import { EmailRounded } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { isEmail, requestStateType } from "../helpers/utils";
import ReCAPTCHA from "react-google-recaptcha";
import { PublicService } from "../helpers/publicService";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import bind from "bind-decorator";

interface IWaitingListProps {
    open: boolean
    publicService: PublicService
}

@observer
export class WaitingListDialog extends React.Component<IWaitingListProps> {
    
    @observable open: boolean = false
    @observable manualNotifyMeClose: boolean = false
    @observable email: string = ''
    @observable recaptchaToken_v3: string | null = null
    @observable requestState: requestStateType = 'notstarted'
    @observable manualConfirmationClose: boolean = false

    private refreshReCaptcha: string | number | boolean | null | undefined;

    constructor(props: IWaitingListProps) {
        super(props)
        makeObservable(this)
        this.open = this.props.open
    }

    @bind
    private close() {
        this.manualNotifyMeClose = true
    }

    private async submit() {
        this.requestState = "verifying"
        if (isEmail(this.email) && this.recaptchaToken_v3) {
            try {
                const res = await this.props.publicService.joinWaitingList(this.email, this.recaptchaToken_v3)
                this.requestState = "ok"
            } catch {
                this.requestState = "error"
            } finally {
                this.refreshReCaptcha = Date.now()
            }
        }
    }

    @computed get readyToSubmit(): boolean {
        return isEmail(this.email) && this.recaptchaToken_v3 !== null
    }

    @bind
    onVerify(token: string): void | Promise<void> {
        runInAction(() => {
            this.recaptchaToken_v3 = token
        })
    }

    render() {
        return <>
            <Dialog         
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // open = { !this.manualConfirmationClose }
            open={ this.requestState === 'ok' && !this.manualConfirmationClose} onClose={() => this.manualConfirmationClose = true}
            >
                <DialogTitle id="alert-dialog-title">Thank you!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Thank you for your interest in cophone! Please confirm your email address by clicking the link we've just sent you.
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => runInAction(() => this.manualConfirmationClose = true)}>
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog
                open={ this.open && !this.manualNotifyMeClose && this.requestState !== 'ok'}
                // open = {false}
                onClose={ () => this.close  }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                {"Cophone"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Cophone is in private preview. Join the waiting list:
                    </DialogContentText>
                    <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    onChange={(evt) => runInAction(() => this.email = evt.target.value.trim().toLowerCase())}
                />
                </DialogContent>
                <GoogleReCaptchaProvider reCaptchaKey={this.props.publicService.recaptchaSiteKey_v3}>
                <GoogleReCaptcha
                    onVerify={this.onVerify}
                    refreshReCaptcha={this.refreshReCaptcha}
                />
                <DialogActions>
                    <Button onClick={this.close}>
                        I have an invite
                    </Button>
                    <Button disabled={!this.readyToSubmit || this.requestState === "verifying"} color="secondary" onClick={() => this.submit()} >
                        Notify me
                    </Button>
                </DialogActions>
                </GoogleReCaptchaProvider>
                
            </Dialog>
        </>
    }
}