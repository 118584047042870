export class ProducerPromise {
    constructor(public readonly id: string, 
            public readonly resolve: (value: string | PromiseLike<string>) => void, 
            public readonly reject: (reason?: any) => void,
            public readonly dataProducerCallback: ((...args: any[]) => void) | null) {}
}

export class ProducerPromiseContainer {
    private id2ProducerPromise = new Map<string, ProducerPromise>();

    public add(e: ProducerPromise) {
        this.id2ProducerPromise.set(e.id, e)
    }

    public remove(id: string) {
        this.id2ProducerPromise.delete(id)
    }

    public get(id: string): ProducerPromise | undefined {
        return this.id2ProducerPromise.get(id)
    }
}