import { observer } from "mobx-react"
import React from "react"
import { requestStateType } from "../helpers/utils"
import { makeObservable, observable, runInAction } from "mobx"
import bind from "bind-decorator"
import { Box, Button, Stack, Typography } from "@mui/material"
import { CommonAppTitle } from "../helpers/utilComponents"
import { PublicService } from "../helpers/publicService"

interface IWaitingListConfirmProps {
    publicService: PublicService
}

@observer
export class ConfirmWaitingList extends React.Component<IWaitingListConfirmProps> {

    @observable requestState: requestStateType = 'notstarted'

    constructor(props: IWaitingListConfirmProps) {
        super(props)
        makeObservable(this)
    }

    @bind
    private async verifyWaitingListToken(vtoken: string): Promise<void> {
        try {
            runInAction(() =>
                this.requestState = 'verifying'
            )
            const res = await this.props.publicService.confirmWaitingList(vtoken)

            runInAction(() =>
                this.requestState = 'ok'
            )
        } catch {
            runInAction(() =>
                this.requestState = 'error'
            )
        }
    }

    componentDidMount() {
        const { t } = Object.fromEntries(new URLSearchParams(document.location.search));

        window.history.pushState({}, document.title, window.location.pathname);

        // document.title = 'Confirm your email'

        if (t && t.length > 10) {
            setTimeout(this.verifyWaitingListToken, 100, t)
        } else {
            // silent error
        }
    }

    render() {
        let text = 'Confirming your email address...'
        if (this.requestState === 'ok') {
            text = 'Thank you! We will let you know as soon as Cophone is publicly available.'
        }
        if (this.requestState === 'error') {
            text = 'An error occured while confirming your email address.'
        }
        if (this.requestState === 'pending') {
            text = 'Verification in progress...'
        }
        

        return <Box display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh">
            <Stack sx={{ minWidth: '20%', maxWidth: '80%' }} spacing={2}>
                
            <CommonAppTitle/>
            <Typography align="center" justifyContent={"center"}>
                <p>{text}</p>
            </Typography>
            {/* { this.requestState === 'ok' && <Button onClick={this.toLogin} >Go to login</Button> } */}
            </Stack>
        </Box>
    }    
    
    // private toLogin() {
    //     document.location.replace('login')
    // }
}
