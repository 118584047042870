import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LoggedInApp, { SharedExpired, SharedLoggedInApp } from './LoggedInApp';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { LoggedOutSuccess } from './components/loggedOutSuccess';
import { LoginComponent } from './components/loginComponent';
import { ConfirmEmailPage, VerifyEmail } from './components/confirmEmailPage';
import { AskResetPasswordComponent } from './components/askResetPasswordComponent';
import { DoResetPasswordComponent } from './components/doResetPasswordComponent';
import { PrivacyComponent, TermsComponent } from './components/legal';
import { SignupComponentWithPayment } from "./components/signupWithPayment";
import { publicService } from './helpers/userService';
import { PublicService } from "./helpers/publicService";
import { ConfirmWaitingList } from './components/confirmWaitingListComponent';

// const dummyUserData: IUserData = {
//   id: 'abc123',
//   name: 'John Doe', 
//   email: 'test@name.com',
//   phoneNumber: '+1234567879'
// }

function VersionComponent() {
  return (
    <React.Fragment>
      <p>{process.env.REACT_APP_VERSION}</p>
    </React.Fragment>
  )
}

ReactDOM.render(
  // <React.StrictMode>
    <Router>
      <Switch>
          <Route path='/terms'>
            <TermsComponent></TermsComponent>
          </Route>
          <Route path='/privacy'>
            <PrivacyComponent></PrivacyComponent>
          </Route>
          <Route path='/resetpasswordconfirm'>
            <DoResetPasswordComponent publicService={publicService} ></DoResetPasswordComponent>
          </Route>
          <Route path='/resetpassword'>
            <AskResetPasswordComponent publicService={publicService} ></AskResetPasswordComponent>
          </Route>
          <Route path='/verifyEmail'>
            <VerifyEmail publicService={publicService} ></VerifyEmail>
          </Route>
          <Route path='/confirm-email'>
            <ConfirmEmailPage></ConfirmEmailPage>
          </Route>
          {/* <Route path='/signup'>
            <SignupComponent signupAddress={new URL(`${process.env.REACT_APP_MAINSERVER_URL!}/user/account/signup`)} recaptchaSiteKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3!}/>
          </Route>
          <Route path='/signup2'>
            <SignupComponentWithProduct phoneNumberService={publicService} signupAddress={new URL(`${process.env.REACT_APP_MAINSERVER_URL!}/user/account/signup`)} recaptchaSiteKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3!}></SignupComponentWithProduct>
          </Route>           */}
          <Route path='/signup'>
            <SignupComponentWithPayment publicService={publicService}></SignupComponentWithPayment>
          </Route>                    
          <Route path='/login'>
            <LoginComponent publicService={publicService}/>
          </Route>
          <Route path='/_version'>
            <VersionComponent></VersionComponent>
          </Route>
          <Route path='/waitingList/confirm'>
            <ConfirmWaitingList publicService={publicService} ></ConfirmWaitingList>
          </Route>
          <Route path='/s'>
            <SharedLoggedInApp publicService={publicService}/>
          </Route>
          <Route path='/shared/expired'>
            <SharedExpired/>
          </Route>
          <Route path='/'>
            {/* <AppProvider /> */}
            <LoggedInApp publicService={publicService}/>
          </Route>
          <Route path='/logged_out'>
            <LoggedOutSuccess show={true}/>
          </Route>
          
        </Switch>
    </Router>

  // </React.StrictMode>,
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
