import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, LinearProgress, MenuItem, Select, Stack, Typography } from "@mui/material";
import React from "react";
import { observer } from "mobx-react";
import { autorun, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { LocalEndpointState } from "../lib/state/localEndpointState";
import bind from "bind-decorator";
import { Logger } from "../helpers/logger";

interface AskAllowDeviceAccessProps {
    cb: (success: boolean) => void
}

type AskAllowState = 'notasked' | 'asking' | 'accepted' | 'rejected'

@observer
class AskAllowDeviceAccess extends React.Component<AskAllowDeviceAccessProps> {

    @observable askState: AskAllowState = 'notasked'

    private logger = new Logger("AskAllowDeviceAccess")

    constructor(props: AskAllowDeviceAccessProps) {
        super(props)
        makeObservable(this)
    }

    render() {
        return (
            <Stack sx={{ minWidth: '20%' }} spacing={2}>
                { this.askState === 'notasked' && 
                    <>
                        <Typography>In order to manage your media devices (microphones, webcams, speakers), you need to allow cophone to access them.</Typography>
                        <Button onClick={this.askAllowMediaAccess} >Allow access</Button>
                    </>
                }
                { this.askState === 'asking' && 
                    <>
                        <Typography>Please check the prompt of your browser</Typography>
                        <LinearProgress />
                    </>
                }
                { this.askState === 'accepted' && 
                    <>
                        <Typography>Getting ready ...</Typography>
                        <LinearProgress color="secondary" />
                    </>
                }
                { this.askState === 'rejected' && 
                    <>
                        <Typography>Unable to manage your media devices.</Typography>
                    </>
                }
            </Stack>
        )
    }
    
    @bind
    private async askAllowMediaAccess() {
        this.askState = 'asking'
        let stream: MediaStream | undefined = undefined
        try {
            stream = await navigator.mediaDevices.getUserMedia({audio: true, video: true})
            this.askState = 'accepted'
            this.props.cb(!!stream)
        } catch (e: any) {
            this.logger.error('Could not get user media', e.toString())
            this.askState = 'rejected'
        } finally {
            setTimeout(() => stream?.getTracks().forEach(t=>t.stop()), 6000)
        }
    }
}

interface ChooseDevicesProps {
    devices: MediaDeviceInfo[]
    localEndpointState: LocalEndpointState
}

@observer
class ChooseDevices extends React.Component<ChooseDevicesProps> {
    selectedVideoInput: any;
    selectedAudioOutput: any;
    selectedAudioInput: any;
    audioOutputElement: HTMLAudioElement | null = null;
    
    componentDidMount(): void {
        autorun(() => {
            const audioEl = this.audioOutputElement as any;
            if (audioEl && audioEl.setSinkId) {
                audioEl.setSinkId(this.props.localEndpointState.audioOutputDevice?.deviceId)
            }
        })
    }

    renderAudioInputs() {
        return <FormControl fullWidth>
            <InputLabel id="audio-input-select-label">Audio input</InputLabel>
                <Select
                    labelId="audio-input-select-label"
                    id="audio-input-select"
                    value={this.props.localEndpointState.audioInputDeviceId || ''}
                    label="Audio input"
                    disabled={this.props.localEndpointState.noAudioInputs}
                    onChange={(event) => this.props.localEndpointState.audioInputDeviceId = event.target.value }
                >
                    {
                        this.props.localEndpointState.audioInputs.map((ai) => {
                            return <MenuItem key={ai.deviceId} value={ai.deviceId}>{ai.label}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
    }

    renderAudioOutputs() {
        return <><FormControl fullWidth>
            <InputLabel id="audio-output-select-label">Audio output</InputLabel>
                <Select
                    labelId="audio-output-select-label"
                    id="audio-output-select"
                    value={ this.props.localEndpointState.audioOutputDevice?.deviceId || ''}
                    label="Audio output"
                    disabled={this.props.localEndpointState.noAudioOutputs}
                    onChange={(event) => this.props.localEndpointState.audioOutputDeviceId = event.target.value }
                >
                    {
                        this.props.localEndpointState.audioOutputs.map((ao) => {
                            return <MenuItem key={ao.deviceId} value={ao.deviceId}>{ao.label}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            { 
                this.props.localEndpointState.audioOutputDevice &&
                <audio ref={ref => this.audioOutputElement = ref} id="audio-output-element" controls loop>
                    <source src="music/over50-first-test-210923-230921-16-v3-uplifting-22sec-with-guitar-piano-9000.mp3" type="audio/mpeg"/>
                    Your browser does not support the audio element.
                </audio>
            }
            {
                !this.props.localEndpointState.audioOutputDevice &&
                <Typography>No speakers detected</Typography>
            }
            </>
    }

    renderVideoInputs() {
        return <FormControl fullWidth>
            <InputLabel id="video-input-select-label">Video input</InputLabel>
                <Select
                    labelId="video-input-select-label"
                    id="video-input-select"
                    value={this.props.localEndpointState.videoInputDeviceId || ''}
                    label="Video input"
                    disabled={this.props.localEndpointState.noVideoInputs}
                    onChange={(event) => this.props.localEndpointState.videoInputDeviceId = event.target.value }
                >
                    {
                        this.props.localEndpointState.videoInputs.map((ai) => {
                            return <MenuItem key={`mi_${ai.deviceId}`} value={ai.deviceId}>{ai.label}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
    }
    
    render() {
        return (
            <Stack sx={{ minWidth: '20%' }} spacing={2}>
                { this.renderAudioInputs() }
                { this.renderAudioOutputs() }
                { this.renderVideoInputs() }
        </Stack>
        )
    }
}

export interface MediaSettingsState {
}

export interface MediaSettingsProps {
    localEndpointState: LocalEndpointState
}

@observer
export class MediaSettings extends React.Component<MediaSettingsProps, MediaSettingsState> {
    
    private logger = new Logger("MediaSettings")

    constructor(props: MediaSettingsProps) {
        super(props)
        makeObservable(this)
    }
    
    @computed get userAllowedDeviceAccess(): boolean {
        if (this.props.localEndpointState.mediaDeviceInfos.length > 0) {
            return this.props.localEndpointState.mediaDeviceInfos.every(mdi => mdi.label.length > 0)
        }
        return false
    }

    @bind
    private onAllowAccessReply(success: boolean) {
        this.logger.debug('onAllowAccessReply', success)
        if (success) {
            this.props.localEndpointState.reloadDevices()
        }
    }

    componentDidUpdate(prevProps: Readonly<MediaSettingsProps>, prevState: Readonly<MediaSettingsState>, snapshot?: any): void {
    }
    
    render() {
        return (
        <>
            { this.props.localEndpointState.hasError && <Typography>An error has occured while enumerating your media devices</Typography>}
            { this.userAllowedDeviceAccess && <ChooseDevices localEndpointState={this.props.localEndpointState} devices={this.props.localEndpointState.mediaDeviceInfos} ></ChooseDevices>}
            { !this.userAllowedDeviceAccess && <AskAllowDeviceAccess cb={this.onAllowAccessReply}></AskAllowDeviceAccess>}
        </>
        )
    }
}