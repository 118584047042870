import { AccountCircle, EmailRounded } from "@mui/icons-material";
import { Box, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, Stack, Alert, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import bind from "bind-decorator";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { isEmail, requestStateType } from "../helpers/utils";
import { PublicService } from "../helpers/publicService";
import { CommonAppTitle } from "../helpers/utilComponents";

interface IResetPasswordProps{
    publicService: PublicService
}

interface IResetPasswordState{
}

@observer
export class AskResetPasswordComponent extends React.Component<IResetPasswordProps, IResetPasswordState> {
    
    @observable email: string = ''
    @observable recaptchaToken: string | undefined
    @observable requestState: requestStateType = 'notstarted'
    refreshReCaptcha: string | number | boolean | null | undefined;
    timer: NodeJS.Timer | undefined;

    constructor(props: IResetPasswordProps) {
        super(props)
        makeObservable(this)
    }

    componentDidMount(): void {
        this.timer = setInterval(() => this.refreshReCaptcha = Date.now(), 1000 * 90)
    }

    @computed get readyToSubmit() {
        return isEmail(this.email) && this.recaptchaToken
    }

    @bind 
    private async resetPassword(evt: any) {
        // check again the email
        if (isEmail(this.email)) {
            runInAction(() => this.requestState = 'verifying')
            try {
                const res = await this.props.publicService.askResetPassword(this.email, this.recaptchaToken!)

                if (res.status === 200) {
                    runInAction(() => this.requestState = 'ok')
                } else {
                    runInAction(() => this.requestState = 'error')
                }

            } catch (e:any) {
                runInAction(() => this.requestState = 'error')
            } finally {
                this.refreshReCaptcha = Date.now()
            }
        }
    }

    @bind
    onVerify(token: string): void | Promise<void> {
        runInAction(() => this.recaptchaToken = token)
    }

    @bind
    handleSubmit(ev: any) {
        ev.preventDefault()
    }

    render() {
        return <form onSubmit={this.handleSubmit}>
        <GoogleReCaptchaProvider reCaptchaKey={this.props.publicService.recaptchaSiteKey_v3}>
        <GoogleReCaptcha
            onVerify={this.onVerify}
            refreshReCaptcha={this.refreshReCaptcha}
        />
        <Box display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh">
            <Stack sx={{ minWidth: '20%', maxWidth: '80%' }} spacing={2}>
            <CommonAppTitle/>
                    <FormControl variant="outlined" disabled={this.requestState === 'ok'} >
                    <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                    <OutlinedInput
                    id="outlined-adornment-email"
                    type='text'
                    autoComplete='off'
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton edge="end" disabled>
                        <EmailRounded />
                        </IconButton>
                        </InputAdornment>
                    }
            label="Email"
            onChange={(evt) => runInAction(() => this.email = evt.target.value.trim().toLowerCase())}
            // fullWidth
            />
            </FormControl>
            { (this.requestState === 'ok') && <Alert severity="success" variant="outlined">Check your email for next steps.</Alert> }
            { (this.requestState === 'error') && <Alert severity="error" variant="outlined">An error occured. Please refresh this page and try again</Alert> }
            { (this.requestState === 'notstarted') && <Button type="submit" disabled={!this.readyToSubmit} variant="contained" onClick={this.resetPassword} >Reset password</Button> }
            { (this.requestState === 'verifying') && 
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress />
                </div>
            }
            </Stack>
            
        </Box>
        </GoogleReCaptchaProvider>
        </form>  
    }

}