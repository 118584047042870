import { AccountCircle, EmailRounded } from "@mui/icons-material";
import { Box, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, Stack, Alert, CircularProgress, Typography, SelectChangeEvent, MenuItem, Select, DialogContent, LinearProgress, Dialog, DialogTitle } from "@mui/material";
import axios from "axios";
import bind from "bind-decorator";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { requestStateType } from "../helpers/utils";

@observer
export class SubscribedComponent extends React.Component {
    
    public static readonly subscribedKey = 'subscribed'

    @observable requestState: requestStateType = 'notstarted'
    @observable open: boolean = true

    constructor(props: any) {
        super(props)
        makeObservable(this)
    }

    @bind
    close() {
        runInAction(() => {
            this.open = false
            
        })
        localStorage.removeItem(SubscribedComponent.subscribedKey)
    }

    render() {
            return (<React.Fragment>
            <Dialog open={this.open} aria-labelledby="form-dialog-title">
                <DialogTitle style={{display: 'flex', justifyContent: 'center'}} >
                Thank you for subscribing!
                </DialogTitle>
                    <DialogContent dividers>
                    <Stack spacing={2}>
                        <Typography variant="body2" align="justify" justifyContent={"flex-end"}  >Your new phone number will be linked to your online smartphone and you will be able to place and receive national and international calls as well as send and receive short messages.</Typography>
                        <Typography variant="body2" align="justify" justifyContent={"flex-end"}  >Please allow up to 10 minutes for your phone number to be provisioned.</Typography>
                        <Button variant="contained" onClick={this.close} >Close</Button>
                    </Stack>
                </DialogContent>
                </Dialog>
            </React.Fragment>)
    }
}