import { types } from "mediasoup-client";
import { MediasoupElement } from "./mediasoupElement";

export class MsElemContainer {
    
    private elemId2Elem = new Map<string, MediasoupElement>();

    public get(msElemId: string): MediasoupElement | undefined {
        return this.elemId2Elem.get(msElemId)
    }

    public has(msElemId: string): boolean {
        return this.elemId2Elem.has(msElemId)
    }

    public add(msElem: MediasoupElement) {
        if (this.has(msElem.id)) {
            throw new Error(`This enpoint id (${msElem.id} is already set)`)
        }
        this.elemId2Elem.set(msElem.id, msElem as types.Producer)
    }

    public remove(msElemId: string) {
        const elem = this.elemId2Elem.get(msElemId)
        if (elem) {
            this.elemId2Elem.delete(msElemId)
            return
        }
        throw new Error(`No such endpoint ${msElemId}`)
    }

    public getTrack(msElemId: string): MediaStreamTrack | null {
        const elem = this.elemId2Elem.get(msElemId)

        try {
            return (elem as any).track
        } catch (e) {
            return null
        }
    }

    public getTracks(msElemIds: string[]): MediaStreamTrack[] {
        const result: MediaStreamTrack[] = []
        msElemIds.forEach(cid => {
            const track = this.getTrack(cid)
            if (track) {
                result.push(track)
            }
        })
        return result
    }

    public where(predicate: (p: MediasoupElement) => boolean) {
        const result: MediasoupElement[] = []
        this.elemId2Elem.forEach(e => {
            if (predicate(e)) {
                result.push(e)
            }
        })
        return result
    }
}