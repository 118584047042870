import * as React from 'react';

// This is the Stripe Pricing Table

function StripePricingTable(props) {

  console.log('StripePricingTable', props, process.env.REACT_APP_STRIPE_PRICING_TABLE_ID, process.env.REACT_APP_AUTOCONSUME_PRODUCERS)

  if (!props || !props.clientId || !props.clientEmail) throw Error('Please specify clientId and clientEmail in props')
  return (
    <stripe-pricing-table pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
    publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
    client-reference-id={props.clientId}
    customer-email={props.clientEmail}
    // payment-method-collection="if_required"
    >
    </stripe-pricing-table>
  );
}

export default StripePricingTable;
