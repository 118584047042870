import bind from "bind-decorator"
import { types, Device } from "mediasoup-client";
import { Consumer, DataProducer, InvalidStateError, Producer, UnsupportedError } from "mediasoup-client/lib/types";
import { Logger } from "../../../helpers/logger";
import { EnhancedEventEmitter2, IMsSignaling } from "./iMsSignaling";
import { MediasoupElement } from "./mediasoupElement";
import { MsElemContainer } from "./msElemContainer";
import { ProducerPromiseContainer } from "./producerPromiseContainer";
import { MediasoupEndpointErrorCode } from "../errors/mediasoupEndpointErrors";
import { computed, makeObservable, observable, runInAction } from "mobx";

const logger = new Logger('MsClient')


export class MsClient extends EnhancedEventEmitter2 {

    public readonly EVENT_CONNECTED: string[] = ['MsClient','connected']
    public readonly EVENT_DISCONNECTED: string[] = ['MsClient', 'disconnected']

    private id: string | undefined;
    private device: Device = new Device();
    private deviceLoadedPromise: Promise<void> 
    @observable
    private recvTransport: types.Transport | undefined;
    @observable
    private sendTransport: types.Transport | undefined;
    private msContainer = new MsElemContainer();
    private producerPromiseContainer = new ProducerPromiseContainer();
    private mainDataProducer: MediasoupElement | undefined;

    // @observable codecs: types.RtpCodecCapability[] | undefined | null = null
    @observable error: string | null = null
    private _deviceLoadResolve!: (value: void | PromiseLike<void>) => void;
    private _deviceLoadReject!: (reason?: any) => void;
    transportsConnectedPromise: Promise<void>;
    private _connectedResolve!: (reason?: any) => void;
    private _connectedReject!: (value: void | PromiseLike<void>) => void;

    get codecs(): types.RtpCodecCapability[] | null | undefined {
        if (this.device.loaded)
            return this.device.rtpCapabilities.codecs
        else 
            return null
    }

    @computed
    public override get connected(): boolean {
        return this.recvTransport != undefined && this.sendTransport != undefined
    }

    constructor(public readonly msSignaler: IMsSignaling) {
        super()
        makeObservable(this)
        this.msSignaler.onDisconnected(this.onMsSignalerDisconnected)
        this.msSignaler.onConnectedAsync(this.onMsSignalerConnected)
        this.deviceLoadedPromise = new Promise((resolve, reject) => {
            this._deviceLoadResolve = resolve
            this._deviceLoadReject = reject
        })
        this.transportsConnectedPromise = new Promise((resolve, reject) => {
            this._connectedResolve = resolve
            this._connectedReject = reject
        })
    }

    @bind
    private onMsSignalerDisconnected() {
        logger.debug('onDisconnected()')
        this.reset()
    }

    private reset() {
        this.id = undefined
        this.recvTransport && this.recvTransport.close()
        this.recvTransport = undefined
        
        this.sendTransport && this.sendTransport.close()
        this.sendTransport = undefined
        this.device = new Device()
        this.deviceLoadedPromise = new Promise((resolve, reject) => {
            this._deviceLoadResolve = resolve
            this._deviceLoadReject = reject
        })
        this.transportsConnectedPromise = new Promise((resolve, reject) => {
            this._connectedResolve = resolve
            this._connectedReject = reject
        })
        this.msContainer = new MsElemContainer()
        this.producerPromiseContainer = new ProducerPromiseContainer();
        runInAction(() => {
            this.error = null
        })

        this.emit(this.EVENT_DISCONNECTED)
    }

    // Private area - mediasoup related stuff
    @bind
    private async onCreateRecvTransport(content: any) {
        if (!this.device.loaded) throw new Error('device is present but not loaded')

        if (this.recvTransport) throw new Error('recvTransport already created')

        this.recvTransport = await this.device.createRecvTransport(content.transportOptions)
        this.recvTransport.on('connect', async ({ dtlsParameters }: any, callback: any, errback: any) => {
            logger.debug('recttransport connect event', dtlsParameters)
            try {
                this.msSend(`connecttransport.${this.recvTransport!.id}`, { dtlsParameters })
                callback()
            }
            catch (reason) {
                logger.error('ms: Error connecting recv transport', reason)
                errback()
            }
        })

        if (this.connected) {
            logger.debug('Connected!')
            this._connectedResolve()
            this.emit(this.EVENT_CONNECTED)
        }
    }

    @bind
    private async onCreateSendTransport(content: any) {
        if (!this.device) throw new Error('device was not created')

        if (!this.device.loaded) throw new Error('device is present but not loaded')

        if (this.sendTransport) throw new Error('sendTransport already created')

        this.sendTransport = await this.device.createSendTransport(content.transportOptions)
        this.sendTransport.on('produce', this.onProduce)
        this.sendTransport.on('producedata', this.onProduceData)

        this.sendTransport.on('connect', async ({ dtlsParameters }: any, callback: any, errback: any) => {
            logger.debug('sendtransport connect event', dtlsParameters)
            try {
                this.msSend(`connecttransport.${this.sendTransport!.id}`, { dtlsParameters })
                callback()
            }
            catch (reason) {
                logger.error('ms: Error connecting send transport', reason)
                errback()
            }
        })

        if (this.connected) {
            logger.debug('Connected!')
            this._connectedResolve()
            this.emit(this.EVENT_CONNECTED)
        }
    }

    @bind
    private async onProduce({ rtpParameters, kind, appData }: any, callback: any, errback: any) {

        // rtpParameters.encodings.forEach((element: any) => {
        //   element.dtx = true
        // });
        try {
            const ev = `produce.${this.sendTransport!.id}`
            this.msSignaler!.once(`re.${ev}`, (data: any) => {
                const producerId = data.producerId
                logger.debug("The producer's id is: ", producerId)
                callback({ id: producerId });
            })
            this.msSend(ev, { rtpParameters, kind, name: appData.name })

        } catch (error) {
            // Tell the transport that something was wrong.
            errback(error);
        }
    }

    @bind
    private async onProduceData({ sctpStreamParameters, label, protocol, appData }: any, callback: any, errback: any) {

        // rtpParameters.encodings.forEach((element: any) => {
        //   element.dtx = true
        // });
        try {
            const ev = `producedata.${this.sendTransport!.id}`
            this.msSignaler!.once(`re.${ev}`, (data: any) => {
                const dataProducerId = data.dataProducerId
                logger.debug("The data producer's id is: ", dataProducerId)
                callback({ id: dataProducerId });
            })
            this.msSend(ev, { sctpStreamParameters, label, protocol, name: appData.name })

        } catch (error) {
            // Tell the transport that something was wrong.
            errback(error);
        }
    }

    @bind
    private async onConsume(data: any) {
        if (!this.connected) throw new Error('Not ready!')
        if (this.msContainer.has(data.consumerId)) throw new Error('Consumer already created')

        const producerPromise = this.producerPromiseContainer.get(data.producerId)

        if (!producerPromise) {
            throw new Error(`No resolve set for producer ${data.producerId}`)
        } 

        if (data.success) {
            try {

                const consumer = await this.recvTransport!.consume({
                    id: data.consumerId, producerId: data.producerId,
                    kind: data.kind, rtpParameters: data.rtpParameters
                })
                this.msContainer.add(consumer)
                
                producerPromise.resolve(data.consumerId)
                
                this.resume(consumer.id)
            } catch (e: any) {
                logger.error('Could not consume producer', data)
                if (typeof e == typeof UnsupportedError) {
                    logger.error('Unsupported error')
                    producerPromise.reject('Unsupported')
                } else {
                    throw e
                }
            }
            
        } else {
            logger.error(`Error ${data.errorCode} occured while trying to consume producer ${data.producerId}`)
            if  (data.errorCode === MediasoupEndpointErrorCode.CANNOT_CONSUME_ERROR) {
                logger.error('Cannot consume this producer, so we should try to switch encoder', data.producerId)
            }
            producerPromise.reject(data.errorCode)
        }
        this.producerPromiseContainer.remove(producerPromise.id)
    }

    @bind
    private async onDataConsume(data: any) {
        if (!this.connected) throw new Error('Not ready!')
        if (this.msContainer.has(data.dataConsumerId)) throw new Error('Data consumer already created')
        const dataConsumer = await this.recvTransport!.consumeData({ id: data.dataConsumerId, dataProducerId: data.dataProducerId, sctpStreamParameters: data.sctpStreamParameters })
        this.msContainer.add(dataConsumer)
        this.resume(dataConsumer.id) // TODO: don't need this

        const producerPromise = this.producerPromiseContainer.get(data.dataProducerId)

        if (producerPromise && producerPromise.dataProducerCallback) {
            dataConsumer.on('message', producerPromise.dataProducerCallback)
            producerPromise.resolve(data.dataConsumerId)
        } else {
            throw new Error(`No resolve set for producer ${data.producerId}`)
        }
    }

    @bind
    private async onRtpCapabilities(content: any) {
        logger.debug('onRtpCapabilities(),', content)
        // if (!this.device) {
            try
            {
                if (!this.device)
                    this.device = new Device()
                this.deviceLoadedPromise = this.device.load({ routerRtpCapabilities: content.rtpParameters })
                await this.deviceLoadedPromise
                this._deviceLoadResolve()

                logger.debug('local device codecs', this.codecs)

                const recvTranspMsg = 'createtransport.recv'
                this.msSignaler.once(`re.${recvTranspMsg}`, this.onCreateRecvTransport)
                this.msSend(recvTranspMsg, { sctpCapabilities: this.device.sctpCapabilities })

                const sendTranspMsg = 'createtransport.send'
                this.msSignaler.once(`re.${sendTranspMsg}`, this.onCreateSendTransport)
                this.msSend(sendTranspMsg, { sctpCapabilities: this.device.sctpCapabilities })
            } catch (error: any) {
                if (error && error.name === 'UnsupportedError') {
                    logger.error('Browser not supported', error)
                    runInAction(() => {
                        this.error = error.name
                    })
                } else {
                    logger.error('Unknown error', error)
                    runInAction(() => { 
                        this.error = 'unknown_error'
                    })
                }
            }
        // }
    }

    @bind
    private onMsSignalerConnected() {
        logger.debug('onMsSignalerConnected()')
        if (!this.msSignaler) throw new Error('Not connected')
        this.msSignaler.once('re.getrtpcapabilities', this.onRtpCapabilities)
        this.msSend('getrtpcapabilities')
    }

    // Private area - helpers
    private msSend(event: string, content?: any) {
        this.msSignaler.send(event, content)
    }

    private stopMsElem(msElem: MediasoupElement) {
        this.msSend(`stop.${msElem.id}`)
        msElem.close()
    }

    private pauseMsElem(msElem: MediasoupElement) {
        this.msSend(`pause.${msElem.id}`);
        if (msElem.hasOwnProperty('pause')) {
            (msElem as any).pause();
        }
    }

    private resumeMsElem(msElem: MediasoupElement) {
        this.msSend(`resume.${msElem.id}`);
        if (msElem.hasOwnProperty('resume')) {
            (msElem as any).resume();
        }
    }

    // Public API
    public async produce(track: MediaStreamTrack, name: string, paused: boolean = false, opts?: { opusDtx: boolean }): Promise<string> {
        if (!this.connected) throw new Error('Not ready or no sendTransport')
        let codec = undefined
        // for some reason, we want to send VP8
        if (track.kind == 'video') 
            if (this.device!.rtpCapabilities.codecs) {
                codec = this.device!.rtpCapabilities.codecs
                    .find((codec) => codec.mimeType.toLowerCase() === 'video/vp8')
            }

        const producer = await this.sendTransport!.produce({
            track,
            codec,
            codecOptions: {
                opusStereo: true,
                opusDtx: opts?.opusDtx ?? true
            },
            appData: {
                trackId: track.id,
                paused,
                name
            }
        })

        this.msContainer.add(producer)
        return producer.id
    }

    public async produceData(name: string): Promise<string> {
        if (!this.connected) throw new Error('Not ready or no sendTransport')
        logger.debug(`produceData`, name)
        const dataProducer = await this.sendTransport!.produceData({appData: { name }})

        this.msContainer.add(dataProducer)

        return dataProducer.id
    }

    public send(data: any, dataProducerId: string | undefined = undefined) {
        let msElem: MediasoupElement | undefined
        if (dataProducerId) {
            msElem = this.msContainer.get(dataProducerId)
            if (!msElem) {
                throw new Error(`No such data producer ${dataProducerId}`)
            }
        } else {
            if (this.mainDataProducer) {
                msElem = this.mainDataProducer
            } else {
                const dataProducers = this.msContainer.where(e => e instanceof types.DataProducer)
                if (dataProducers.length === 1) {
                    msElem = dataProducers[0]
                    this.mainDataProducer = msElem
                } else {
                    var e = (dataProducers.length > 1) ?
                        new Error(`Multiple data producers defined`) :
                        new Error(`No data producer present`)
                    throw e
                }
            }
        }
        const dataProducer = msElem as DataProducer
        try {
            dataProducer.send(data)
        } catch (e) {
            if (e instanceof InvalidStateError) {
                logger.warn("Data producer has been closed!")
                logger.error("Refreshing page hoping this will fix the error")
                setTimeout(() => window.location.reload(), 1000)
            }
        }
    }

    public async consume(producerId: string, paused: boolean = true): Promise<string> {
        await this.transportsConnectedPromise
        const consumerId = await new Promise<string>((resolve, reject) => {
            this.producerPromiseContainer.add({ id: producerId, resolve, reject, dataProducerCallback: null })
            this._consume(producerId, paused)
        })
        return consumerId
    }

    private _consume(producerId: string, paused: boolean) {
        if (!this.connected)
            throw new Error('Not connected');
        const ev = `consume.${this.recvTransport!.id}.${producerId}`;
        this.msSignaler.once(`re.${ev}`, this.onConsume);
        this.msSend(ev, { consumerName: 'Audio', producerId, paused, rtpCapabilities: this.device?.rtpCapabilities });
    }

    public async consumeData(dataProducerId: string, fn: (...args: any[]) => void): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.producerPromiseContainer.add({ id: dataProducerId, resolve, reject, dataProducerCallback: fn })
            this._consumeDataTimeout(dataProducerId)
        })
    }

    private _consumeDataTimeout(dataProducerId: string) {
        setTimeout(() => {
            if (!this.connected) {
                return this._consumeDataTimeout(dataProducerId)
            }
            this._consumeData(dataProducerId);
        }, 10)        
    }

    private _consumeData(dataProducerId: string) {
        if (!this.connected) throw new Error('Not connected')

        const ev = `dataconsume.${this.recvTransport!.id}.${dataProducerId}`
        this.msSignaler.once(`re.${ev}`, this.onDataConsume)
        this.msSend(ev, { dataProducerId: dataProducerId, rtpCapabilities: this.device?.rtpCapabilities })
    }

    public stop(msElemId: string): MediaStreamTrack[] | undefined {

        const endpoint = this.msContainer.get(msElemId)
        if (endpoint) {
            this.stopMsElem(endpoint)
            const tracks = this.msContainer.getTracks([msElemId])
            tracks.forEach(t => t.enabled = false)
            tracks.forEach(t => t.stop())
            this.msContainer.remove(endpoint.id)
            return tracks
        }

        // no such endpoint - maybe throw?
        return undefined;
    }

    public pause(msElemId: string): boolean {
        const endpoint = this.msContainer.get(msElemId)
        if (endpoint) {
            this.pauseMsElem(endpoint);
            return true
        }

        // no such endpoint - maybe throw?
        return false;
    }

    public pauseConsumers() {
        this.msContainer.where(ms => ms instanceof Consumer).forEach(ms => this.pauseMsElem(ms))
    }

    public resumeConsumers() {
        this.msContainer.where(ms => ms instanceof Consumer).forEach(ms => this.resumeMsElem(ms))
    }

    public resume(msElemId: string): boolean {
        const msElem = this.msContainer.get(msElemId)
        if (msElem) {
            this.resumeMsElem(msElem);
            return true
        }

        // no such endpoint - maybe throw?
        return false;
    }

    public getTrack(msElemId: string): MediaStreamTrack | null {
        return this.msContainer.getTrack(msElemId)
    }

    public getTracks(msElemIds: string[]): MediaStreamTrack[] {
        return this.msContainer.getTracks(msElemIds)
    }

    public replaceTrack(producerId: string, track: MediaStreamTrack | null): Promise<void> {
        const producer = this.msContainer.get(producerId) as Producer
        if (producer) {
            return producer.replaceTrack({ track })
        }
        return new Promise((resolve, reject) => reject())
    }

    public async canDecode(inputCodecs: types.RtpCodecParameters[]): Promise<boolean> {
        await this.deviceLoadedPromise
        if (!this.readyToDecode()) {
            return false
        }
        return inputCodecs.every(ic => this.codecs!.some(c => ic.mimeType === c.mimeType))
    }

    public readyToDecode(): boolean {
        if (!this.device.loaded) {
            return false
        }
        return true        
    }
}