import { Box, Button, CircularProgress, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import { AccountCircle, Email, PermIdentity, Phone } from "@mui/icons-material";
import React from "react";
import { UserDetails, UserService } from "../helpers/userService";
import { makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";

// export interface IUserData {
//     id: string
//     name: string
//     email: string
//     phoneNumber: string
//     callingCredit?: number
// }

export interface IUserDetailsProps {
    userService: UserService
    // userData: IUserData
}

export interface IUserDetailsState {
    // userData: IUserData
}

@observer
export class UserDetailsComponent extends React.Component<IUserDetailsProps, IUserDetailsState> {
    
    @observable
    userDetails: UserDetails | undefined

    @observable
    restartDisabled: boolean = false

    constructor(props: IUserDetailsProps) {
        super(props)
        makeObservable(this)
    }

    requestRestart(): void {
        runInAction(() => {
            this.restartDisabled = true
            setTimeout(() => runInAction(() => this.restartDisabled = false), 30000)
        })
        this.props.userService.requestRestartEmubridge()

    }

    componentDidMount() {
        this.props.userService.getUserDetails().then((v) => runInAction(() => this.userDetails = v))
    }

    render() {
        return (
                        
            <React.Fragment>
                <Stack spacing={2}>
            { this.userDetails && <>
            <TextField id="input-with-icon-grid" 
            label="Email"
            value={this.props.userService.userEmail} 
            fullWidth disabled InputProps={{
                style: {fontSize: 18},
                startAdornment: (
                    <InputAdornment position="start">
                    <Email />
                    </InputAdornment>
                    ),
                }}/>
            <TextField id="input-with-icon-grid" 
            label="Telephone number" 
            value={this.userDetails?.phoneNumber} 
            fullWidth disabled InputProps={{
                style: {fontSize: 18},
                startAdornment: (
                    <InputAdornment position="start">
                        <Phone />
                    </InputAdornment>
                    ),
                }}/>
            <TextField id="input-with-icon-grid" 
            label="id" 
            value={this.userDetails?.id} 
            fullWidth disabled InputProps={{
                style: {fontSize: 18},
                startAdornment: (
                    <InputAdornment position="start">
                        <PermIdentity />
                    </InputAdornment>
                    ),
                }}/>                    
            </>
            }
            { !this.userDetails && <><CircularProgress/></> }
                    <Box
                    // m={1} //margin
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    // sx={boxDefault}
                    >
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" onClick={() => this.requestRestart() } disabled = {this.restartDisabled} >Restart cophone</Button>
                        <Button variant="outlined" onClick={() => this.props.userService.logout()} >Logout</Button>
                    </Stack>
                    </Box>
                    </Stack>
                    </React.Fragment>
                    )
                }
    
            }