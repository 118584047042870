import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import bind from "bind-decorator";
import { autorun, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { PublicService } from "../helpers/publicService";

export interface GetPhoneNumberState {
}

class SelectPhoneNumberStore {
    @observable phoneNumber: string | undefined
}

export interface GetPhoneNumberProps {
    publicService: PublicService
    store: SelectPhoneNumberStore
}

@observer
export class GetPhoneNumber extends React.Component<GetPhoneNumberProps, GetPhoneNumberState> {
    
    @observable phoneNumbers: string[] | undefined
    @observable error: string | undefined

    constructor(props: GetPhoneNumberProps) {
        super(props)
        makeObservable(this)
    }

    componentDidMount(): void {
        this.getPhoneNumbers()
    }

    private async getPhoneNumbers() {
        try {
            const res = await this.props.publicService.getAvailablePhoneNumbers()
            runInAction(() => {
                this.phoneNumbers = res
                this.props.store.phoneNumber = this.phoneNumbers[0]
            })
        } catch {
            runInAction(() => this.error = 'Could not get phone numbers')
        }
    }

    render() {
        if (this.phoneNumbers && this.phoneNumbers.length > 0) {
            const menuItems = this.phoneNumbers?.map((item, i) => {
                return (
                    <MenuItem key={i} value={item}>{item}</MenuItem>
                );
            })
            return (<React.Fragment>
                <Stack spacing={2}>
                {/* <Typography>Add a new number to your phone</Typography> */}
                <FormControl fullWidth>
                <InputLabel id="select-phonenumber">Your new phone number</InputLabel>
                <Select
                labelId="select-phonenumber"
                id="select-phonenumber"
                value={this.props.store.phoneNumber ?? this.phoneNumbers[0] ?? ''}
                label="Your new phone number"
                onChange={(ev) => runInAction(() => this.props.store.phoneNumber = ev.target.value)}
                > 
                    {menuItems}
                </Select>
                </FormControl>
                </Stack>
            </React.Fragment>)
        } else {
            if (this.error) {
                return (<Typography><p>An error occured</p></Typography>)
            } else {
                return (<CircularProgress></CircularProgress>)
            }
        }
    }
}