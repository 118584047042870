import bind from "bind-decorator";
import { EventEmitter2, Listener } from "eventemitter2";
import { IGroupSignaling } from "../base/iMsSignaling";
import { MsClient } from "../base/msClient";
import { NotConnectedError } from "../../rabbitmq/customErrors";
import { PresenceService } from "../../rabbitmq/presenceService";
import { MediasoupElementInfo } from "../base/mediasoupElement";
import { Logger } from "../../../helpers/logger";
import { RemoteEndpoint, RemoteEndpointType } from "./remoteEndpoint";
import { parsePhoneNumber, PhoneNumber } from "libphonenumber-js";
import { Call, EVENT_CALL_STATUS, EVENT_COMMAND_RESPONSE } from "../../../helpers/call";
import { PstnEvent } from "../../rabbitmq/pstnEvent";
import { MediaTrack } from "./mediaTrack";
import { LocalEndpoint } from "./localEndpoint";
import { PstnSignaling2 } from "../../rabbitmq/pstnSignaling2";
import { CallError } from "../../../helpers/errors/callError";
import { ProducerInfo } from "../base/producerInfo";
import { BaresipCall, IMediaController, TheCall, TwilioCallStatus } from "../../../helpers/twilioCallStatus";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { EVENT_SMS, EVENT_SMSSTATUS, SmsSignaling2 } from "../../rabbitmq/smsSignaling2";
import { v4 as uuidv4 } from 'uuid';

export namespace Testing {
    export class Sms {

        @observable Status: string = 'new';

        constructor(public readonly id: string,
            public readonly from: PhoneNumber,
            public readonly to: PhoneNumber,
            public readonly text: string) {
                makeObservable(this);
            }
    }
}

export class SipRemoteEndpoint extends RemoteEndpoint implements IMediaController {
    
    public static readonly EVENT_CALL = 'call';
    public static readonly EVENT_SMS = 'sms';
    public static readonly EVENT_SMSSTATUS = 'smsStatus';

    @observable
    protected _currentCalls = new Map<string, TheCall>()
    protected smsSignaler: SmsSignaling2;

    @observable
    public smses = new Map<string, Testing.Sms>();

    @computed
    public get currentCalls(): Array<TheCall> {
        return Array.from(this._currentCalls.values())
    }

    protected readonly pstnSignaling: PstnSignaling2;
    
    public get type(): RemoteEndpointType {
        return 'sipbridge'
    }
    
    protected async getSendTracks(): Promise<MediaTrack[]> {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const sendTracks = [new MediaTrack(stream.getAudioTracks()[0], 'pstn audio')];
        return sendTracks
    }
    
    @bind
    public startSendingMedia(call: BaresipCall): Promise<ProducerInfo[]> {
        return this._startMedia();
    }

    @bind
    public async stopSendingMedia(call: BaresipCall): Promise<void> {
        this._stopMedia(call.outgoingMedia)
    }

    @bind
    protected async _startMedia(sendTracks?: MediaTrack[]): Promise<ProducerInfo[]> {
        if (!sendTracks) {
            sendTracks = await this.getSendTracks();
        }
        
        const producerInfos = await this.localEndpoint.produceMedia({ mediaTracks: sendTracks, opts: { opusDtx: true } });
        return producerInfos
    }
    
    @bind
    protected _stopMedia(producerInfos: ProducerInfo[] | undefined) {
        if (producerInfos) {
            this.localEndpoint.stop(producerInfos);
            this.stopMediaTracks((producerInfos as ProducerInfo[]).map(pi => pi.mediaTrack))
        } else {
            this.logger.warn('stopMedia(): cannot stop because there are no producerInfos on the call')
        }
    }

    @bind
    protected stopMediaTracks(mediaTracks: (MediaTrack | null)[]) {
        if (mediaTracks) {
            mediaTracks.forEach(mt => mt?.track.stop())
        }
    }
    
    @bind
    public dial(number: PhoneNumber) {
        const call = new BaresipCall(this, this.pstnSignaling)
        call.dial(this.phoneNumber, number)
        this._currentCalls.set(call.internal_id, call)
    }

    // dummy phone number for now
    phoneNumber: PhoneNumber = parsePhoneNumber('+123456789')
    
    constructor(public readonly id: string,
        // public readonly phoneNumber: PhoneNumber,
        // public readonly sharedData: any,
        // public readonly producers: ProducerInfo[],
        protected readonly msClient: MsClient,
        protected readonly groupSignaling: IGroupSignaling,
        protected readonly presenceService: PresenceService,
        protected readonly localEndpoint: LocalEndpoint,
        public autoConsumeProducers: boolean = true) {
            super(id, msClient, groupSignaling, presenceService, autoConsumeProducers)
            makeObservable(this)
            this.logger = new Logger(`SipRemoteEndpoint_${id}`)
            
            this.pstnSignaling = new PstnSignaling2(groupSignaling)
            // this.groupSignaling.on(['*', '*', EVENT_CALL_OPERATION], this.onCallOp)
            this.groupSignaling.on(['*', '*', EVENT_CALL_STATUS], this.onCallStatus_baresip)
            this.groupSignaling.on(['*', '*', EVENT_COMMAND_RESPONSE], this.onCommandResponse_baresip)
            this.groupSignaling.on(['*', '*', EVENT_COMMAND_RESPONSE], this.onCommandResponse_baresip)
            this.smsSignaler = new SmsSignaling2(this.groupSignaling, this.phoneNumber)
            this.smsSignaler.on(EVENT_SMS, this.onSms)
            this.smsSignaler.on(EVENT_SMSSTATUS, this.onSmsStatus)
        }
    
    @bind
    onStartIncomingMedia(call: TheCall): void {
        this.on([RemoteEndpoint.NEW_MEDIASOUP_ELEM, `${(call.external_id || call.internal_id)} audio`, 'producer', 'audio'], (mei) => {
            call.addIncomingMedia(mei);
        })
    }

    @bind
    onStopIncomingMedia(call: TheCall): void {
        this.on([RemoteEndpoint.CLOSE_MEDIASOUP_ELEM, `${(call.external_id || call.internal_id)} audio`, 'producer', 'audio', '**'], (mei) => {
            call.removeIncomingMedia(mei);
        })
    }
    
    @bind
    protected onSms(senderId: string, data: any) {
        this.logger.debug('onSms', senderId, data)
        this.addSms(data)
        this.emit([SipRemoteEndpoint.EVENT_SMS, data.direction], data)
    }

    @bind
    protected onSmsStatus(senderId: string, data: any) {
        this.logger.debug('onSmsStatus', senderId, data)
        this.updateSmsStatus(data)
        this.emit([SipRemoteEndpoint.EVENT_SMSSTATUS, data.id], data)
    }

    @bind
    public sendSms(to: PhoneNumber, text: string) {
        const opts = {
            id: `sms_${uuidv4()}`
        }
        const outgoingSms = {
            id: opts.id,
            From: this.phoneNumber.format("E.164"),
            To: to.format("E.164"),
            Body: text,
        }
        const sms = new Testing.Sms(opts.id, this.phoneNumber, to, text)
        this.addSms(sms)
        this.smsSignaler.sendSms(this.phoneNumber, to, text, opts)
    }

    @bind
    public addSms(sms: Testing.Sms) {
        this.smses.set(sms.id, sms)
    }

    @bind
    public updateSmsStatus(incoming: any) {
        const sms = this.smses.get(incoming.id)
        if (sms) {
            sms.Status = incoming.SmsStatus || incoming.MessageStatus
        } else {
            this.logger.error('No such sms', incoming);
        }
    }
    
    @bind
    private async onCallStatus_twilio(senderId: string, data: any) {
        this.logger.debug('onCallStatus_twilio', data)
        var call = this._currentCalls.get(data.CallSid)
        if (!call) {
            call = new TwilioCallStatus(data as TwilioCallStatus)
            this._currentCalls.set(call.internal_id, call)
        } else {
            runInAction(() => Object.assign(call!, data as TwilioCallStatus))
        }
        // if (!call.media && call.isActive) {
        //     call.media = await this.startMedia();
        // }
        // if (call.media && call.isEnded) { 
        //     this.stopMedia(call.media)
        // }

        this.emit([SipRemoteEndpoint.EVENT_CALL, call.state], call)
    }

    @bind
    private onCommandResponse_baresip(senderId: string, data: any) {
        this.logger.debug('onCommandResponse_baresip', senderId, data)
        // const internal_call_id = data.token
        // const external_call_id = data.data.split(' ').pop()
        // const call = this._currentCalls.get(internal_call_id)
        // if (call) {
        //     call.external_id = external_call_id
        // } else {
        //     this.logger.error('No such call', internal_call_id)
        // }
    }

    @bind
    private async onCallStatus_baresip(senderId: string, data: any) {
        this.logger.debug('onCallStatus_baresip', data)
        if (data.id) {
            let existingCall = this._currentCalls.get(data.id)
            if (!existingCall) {
                // try to match on call details
                for (let [callId, call] of Array.from(this._currentCalls)) {
                    if (call.external_id === data.id 
                        || (data.peeruri.includes(call.to) && call.state === 'CALL_OUTGOING' && call.direction === data.direction)) {
                        existingCall = call
                        existingCall.external_id = data.id
                        break
                    }
                }
            }
            if (!existingCall) {
                // this is really a new call
                const call = new BaresipCall(this, this.pstnSignaling)
                runInAction(() => this._currentCalls.set(call.internal_id, call))
                existingCall = call
            }
            if (existingCall) {
                runInAction(() => existingCall!.update(data))
            } else {
                this.logger.error('No call')
                return
            }

            this.emit([SipRemoteEndpoint.EVENT_CALL, existingCall.state], existingCall)
        }
    }

    public override close(): void {
        this.logger.debug('closing')
        super.close();
        this._currentCalls.forEach(c => {
            c.hangup();
        })
        this._currentCalls.clear()
        this.groupSignaling.off(['*', '*', EVENT_CALL_STATUS], this.onCallStatus_baresip)
        this.smsSignaler.off(EVENT_SMS, this.onSms)
        this.smsSignaler.off(EVENT_SMSSTATUS, this.onSmsStatus)
    }

}