import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import List from "@mui/material/List/List";
import CallIcon from '@mui/icons-material/Call';
import bind from "bind-decorator";
import { parsePhoneNumber, PhoneNumber } from "libphonenumber-js";
import MuiPhoneNumber from "material-ui-phone-number";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { CallError } from "../helpers/errors/callError";
import { TheCall } from "../helpers/twilioCallStatus";
import { MediaTrack } from "../lib/msClient/endpoints/mediaTrack";
import { SipRemoteEndpoint } from "../lib/msClient/endpoints/sipRemoteEndpoint";
import { CallComponent } from "./callComponent";

interface ICallsProps {
    sipRemoteEndpoint: SipRemoteEndpoint
}

@observer
export class PstnComponent extends React.Component<ICallsProps> {

    _callButtonRef = React.createRef<HTMLButtonElement>()

    @observable
    private phoneNumber: PhoneNumber | undefined

    private toPhoneNumber: PhoneNumber | undefined

    @observable
    private text: string | undefined

    @observable
    callButtonEnabled: boolean = false;
    
    @observable
    requestingMedia: boolean = false;
    private _logger: any;

    constructor(public readonly props: ICallsProps) {
        super(props)
        makeObservable(this);
    }

    renderCalls = (calls: Array<TheCall>) => {
        return (<React.Fragment key="activeCalls">
        {
            calls.map((c) => {
                return <CallComponent key={`call_${c.internal_id}`} call={c}></CallComponent>
            })
        }
        </React.Fragment>)
    }

    @bind handleInputNumberOnChange(v: any) {
        try {

            const parsedPN = parsePhoneNumber(v)
            if (parsedPN && this._callButtonRef.current) {
                this.callButtonEnabled = parsedPN.isValid()
                if (parsedPN.isValid()) {
                    this.toPhoneNumber = parsedPN
                } else {
                    this.toPhoneNumber = undefined
                }
            } else {
                this.toPhoneNumber = undefined
            }
        } catch {
            this.toPhoneNumber = undefined
        }
    }

    @bind async onDialButtonClick(v: any) {

        if (this.toPhoneNumber) {
            // before placing the call, we need to make sure that we have mic access
            // get an audio track and immediately release it

            this.requestingMedia = true

            let sendTrack;
            
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                sendTrack = new MediaTrack(stream.getAudioTracks()[0], 'pstn audio');
            } catch {
                this.setState(prevState => {
                    return {
                        ...prevState,
                    }
                })
                return new CallError('Could not getUserMedia')
            } finally {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        requestingMedia: false,
                    }
                })
            }

            if (sendTrack) {
                sendTrack.track.stop()
                sendTrack = undefined
                this.props.sipRemoteEndpoint.dial(this.toPhoneNumber)
            } else {
                this._logger.error('Could not get audio track to send.')
            }
        } else {
            this._logger.error('Cannot call invalid number {}', this.toPhoneNumber)
        }
    }    
    
    render() {
        return (<React.Fragment key={`placeCallsFrom_${this.props.sipRemoteEndpoint.phoneNumber}`} >
            <Box component="div" sx={{ p: 2, border: '1px dashed grey' }}>
            <MuiPhoneNumber defaultCountry={'us'} onChange={this.handleInputNumberOnChange}/>
            <div>
            <Button disabled={!this.callButtonEnabled} ref={this._callButtonRef} color="primary" variant="contained" endIcon={<CallIcon />} onClick={this.onDialButtonClick} >
                Dial
            </Button>
            </div>
            {this.renderCalls(this.props.sipRemoteEndpoint.currentCalls)}
            {/* {this.state.requestingMedia ? this.renderAllowMedia() : null} */}
            </Box>
            </React.Fragment>)
    }    
}
