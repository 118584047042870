import { Box, Button, TextField } from "@mui/material";
import bind from "bind-decorator";
import React from "react";
import { UserService } from "../helpers/userService";

export interface CustomerFeedbackState {
    textFieldValue: string,
    sending: boolean
}

export interface CustomerFeedbackProps {
    userService: UserService
}

export class CustomerFeedback extends React.Component<CustomerFeedbackProps, CustomerFeedbackState> {
   

    constructor(props: CustomerFeedbackProps) {
        super(props)
        this.state = {
            textFieldValue: '',
            sending: false
        }
    }

    @bind
    private _handleTextFieldChange(e: any) {
        this.setState({
            textFieldValue: e.target.value
        });
    }

    @bind
    private async onSendClicked() {
        if (this.state.textFieldValue.length > 0) {
            this.setState({
                sending: true
            })
            try {
                const result = await this.props.userService.sendFeedback(this.state.textFieldValue, '123')
                if (result) {
                    this.setState({
                        textFieldValue: ''
                    })
                }
            } catch (e) {
                console.error(e)
                // show some kind of feedback
            } finally {
                this.setState({
                    sending: false
                })
            }
        }
    }

    render() {
        return(<React.Fragment>
        <TextField
          id="filled-multiline-static"
          label="Your feedback for us"
          multiline
          rows={8}
          variant="standard"
          fullWidth
          value={this.state.textFieldValue} onChange={this._handleTextFieldChange}
        />
        <div>
        <Box
            m={1} //margin
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            // sx={boxDefault}
            >
            <Button disabled={this.state.sending} variant="contained" onClick={this.onSendClicked} >Send</Button>
        </Box>
        </div>
            </React.Fragment>)
    }
}