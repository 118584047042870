import bind from "bind-decorator";
import { PhoneNumber } from "libphonenumber-js";
import { makeObservable, observable, computed, action, makeAutoObservable, autorun, IReactionDisposer, when } from "mobx"
import { MediasoupElementInfo } from "../lib/msClient/base/mediasoupElement";
import { ProducerInfo } from "../lib/msClient/base/producerInfo";
import { IDialer, Internal_PhoneCall_Operation } from "../lib/rabbitmq/pstnSignaling2";

export interface IMediaController {
    startSendingMedia(call: TheCall): Promise<ProducerInfo[]> | undefined
    stopSendingMedia(call: TheCall): void
    onStartIncomingMedia(call: TheCall): void
    onStopIncomingMedia(call: TheCall): void
    // hangup(call: TheCall): void
    // pickup(call: TheCall): void
}

export interface TheCall {
    dial(from: PhoneNumber, to: PhoneNumber): void;
    hangup(): void;
    pickup(): void;
    putOnHold(): void;
    takeOffHold(): void;
    get state(): string;
    get direction(): string;
    get from(): string;
    get to(): string;
    get isActive(): boolean;
    get isEnded(): boolean;
    get outgoingMedia(): any;
    set outgoingMedia(m: any);
    addIncomingMedia(mei: any): void;
    removeIncomingMedia(mei: any): void;
    get external_id(): string;
    set external_id(v: string);
    get internal_id(): string;
    update(n: any): void;
}

export interface ICallCommand {
    commandId: string;
    callId: string;
    operation: Internal_PhoneCall_Operation
    from: string
    to: string
}

export class CallCommand implements ICallCommand {
    constructor (public readonly commandId: string, 
    public readonly callId: string,
    public readonly operation: Internal_PhoneCall_Operation,
    public readonly from: string,
    public readonly to: string) {}
}

export class TwilioCallStatus implements TheCall
{
    public static readonly CALL_ENDED_STATES = ['failed', 'completed'];

    @observable ApiVersion: string | undefined;
    @observable Called: string | undefined;
    @observable ParentCallSid: string | undefined;
    @observable CallStatus: string;
    @observable From: string;
    @observable Direction: string;
    @observable Timestamp: string | undefined;
    @observable AccountSid: string | undefined;
    @observable CallbackSource: string | undefined;
    @observable Caller: string | undefined;
    @observable SequenceNumber: string | undefined;
    @observable CallSid: string;
    @observable To: string;

    private producerInfos: any;

    public get isActive(): boolean {
        return this.CallStatus === 'in-progress'
    }

    public get isEnded(): boolean {
        return TwilioCallStatus.CALL_ENDED_STATES.includes(this.CallStatus)
    }

    constructor(raw: TwilioCallStatus) {
        makeObservable(this)
        this.CallSid = raw.CallSid
        this.CallStatus = raw.CallStatus
        this.Direction = raw.Direction
        this.From = raw.From
        this.To = raw.To
        Object.assign(this, raw)
    }
    addIncomingMedia(mei: any): void {
        throw new Error("Method not implemented.");
    }
    removeIncomingMedia(mei: any): void {
        throw new Error("Method not implemented.");
    }
    putOnHold(): void {
        throw new Error("Method not implemented.");
    }
    takeOffHold(): void {
        throw new Error("Method not implemented.");
    }
    storeCallCommand(callCommand: CallCommand): void {
        throw new Error("Method not implemented.");
    }
    update(n: TheCall): void {
        Object.assign(this, n);
    }
    get external_id(): string {
        return this.CallSid;
    }
    get internal_id(): string {
        return this.CallSid;
    }    
    get outgoingMedia(): any {
        return this.producerInfos;
    }
    set outgoingMedia(m: any) {
        this.producerInfos = m;
    }

    get state(): string {
        return this.CallStatus;
    }
    get direction(): string {
        return this.Direction;
    }
    get from(): string {
        return this.From;
    }
    get to(): string {
        return this.To;
    }

    dial(to: PhoneNumber): void {}

    hangup(): void {}

    pickup(): void {}
}

export class BaresipCall implements TheCall
{
    public static readonly CALL_ACTIVE_STATES = ['CALL_OUTGOING', 'CALL_INCOMING', 'CALL_PROGRESS', 'CALL_ANSWERED', 'CALL_PROGRESS', 'CALL_ESTABLISHED', 'CALL_RTCP']
    private _disposers = new Array<IReactionDisposer>();
    protected callCommands = new Array<CallCommand>();
    @observable terminated: boolean = false;
    // public static readonly CALL_ENDED_STATES = []

    constructor(
        public readonly mediaController: IMediaController,
        public readonly dialer: IDialer) {
        makeObservable(this);
        // this._raw = raw;
        this._disposers.push(
            when(
                () => this.isActive, 
                async () => this._medias = await mediaController.startSendingMedia(this)
            ),
            when(
                () => (!this.isActive && (this._medias !== undefined)),
                () => {
                    mediaController.stopSendingMedia(this)
                    this._medias = undefined;
                    this._disposers.forEach(d => d())
                }
            ));
    }
    addIncomingMedia(mei: MediasoupElementInfo): void {
        this._incomingMedias.set(mei.id, mei);
    }
    removeIncomingMedia(mei: MediasoupElementInfo): void {
        this._incomingMedias.delete(mei.id)
    }
    @action
    update(n: any): void {
        this._raw = n;
    }
    get state(): string {
        return this._raw['type'];
    }
    get direction(): string {
        return this._raw['direction'];
    }
    get from(): string {
        return this.accountaor || this._raw['from'];
        // return this._raw['from'];
    }
    get to(): string {
        if (this.direction === 'incoming') {
            return this.peerdisplayname || this._raw['to']
        }
        return this.peeruri || this._raw['to'];
    }
    get peerdisplayname(): string {
        return this._raw['peerdisplayname'];
    }
    get peeruri(): string {
        return this._raw['peeruri'];
    }
    @observable private _raw: any = {
        type: 'UNDEFINED'
    };

    @computed get external_id(): string {
        return this._raw["id"];
    }

    set external_id(v: string) {
        this._raw["id"] = v
    }
    
    @computed get internal_id(): string {
        return this._raw["userdata"] || this._raw["internal_id"] || this.external_id;
    }

    @computed get accountaor(): string {
        return this._raw["accountaor"];
    }

    @computed get param(): string {
        return this._raw["param"];
    }

    @computed get class(): string {
        return this._raw["class"];
    }

    @computed get type(): string {
        return this._raw["type"];
    }

    // @action set raw(x: string) {
    //     this._raw = JSON.parse(x);
    //     // console.log('_raw', this._raw);
    // }

    @computed
    public get isActive(): boolean {
        return !this.isEnded
    }

    @computed
    public get isEnded(): boolean {
        return this.terminated || this.state === 'CALL_CLOSED'
    }

    get outgoingMedia(): ProducerInfo[] | undefined {
        return this._medias;
    }
    set outgoingMedia(m: any) {
        this._medias = m;
    }

    @bind
    dial(fromPhoneNumer: PhoneNumber, to: PhoneNumber) {
        const callCommand = this.dialer.dial(fromPhoneNumer, to)

        const data = {
            internal_id: callCommand.callId,
            from: callCommand.from,
            to: callCommand.to,
            direction: 'outgoing',
            type: 'CALL_OUTGOING'
        }

        this._raw = data;

        this.callCommands.push(callCommand);
        // this.storeCallCommand(callCommand);
    }

    @bind
    hangup() {
        this.terminated = true
        const callCommand = this.dialer.hangup(this)
        this.callCommands.push(callCommand)
    }

    @bind
    pickup(): void {
        const callCommand = this.dialer.pickup(this);
        this.callCommands.push(callCommand);
    }

    putOnHold(): void {
        throw new Error("Method not implemented.");
    }
    takeOffHold(): void {
        throw new Error("Method not implemented.");
    }    

    @observable
    private _medias: ProducerInfo[] | undefined = undefined

    @observable
    private _incomingMedias = new Map<string, MediasoupElementInfo>()
}