import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Backdrop, Box, Button, CircularProgress, Link, Stack, Typography } from '@mui/material';
import LoggedInEmuApp from './loggedInEmuApp';
import { Logger } from './helpers/logger';
import LoggedInChatApp from './loggedInChatApp';
import { observer } from 'mobx-react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
import { UserSettings } from './components/userSettings';
// import { IUserData } from './components/userDetails';
import { UserService } from './helpers/userService';
import { PublicService } from "./helpers/publicService";
import { SubscribedComponent } from './components/subscribedComponent';
import { CommonAppTitle } from './helpers/utilComponents';
import jwt_decode from 'jwt-decode';
import { JSX } from 'react/jsx-runtime';

const logger = new Logger('LoggedInApp')

// const dummyUserData: IUserData = {
//     id: 'abc123',
//     name: 'John Doe', 
//     email: 'test@name.com',
//     phoneNumber: '+1234567879'
// }

function onDeleteAccount(): boolean {
    console.log('dummy onDeleteAccount')
    return true
}
  
function onUserSettingsClose() {
  
}

export interface LoggedInAppProps {
    publicService: PublicService
}

interface LoggedInAppState {
    userService: UserService | null
}

class SetSubscribed extends React.Component {

    componentDidMount(): void {
        localStorage.setItem(SubscribedComponent.subscribedKey, '1')
        window.location.replace('/')
    }

    render() {
        return <p>Please wait</p>
    }
}

@observer
export default class LoggedInApp extends React.Component<LoggedInAppProps, LoggedInAppState> {

    constructor(props: LoggedInAppProps) {
        super(props)
        this.state = {
            userService: null
        }
    }

    protected useCustomLogin() {

        const token = localStorage.getItem('token')
        if (token) {

            const dtoken = jwt_decode(token) as any;
            if (process.env.REACT_APP_ENV === 'v2prod' && dtoken.kid !== 'v2prod') {
                localStorage.removeItem('token')
                window.location.href = 'login'
            } else {
                if (!process.env.REACT_APP_SFU_ID) {
                    throw new Error('Invalid configuration: no sfuId defined.');
                }
                const sfuId = process.env.REACT_APP_SFU_ID;
                const userService = new UserService({
                    token, sfuId
                }, this.props.publicService)

                this.setState({ userService })
            }
        } else {
            window.location.href = 'login'
        }
    }

    async componentDidMount() {
        return this.useCustomLogin()
    }

    private renderWait(color: "inherit" | "primary" | "secondary" | undefined = "inherit") {
        return (
            <React.Fragment>
                <Backdrop open={true} >
                    <CircularProgress color={color} />
                </Backdrop>
            </React.Fragment>
        )
    }

    public get loggedInApp(): React.ReactNode {
        if (this.state.userService) {
            if (process.env.REACT_APP_MODE === 'chat') {
                return <LoggedInChatApp userService={this.state.userService}></LoggedInChatApp>
            } else {
                if (process.env.REACT_APP_MODE === 'emu') {
                    return <LoggedInEmuApp userService={this.state.userService}></LoggedInEmuApp>
                } else {
                    throw new Error(`Unknown APP_MODE ${process.env.REACT_APP_MODE}`)
                }
            }
        } else {
            return null
        }
    }

    render() {
        if (this.state.userService) {
            return (
                <React.Fragment>
                    <Router>
                        <Switch>
                            <Route path='/userSettings'>
                                <UserSettings show={true} selectedTab={0}  
                                onClose={onUserSettingsClose} onDeleteAccount={onDeleteAccount} userService={this.state.userService!} />
                            </Route>
                            {/* <Route path='/subscribed'>
                                <SetSubscribed></SetSubscribed>
                            </Route> */}
                            <Route path='/'>
                                {this.loggedInApp}
                            </Route>
                        </Switch>
                    </Router>
                </React.Fragment>
            )
        } else {
            return this.renderWait("secondary")            
        }
    }
}

@observer
export class SharedLoggedInApp extends LoggedInApp {

    private navigateToSharedExpired(delay: number = 0) {
        // setTimeout(() => window.location.href = '/shared/expired', delay)
    }

    protected override async useCustomLogin() {

        const code = window.location.pathname.split("/").pop()

        if (!code) throw new Error("cannot find code")
        
        var token
        try {
            token = await this.props.publicService.getToken(code)
        } catch {
            // this.navigateToSharedExpired()
            return;
        }

        if (token) {
            // todo: check if token is expired
            window.history.pushState({}, '', '/shared');
            // window.history.replaceState({}, '', '/shared')

            if (!process.env.REACT_APP_SFU_ID) {
                throw new Error('Invalid configuration: no sfuId defined.');
            }
            const sfuId = process.env.REACT_APP_SFU_ID;
            const userService = new UserService({
                token, sfuId
            }, this.props.publicService)

            this.setState({ userService })
            // this.navigateToSharedExpired(1 * 60 * 60 * 1000)
        } else {
            // this.navigateToSharedExpired()
        }
    }

    render(): JSX.Element {
        if (this.state.userService) {
            return super.render()
        }
        return <>
        <SharedExpired/>
        </>
    }

}

export class SharedExpired extends React.Component {

    render() {
        return <React.Fragment>
            <Box display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh">
            <Stack sx={{ minWidth: '20%', maxWidth: '80%' }} spacing={2}>
                <CommonAppTitle/>                
                <Typography>Your shared session has expired or the provided token is not valid. Please request a new token.</Typography>
                <Typography align="center" justifyContent={"center"}>Alternatively, you can <Link href='/login'>login</Link> or <Link href='/signup'>sign up</Link>.</Typography>
            </Stack>
            </Box>
        </React.Fragment>
    }
}