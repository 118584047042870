import bind from 'bind-decorator';
import { EventEmitter2 } from 'eventemitter2';
import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js';
import { ProducerInfo } from '../lib/msClient/base/producerInfo';
import { MediaTrack } from '../lib/msClient/endpoints/mediaTrack';
import { CallError } from './errors/callError';
import { TheCall } from './twilioCallStatus';

export const EVENT_CALL_OPERATION = 'calloperation'
export const EVENT_CALL_STATUS = 'callstatus'
export const EVENT_COMMAND_RESPONSE = 'commandresponse'

export interface ICallActions {
    pickup(track: MediaStreamTrack | undefined): Promise<void>
    hangup(stopMediaTrack: boolean): void
}

export abstract class Call extends EventEmitter2 implements ICallActions {

    public static EVENT_CALL: string = 'call'
    public static EVENT_CALL_DIALING: string[] = [ Call.EVENT_CALL,  'dialing' ]
    public static EVENT_CALL_CONNECTED: string[] = [ Call.EVENT_CALL,  'connected' ]
    public static EVENT_CALL_DISCONNECTED: string[] = [ Call.EVENT_CALL, 'disconnected' ]

    constructor(
        public readonly id: string,
        public readonly from: PhoneNumber, 
        public readonly to: PhoneNumber,
        public producerInfos: ProducerInfo[]) { 
            super({wildcard: true})
        }

    abstract pickup(track: MediaStreamTrack): Promise<void>
    abstract hangup(stopMediaTrack: boolean): void
}

// export interface IDialer extends EventEmitter2 {
//     dial(number: PhoneNumber): void
//     pickup(call: TheCall): void
//     hangup(call: TheCall): void
// }
