import bind from "bind-decorator";
import { EventEmitter2 } from "eventemitter2";
import { MqClient } from "../../rabbitmq/mqClient";
import { MsClient } from "../base/msClient";
import { LocalEndpoint } from "./localEndpoint";
import { RemoteEndpoint } from "./remoteEndpoint";
import { GroupSignaler, MsSignaler } from "../../rabbitmq/swisstchSignalers";
import { PresenceService } from "../../rabbitmq/presenceService";
import { Logger } from "../../../helpers/logger";
import { SipRemoteEndpoint } from "./sipRemoteEndpoint";
import { parsePhoneNumber } from "libphonenumber-js";
import { IReactionDisposer, ObservableMap, autorun, computed, makeObservable, observable, runInAction } from "mobx";
import { UserService } from "../../../helpers/userService";
import { RtpCodecCapability } from "mediasoup-client/lib/RtpParameters";
import { EmulatorRemoteEndpoint2 } from "./emulatorRemoteEndpoint2";
import { localEndpointState } from "../../state/localEndpointState";

const logger = new Logger('Conference')

const MAX_RECONNECT_COUNT = 10

export class ConferenceState {

    @observable public readonly localEndpoint: LocalEndpoint;
    @observable public remoteEndpoints = new ObservableMap<string, RemoteEndpoint>()
    @observable public sfuConnected: boolean = false

    @observable public paymentMethodRequired: boolean = false

    @computed public get mqClientConnected() {
        return this.mqClient.connected
    }

    @computed public get emuBridgeRemoteEndpoint(): EmulatorRemoteEndpoint2 | undefined {
        const ret = Array.from(this.remoteEndpoints.values()).filter(re => re.type === 'emubridge')
        if (ret && ret.length === 1) {
            return ret[0] as EmulatorRemoteEndpoint2
        }
        return undefined
    }

    constructor(localEndpoint: LocalEndpoint, private readonly mqClient: MqClient) {
        this.localEndpoint = localEndpoint
        makeObservable(this)
    }
}

export class Conference extends EventEmitter2 {

    private readonly mqClient: MqClient
    private readonly msClient: MsClient;
    private readonly groupSignaler: GroupSignaler
    private readonly presenceService: PresenceService;
    
    public readonly state: ConferenceState;

    private readonly msSignaler: MsSignaler;

    // public static readonly EVENT_REMOTE_ENDPOINT_JOINED = 'endpoint joined'
    public static readonly EVENT_MAX_RECONNECT_ATTEMPTS = 'max reconnect attempts reached'
    // public static readonly EVENT_REMOTE_ENDPOINT_LEAVING = 'endpoint leaving'
    // public static readonly EVENT_LOCAL_ENDPOINT_JOINED = 'local endpoint joined';
    // public static readonly EVENT_LOCAL_ENDPOINT_LEFT = 'local endpoint left';

    private _anyEndpointConnectedListener: any;
    private _anyEndpointDisconnectedListener: any;
    private _disposers = new Array<IReactionDisposer>();

    public paymentMethodRequiredMessage: string = "Hello! You need to upgrade to use this functionality!";

    constructor (private readonly userService: UserService) {
        super({wildcard: true})
        makeObservable(this)

        this.mqClient = new MqClient(userService.serverAddress, userService.vhost, userService.exchangeId, userService.token, MAX_RECONNECT_COUNT)
        this.mqClient.on('max reconnect attempts reached', this.onMaxReconnectAttempts)
        
        this.presenceService = new PresenceService(this.mqClient, userService.exchangeId)
        this._anyEndpointConnectedListener = this.presenceService.on(PresenceService.ANY_ENDPOINT_CONNECTED, this.onHiEndpoint)
        this._anyEndpointDisconnectedListener = this.presenceService.on(PresenceService.ANY_ENDPOINT_DISCONNECTED, this.onByeEndpoint)
        this.msSignaler = new MsSignaler(this.mqClient, this.presenceService, userService.sfuId)
        this.msClient = new MsClient(this.msSignaler)

        this._disposers.push(autorun(() => {
            if (this.msClient.codecs !== null) {
                if (!this.msClient.codecs) {
                    throw new Error('no codecs')
                } else {
                    if (!this.checkCodecs(this.msClient.codecs)) {
                        throw new Error('necessary codecs (VP8 | h264 & opus) not found')
                    }
                }
            }
        }))

        this._disposers.push(autorun(() => {
            if (this.msClient.error !== null) {
                throw new Error('error: unsupported browser')
            }
        }))

        this.groupSignaler = new GroupSignaler(this.mqClient)
        this.groupSignaler.on(['*', '*', 'PaymentMethodRequired'], this.onPaymentMethodRequired)
        this.groupSignaler.on(['*', '*', 'NavigateToUrl'], this.onNavigateToUrl)
        
        const localEndpoint = new LocalEndpoint(this.msClient, { userType: "browser" })
        localEndpoint.onConnectedAsync(this.onLocalEndpointConnected)

        this.state = new ConferenceState(localEndpoint, this.mqClient)
    }
    
    @bind
    public checkCodecs(codecs: RtpCodecCapability[]): boolean {
        let hasAudio = false
        let hasVideo = false
        codecs.forEach((c) => {
            if (!hasVideo && c.kind === "video") {
                if (c.mimeType.toLowerCase().includes('VP8'.toLowerCase()) || c.mimeType.toLowerCase().includes('H264'.toLowerCase())) {
                    hasVideo = true
                    logger.debug('found video codec', c)
                }
            } else {
                if (!hasAudio && c.kind === 'audio') {
                    if (c.mimeType.toLowerCase().includes('opus'.toLowerCase())) {
                        hasAudio = true
                        logger.debug('found audio codec', c)
                    }
                }
            }
        })
        return hasAudio && hasVideo
    }
    
    @bind
    private onNavigateToUrl(sender: string, data: any) {
        window.location = data.url
    }
    
    @bind
    private onPaymentMethodRequired(sender: string, data: any) {
        runInAction(() => {
            this.paymentMethodRequiredMessage = data.message
            this.state.paymentMethodRequired = true
        })
    }

    @bind
    private onMaxReconnectAttempts() {
        this.emit(Conference.EVENT_MAX_RECONNECT_ATTEMPTS)
    }

    @bind
    private onHiEndpoint(data: any) {
        if (data.sid.startsWith("sfu")) {
            runInAction(() =>this.state.sfuConnected = true)
            return
        }

        if (data.sid === this.mqClient.id) {
            // this should not happen
            return
        }

        let remoteEndpoint = this.state.remoteEndpoints.get(data.sid)
        if (!remoteEndpoint) {
            let remoteEndpoint: RemoteEndpoint
            if (data.sid.toLowerCase().startsWith('sipbridge')) {
                // const phoneNumber = parsePhoneNumber(this.userService.resourceId)
                remoteEndpoint = new SipRemoteEndpoint(data.sid, this.msClient, this.groupSignaler, 
                    this.presenceService, this.state.localEndpoint);
            } else {
                if (data.sid.toLowerCase().startsWith('emubridge')) {
                    remoteEndpoint = new EmulatorRemoteEndpoint2(data.sid, this.msClient, this.groupSignaler, 
                        this.presenceService, this.state.localEndpoint);

                    remoteEndpoint.on('showSettings', () => {
                        localEndpointState.userSettings.show = true
                    });
                                    
                } else {                
                    const autoConsumeProducers = (process.env.REACT_APP_AUTOCONSUME_PRODUCERS === 'true')
                    remoteEndpoint = new RemoteEndpoint(data.sid, this.msClient, this.groupSignaler, 
                        this.presenceService, autoConsumeProducers)
                }
            }
            // remoteEndpoint.on('leaving', () => this.remoteEndpoints.delete(remoteEndpoint.id))
            runInAction(() => this.state.remoteEndpoints.set(remoteEndpoint.id, remoteEndpoint))
            // this.emit(Conference.EVENT_REMOTE_ENDPOINT_JOINED, remoteEndpoint)
        } else {
            /* We've already seen this endpoint, we should take care of this situation somehow */
        }
    }

    @bind
    private onByeEndpoint(data: any) {
        const remoteEndpoint = this.state.remoteEndpoints.get(data.sid)
        if (remoteEndpoint) {
            remoteEndpoint.close()
            runInAction(() => this.state.remoteEndpoints.delete(remoteEndpoint.id))
            // this.emit(Conference.EVENT_REMOTE_ENDPOINT_LEAVING, remoteEndpoint)
        } else {
            if (data.sid.startsWith("sfu")) {
                runInAction(() => this.state.sfuConnected = false)
                return
            } else {
                logger.warn('An endpoint that weve never seen has disconnected')
            }
        }
    }

    @bind
    private async onLocalEndpointConnected() {
        logger.debug('Conference.onLocalEndpointConnected()')
        this.state.localEndpoint.once(this.state.localEndpoint.EVENT_DISCONNECTED, this.onLocalEndpointDisconnected)
        // this.emit(Conference.EVENT_LOCAL_ENDPOINT_JOINED, this.localEndpoint)
        // await this.localEndpoint.produceDefaultMedia()
    }

    @bind
    private async onLocalEndpointDisconnected() {
        logger.debug('Conference.onLocalEndpointDisconnected()')
        // this.emit(Conference.EVENT_LOCAL_ENDPOINT_LEFT, this.localEndpoint)
        // await this.localEndpoint.produceDefaultMedia()
    }

    @bind
    public connect() {
        this.mqClient.connect()
    }

    @bind
    public stop() {
        this._anyEndpointConnectedListener.off();
        this._anyEndpointDisconnectedListener.off();
        this.state.localEndpoint.stop();
        runInAction(() => this.state.remoteEndpoints.clear());
    }
}