import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import bind from "bind-decorator";
import React from "react";
import { PublicService } from "../helpers/publicService";
import { CommonAppTitle } from "../helpers/utilComponents";

export class ConfirmEmailPage extends React.Component {

    componentDidMount(): void {
        document.title = 'Thank you!'
    }

    render() {
        return <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack sx={{ minWidth: '20%', maxWidth: '80%' }} spacing={2}>
            <CommonAppTitle/>
            <Typography align="center" justifyContent={"center"}>
                <p>Thank you for your registration! Please verify your email address by clicking on the link we've just sent you.</p>
            </Typography>
        </Stack>
      </Box>
    }
}

type tokenVerificationState = 'notstarted' | 'pending' | 'verifying' | 'ok' | 'error'

interface IVerifyEmailState {
    verificationState: tokenVerificationState
}

interface IVerifyEmailProps {
    publicService: PublicService
}

export class VerifyEmail extends React.Component<IVerifyEmailProps, IVerifyEmailState> {

    constructor(props: IVerifyEmailProps) {
        super(props)
        this.state = {
            verificationState: 'notstarted'
        }
    }

    @bind
    private async verifyToken(vtoken: string): Promise<void> {
        try {
            this.setState({
                verificationState: 'verifying'
            })
            const res = await this.props.publicService.confirmEmail(vtoken)

            if (res.status === 200) {
                this.setState({
                    verificationState: 'ok'
                })
            }
        } catch {
            this.setState({
                verificationState: 'error'
            })
        }
    }

    componentDidMount() {
        const { t } = Object.fromEntries(new URLSearchParams(document.location.search));

        document.title = 'Verify your email'

        if (t && t.length > 10) {
            setTimeout(this.verifyToken, 100, t)
        } else {
            // silent error
        }
    }

    render() {
        let text = 'Verifying your email address'
        if (this.state.verificationState === 'ok') {
            text = 'Verification successful! You can now login.'
        }
        if (this.state.verificationState === 'error') {
            text = 'An error occured while verifying your email address.'
        }
        if (this.state.verificationState === 'pending') {
            text = 'Verification in progress...'
        }
        

        return <Box display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh">
            <Stack sx={{ minWidth: '20%', maxWidth: '80%' }} spacing={2}>
                
            <CommonAppTitle/>
            <Typography align="center" justifyContent={"center"}>
                <p>{text}</p>
            </Typography>
            { this.state.verificationState === 'ok' && <Button onClick={this.toLogin} >Go to login</Button> }
            </Stack>
        </Box>
    }    
    
    private toLogin() {
        document.location.replace('login')
    }
}