import { Avatar, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@mui/material";
import React from "react";
import { Invoice, UserService } from "../helpers/userService";
import ReceiptIcon from '@mui/icons-material/Receipt';

export interface ICostOverviewProps {
    userService: UserService
}

export interface ICostOverviewState {
    errored: boolean
    nextInvoice: Invoice | undefined
}

export class CostOverview extends React.Component<ICostOverviewProps, ICostOverviewState> {
    
    constructor(props: ICostOverviewProps) {
        super(props)
        this.state = {
            errored: false,
            nextInvoice: undefined
        }
    }

    componentDidMount(): void {
        this.props.userService.getNextInvoice().then((i) => {
            this.setState({
                errored: false,
                nextInvoice: i
            })
        }).catch((reason: any) => {
            this.setState({
                errored: true,
                nextInvoice: undefined
            })
        })
    } 

    render() {
        return (
            <React.Fragment>
            {this.state.errored && <HasError></HasError>}
            {!this.state.errored && !this.state.nextInvoice && <WaitLoadNextInvoice></WaitLoadNextInvoice>}
            {!this.state.errored && this.state.nextInvoice && <NextInvoice invoice={this.state.nextInvoice} ></NextInvoice>}
            </React.Fragment>
        )
        }
    }

    class HasError extends React.Component {
        render() {
            return (<React.Fragment>
                <div>
                    An error has occured
                </div>
            </React.Fragment>)
        }
    }

    class WaitLoadNextInvoice extends React.Component {
        render() {
            return (<React.Fragment>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress color='info' />
                </div>
            </React.Fragment>)
        }
    }

    class NoSubscription extends React.Component {
        render() {
            return (
                <React.Fragment>
                    <p>No upcoming invoices</p>
                </React.Fragment>
            )
        }
    }

    interface InvoiceProps {
        invoice: Invoice
    }

    class NextInvoice extends React.Component<InvoiceProps> {
        render() {
            let text: string
            let date: string
            if (this.props.invoice.id != "-1") {
                text = (this.props.invoice.amount_due/100).toString() + this.props.invoice.currency
                date = this.props.invoice.due_date ? new Date(this.props.invoice.due_date * 1000).toDateString() : "unknown date"
            } else {
                text = "No upcoming invoice"
                date = ""
            }
            return (
                <React.Fragment>
                    <Box sx={{ m: 2 }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Upcoming invoices</FormLabel>
                        <FormGroup>
                            <List>
                            <ListItem>
                                <ListItemAvatar>
                                <Avatar>
                                    <ReceiptIcon />
                                </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={text} secondary={date} />
                            </ListItem>
                            </List>
                        </FormGroup>
                    </FormControl>
                    </Box>
                </React.Fragment>
            )
        }
    }