import bind from "bind-decorator"
import { observer } from "mobx-react"
import { autorun, IReactionDisposer, makeObservable, observable, runInAction } from 'mobx'
import React from "react"
import { RemoteEndpoint } from "../lib/msClient/endpoints/remoteEndpoint"
// import { IUserData } from "./userDetails"
import { Alert, Backdrop, Button, DialogContent, Link, Stack } from "@mui/material"
import { Conference } from "../lib/msClient/endpoints/conference"
import { EmulatorRemoteEndpoint } from "../lib/msClient/endpoints/emulatorRemoteEndpoint"
import { EmulatorRemoteEndpointComponent } from "./emulatorRemoteEndpointComponent"
import { SipForEmulatorRemoteEndpointComponent } from "./sipForEmulatorRemoteEndpointComponent"
import { SipRemoteEndpoint } from "../lib/msClient/endpoints/sipRemoteEndpoint"
import { BootstrapDialog, BootstrapDialogTitle } from "./paymentPopupComponent"
import StripePricingTable from "./stripePricingTable"
import { UserService } from "../helpers/userService"
import { UserSettings } from "./userSettings"
import { SubscribedComponent } from "./subscribedComponent"
import DebugDialog from "./debug/debugMenu"
import { EmulatorRemoteEndpoint2 } from "../lib/msClient/endpoints/emulatorRemoteEndpoint2"
import { localEndpointState } from "../lib/state/localEndpointState"
import { ConferenceStateComponent } from "./conferenceStateComponent"

const synth = window.speechSynthesis;

export interface IConferenceProps {

    userService: UserService
}

export interface IPaymentData {
    paymentClientSecret?: string
}

export interface IConferenceState {
    paymentData?: IPaymentData
    // userData: IUserData
    maxReconnectsReached: boolean
    conference: Conference
}

@observer
export class EmulatorConferenceComponent extends React.Component<IConferenceProps, IConferenceState> {
    
    protected readonly conference: Conference;
    private _refreshPageButton = React.createRef<HTMLButtonElement>()
    private _disposers = new Array<IReactionDisposer>()
    private userSettingsComponent = React.createRef<UserSettings>()

    @observable showDebugMenu: boolean = false

    private openSettingsLink = <Link component="button" onClick={ () => runInAction(() => localEndpointState.userSettings.show = true) } >Open settings</Link>

    constructor(props: IConferenceProps) {
        super(props)
        makeObservable(this)
        this.conference = new Conference(this.props.userService)
        this.conference.on(Conference.EVENT_MAX_RECONNECT_ATTEMPTS, this.onMaxReconnectAttempts)
        
        this._disposers.push(autorun(() => {
            if (this.conference.state.paymentMethodRequired) {
                if (synth) {
                    const utterance = new SpeechSynthesisUtterance(this.conference.paymentMethodRequiredMessage);
                    synth.speak(utterance);
                }
            }
        }))
        this._disposers.push(autorun(() => {
            if (localEndpointState.userSettings.show) {
                this.userSettingsComponent.current?.show()
            } else {
                this.userSettingsComponent.current?.hide()
            }
        }))

        this.state = { maxReconnectsReached: false, conference: this.conference }

        const storedShowDebugMenu = localStorage.getItem('showDebugMenu')
        this.showDebugMenu = storedShowDebugMenu != null && storedShowDebugMenu === '1'

        // this.conference.on(['*', '*', 'PaymentMethodRequired'], this.onPaymentMethodRequired)
        // this.conference.on(Conference.EVENT_REMOTE_ENDPOINT_JOINED, this.onRemoteEndpointJoined)
        // this.conference.on(Conference.EVENT_REMOTE_ENDPOINT_LEAVING, this.onRemoteEndpointLeaving)
    }
    
    // @bind
    // private onPaymentMethodRequired(sender: string, data: any) {
    //     console.log('onPaymentmethodRequired', sender, data)
    //     this.setState({showPaymentPopup: true})
    // }
    
    // @bind
    // public showSettings(data: any) {
    //     if (this.userSettingsComponent.current) {
    //         const tab = data.selectedTab ?? 0
    //         this.userSettingsComponent.current.show(tab)
    //     }
    // }

    @bind
    private onMaxReconnectAttempts() {
        this.props.userService.logout()
        // this.setState({ maxReconnectsReached: true })
    }
    
    componentDidMount() {
        setTimeout(this.conference.connect, 0)
        window.addEventListener('beforeunload', this.cleanup);
    }

    componentWillUnmount() {
        this.cleanup()
    }

    @bind
    private cleanup() {
        window.removeEventListener('beforeunload', this.cleanup); // remove the event handler for normal unmounting
    }

    @bind
    refreshPage() {
        window.location.reload()
    }

    renderRefreshOverlay = () => {
        return <React.Fragment>
        <Backdrop open={true} >
        <Stack alignItems="center" spacing={2} >
            <Button color="secondary" variant="contained" size="large" onClick={this.refreshPage} ref={this._refreshPageButton} >Refresh</Button>
            { this.openSettingsLink }
        </Stack>
        </Backdrop>
        </React.Fragment>
    }

    protected renderRemoteEndpoint = (e: RemoteEndpoint) => {
        if (e.type === 'default') {
            // for now we don't want any rendering of other remote users
            return null;
            // return <RemoteEndpointComponent key={e.id} endpoint={e}></RemoteEndpointComponent>
        } 

        if (e.type === 'sipbridge') {
            const sipRemoteEndpoint = e as SipRemoteEndpoint
            return <SipForEmulatorRemoteEndpointComponent key={e.id} sipRemoteEndpoint={sipRemoteEndpoint}></SipForEmulatorRemoteEndpointComponent>
        }

        if (e.type === 'emubridge') {
            const emulatorRemoteEndpoint = e as EmulatorRemoteEndpoint2
            return <>
                <EmulatorRemoteEndpointComponent key={emulatorRemoteEndpoint.id} endpoint={emulatorRemoteEndpoint} openSettingsLink={this.openSettingsLink} ></EmulatorRemoteEndpointComponent>              
            </>
        }
        console.error('unhandled endpoint type', e.type)
        return null
    }

    protected renderRemoteEndpoints = (res: RemoteEndpoint[]) => {


        return (<React.Fragment key="emuConference"> 
        {
            res.map((e) => {
                return this.renderRemoteEndpoint(e)
        })}
        </React.Fragment>)
    }

    private renderConferenceState() {
        return (
            <React.Fragment>
                <ConferenceStateComponent conferenceState={this.conference.state} openSettingsLink={this.openSettingsLink} />
            </React.Fragment>
        )
    }

    private renderPaymentDialog(): React.ReactNode {
        return (
        <div>
            <BootstrapDialog
            // onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.conference.state.paymentMethodRequired}
            >
            <BootstrapDialogTitle onClose={() => runInAction(() => this.conference.state.paymentMethodRequired = false)}>
                Subscription needed
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <StripePricingTable clientId={this.props.userService.userId} clientEmail={this.props.userService.userEmail}></StripePricingTable>
            </DialogContent>
            </BootstrapDialog>
        </div>
        )
    }        

    protected renderPhoneConference(): React.ReactFragment {
        return <React.Fragment key="phone_conference">
            {this.state.maxReconnectsReached && this.renderRefreshOverlay()}
            { this.renderConferenceState()}
            { this.conference.state.sfuConnected && this.renderRemoteEndpoints(Array.from(this.conference.state.remoteEndpoints.values()))}
            { this.renderPaymentDialog()}
        </React.Fragment>
    }

    protected renderUserSettings() {
        return (<React.Fragment>
            <UserSettings ref={this.userSettingsComponent} onClose={() => localEndpointState.userSettings.show = false}
                onDeleteAccount={() => {return true}} show={ localEndpointState.userSettings.show} userService={this.props.userService}>
            </UserSettings>
        </React.Fragment>)
    }

    protected renderDebugDialog() {
        return (<React.Fragment>
            <DebugDialog open={this.showDebugMenu} conference={this.conference} ></DebugDialog>
        </React.Fragment>)
    }

    render() {
        return <React.Fragment key="emulatorConferenceComponent" >
            { this.renderDebugDialog() }
            { this.renderPhoneConference() }
            { this.renderUserSettings() }
            {/* { this.renderSubscribedDialog() } */}
        </React.Fragment>
    }
    
    @bind
    renderSubscribedDialog(): React.ReactNode {
        const x = localStorage.getItem(SubscribedComponent.subscribedKey)
        if (x && x === '1') {
            // localStorage.removeItem(SubscribedComponent.subscribedKey)
            return <SubscribedComponent></SubscribedComponent>
        } else {
            return null
        }
    }
    
}