import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Accordion, AccordionDetails, AccordionSummary, Input, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Conference } from '../../lib/msClient/endpoints/conference';
import { RemoteEndpoint } from '../../lib/msClient/endpoints/remoteEndpoint';
import { ExpandMore } from '@mui/icons-material'
import { EmulatorRemoteEndpoint, KeyEventType } from '../../lib/msClient/endpoints/emulatorRemoteEndpoint';
import { DraggablePaperComponent } from '../custom/draggablePaperComponent';

interface IDebugDialogProps {
  open: boolean
  conference: Conference
}

const KeyCodeCommand = (opts: {emulatorRemoteEndpoint: EmulatorRemoteEndpoint}) => {

    const [keyCode, setKeyCode] = React.useState<number>()

    return <>
      <Input onChange={(ev) => setKeyCode(Number.parseInt(ev.target.value))}/>
      <Button disabled={keyCode === undefined} onClick={() => opts.emulatorRemoteEndpoint.sendKeyboard({ key: 'Power', eventType: KeyEventType.KEYPRESSED })}>Send keyCode</Button>
    </>

}

const KeyCommand = (opts: {emulatorRemoteEndpoint: EmulatorRemoteEndpoint}) => {

  const [key, setKey] = React.useState<string>("0")

  const sendKeyCommand = () => {
    var keyCode = parseInt(key);

    if(isNaN(keyCode))
      opts.emulatorRemoteEndpoint.sendKeyboard({ key, eventType: KeyEventType.KEYPRESSED })
    else
      opts.emulatorRemoteEndpoint.sendKeyboard({ keyCode, eventType: KeyEventType.KEYPRESSED })
  }

  return <>
    <Input onChange={(ev) => setKey(ev.target.value)}/>
    <Button disabled={key === "0"} onClick={sendKeyCommand}>Send key pressed</Button>
  </>

}

const EvDevCommand = (opts: {emulatorRemoteEndpoint: EmulatorRemoteEndpoint}) => {

  const [evdev, setEvDev] = React.useState<string>()
  const [eventType, setEventType] = React.useState<number>(0)
  
  return <>
    <Input type='number' onChange={(ev) => setEvDev(ev.target.value)}/>
    <InputLabel id="demo-simple-select-label">Event type</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={eventType}
      label="Event type"
      onChange={(e) => setEventType(e.target.value as number)}
    >
      <MenuItem value={0}>Down</MenuItem>
      <MenuItem value={1}>Up</MenuItem>
      <MenuItem value={2}>Pressed</MenuItem>
    </Select>    
    <Button disabled={evdev === undefined} onClick={() => opts.emulatorRemoteEndpoint.sendEvDev(parseInt(evdev!), eventType)}>Send evdev</Button>
  </>

}


//send_getVmState

const VmState = (opts: {emulatorRemoteEndpoint: EmulatorRemoteEndpoint}) => {

  return <>
    <Button onClick={() => opts.emulatorRemoteEndpoint.send_getVmState()}>Get VmState</Button>
  </>

}

function renderEmuBridgeForDebug(e: EmulatorRemoteEndpoint) {
  return (<Accordion key={e.id} >
    <AccordionSummary
    expandIcon={<ExpandMore />}
    aria-controls="panel1a-content"
    id="panel1a-header"
  >
    <Typography>{e.id}</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Stack spacing={2} >
      <Button onClick={() => e.send(RemoteEndpoint.START_PRODUCER)}>Start producing media</Button>
      <Button onClick={() => e.send(RemoteEndpoint.STOP_PRODUCER)}>Stop producing media</Button>
      <Button onClick={() => e.send(RemoteEndpoint.SUSPEND)}>Suspend</Button>
      <Button onClick={() => e.send(RemoteEndpoint.RESUME)}>Resume</Button>
      {/* <Button onClick={() => e.send(RemoteEndpoint.START)}>Start</Button> */}
      <Button onClick={() => e.send(RemoteEndpoint.TERMINATE)}>Terminate</Button>
      <KeyCommand emulatorRemoteEndpoint={e} ></KeyCommand>
      <EvDevCommand emulatorRemoteEndpoint={e} ></EvDevCommand>
      <VmState emulatorRemoteEndpoint={e}></VmState>
    </Stack>
  </AccordionDetails>
</Accordion>)
}

function renderEndpointForDebug(e: RemoteEndpoint) {

  if (e.type === 'emubridge') return renderEmuBridgeForDebug(e as EmulatorRemoteEndpoint)
  return null
  
}

export default function DebugDialog(props: IDebugDialogProps) {
  const [open, setOpen] = React.useState(props.open);
  const [message, setMessage] = React.useState('')

  const conference = props.conference

  const handleClose = () => {
    setOpen(false);
  };

  const sendMessage = () => {
    console.log('message is', message)
  }


  return (
      <Dialog
        open={open}
        // onClose={handleClose}
        PaperComponent={DraggablePaperComponent}
        aria-labelledby="draggable-dialog-title"
        hideBackdrop // Disable the backdrop color/image
        disableEnforceFocus // Let the user focus on elements outside the dialog
        // style={{     position: 'absolute',
        //  left: 10,
        //  top: 50  }} // This was the key point, reset the position of the dialog, so the user can interact with other elements
        style={{ position: 'initial'}}
        // disableBackdropClick // Remove the backdrop click (just to be sure)
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Debug
        </DialogTitle>
        <DialogContent>
          {
            Array.from(conference.state.remoteEndpoints.values()).map((e) => renderEndpointForDebug(e))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
  );
}