import { ok } from "assert";
import axios, { AxiosInstance, AxiosResponse } from "axios";

export class SignupData {
    constructor(
    public readonly subscriptionId: string,
    public readonly username: string,
    public readonly password: string,
    public readonly recaptchaToken_v2: string | undefined,
    public readonly acceptedTnC: boolean,
    public readonly productId: string,
    public readonly phoneNumber: string | undefined,
    public readonly priceId: string,
    public readonly promotionCode: string | null,
    public readonly stripeCustomerId: string,
    public readonly countryCode: string) {}
}

export class PresignupBody {
    constructor(
        public readonly stripeCustomerId: string | undefined,
        public readonly subscriptionId: string | undefined,
        public readonly productId: string | undefined,
        public readonly priceId: string | undefined,
        public readonly promoCode: string | null,
        public readonly countryCode: string | null,
    ) {}
}

export class PresignupResult {
    constructor(
        public readonly stripeCustomerId: string,
        public readonly subscriptionId: string,
        public readonly price: {currency: string, amount: number}){}
}

export class SubscribeResult {
    constructor (
        public readonly accountCreated: boolean,
        public readonly reason: string,
        public readonly clientSecret: string | null) {
            if (accountCreated === false) {
                ok(clientSecret)
            }
    }
}

export class JoinWaitingListResult {
    constructor(
        public readonly message: string 
    ) {}
}

export interface ConfirmWaitingListResult {
    message: string
}

export interface TokenFromCodeResult {
    token: string
}

export class PublicService {
    private axios: AxiosInstance;

    constructor(public readonly mainServerUrl: string, public readonly recaptchaSiteKey_v3: string, public readonly recaptchaSiteKey_v2: string) {
        this.axios = axios.create({ baseURL: mainServerUrl, headers: { 'Content-Type': 'application/json' } });
    }

    async getAvailablePhoneNumbers(): Promise<string[]> {
        const phoneNumbers = await this.axios.get('public/phoneNumbers');
        if (phoneNumbers.status === 200) {
            return phoneNumbers.data.phoneNumbers;
        }
        throw new Error('Could not contact server');
    }

    async getAvailableProducts(): Promise<any[]> {
        const products = await this.axios.get('public/products');
        if (products.status === 200) {
            return (products.data.products as any[]).filter(p => p.metadata.displayInWebshop === "true");
        }
        throw new Error("An error occured retrieving data");
    }

    async getCountryCodes(enabled: boolean = true): Promise<any[]> {
        const products = await this.axios.get(`public/countryCodes/?enabled=${enabled}`);
        if (products.status === 200) {
            return products.data.countryCodes;
        }
        throw new Error("An error occured retrieving data");        
    }

    // async createUnpaidSubscription(signupData: SignupData): Promise<SubscribeResult> {
    //     const unpaidSubscription = await this.axios.post('public/pay_on_signup', signupData);
    //     if (unpaidSubscription.status === 200) {
    //         return new SubscribeResult(unpaidSubscription.data.clientSecret);
    //     }
    //     throw new Error("An error occured retrieving data");
    // }

    // async signupAndPay(signupData: SignupData): Promise<UnpaidSubscriptionData> {
    //     const unpaidSubscription = await this.axios.post('public/signupAndPay', signupData);
    //     if (unpaidSubscription.status === 200) {
    //         return new UnpaidSubscriptionData(unpaidSubscription.data.clientSecret);
    //     }
    //     throw new Error("An error occured retrieving data");
    // }

    // GET a reset
    async askResetPassword(email: string, recaptchaToken: string): Promise<AxiosResponse> {
        const res = await this.axios.get(`/user/account/passwordreset`, {
            params: {
                email,
                recaptchaToken
            }
        });
        return res;
    }

    // POST a reset
    async doResetPassword(opts: { email: string; password: string; token: string; recaptchaToken: string; }): Promise<AxiosResponse> {
        const res = await this.axios.post(`/user/account/passwordreset`,
            {
                email: opts.email,
                password: opts.password,
                token: opts.token,
                recaptchaToken: opts.recaptchaToken
            });
        return res;
    }

    async confirmEmail(vtoken: string): Promise<AxiosResponse> {
        const res = await this.axios.post(`/user/account/verifyemail`, { token: vtoken });
        return res;
    }

    async login(opts: { email: string; password: string; recaptchaToken: string; }): Promise<AxiosResponse> {
        const data = JSON.stringify({
            username: opts.email,
            password: opts.password,
            recaptchaToken: opts.recaptchaToken
        });
        const res = await this.axios.post(`/user/account/login`, data);
        return res;
    }

    async presubscribe(presignupBody: PresignupBody): Promise<PresignupResult> {
        const patchResult = await this.axios.patch('public/subscribe', presignupBody);
        if (patchResult.status === 200) {
            return new PresignupResult(patchResult.data.stripeCustomerId, patchResult.data.subscriptionId, patchResult.data.price);
        }
        throw new Error("An error occured retrieving data");
    }

    async subscribe(signupData: SignupData): Promise<SubscribeResult> {
        const patchResult = await this.axios.post('public/subscribe', signupData);
        if (patchResult.status === 200) {
            return new SubscribeResult(patchResult.data.accountCreated, patchResult.data.reason, patchResult.data.clientSecret)
            // return new SignupResult(patchResult.data.stripeCustomerId, patchResult.data.subscriptionId, patchResult.data.price);
        }
        throw new Error("An error occured retrieving data");
    }

    async joinWaitingList(email: string, recaptchaToken_v3: string): Promise<JoinWaitingListResult> {
        const postResult = await this.axios.post('waitingList/join', { email, recaptchaToken_v3});
        if (postResult.status === 200) {
            return new JoinWaitingListResult(postResult.data.message)
        }
        throw new Error('Could not perform action')
    }

    async confirmWaitingList(token: string): Promise<ConfirmWaitingListResult> {
        const postResult = await this.axios.post('waitingList/confirm', { token });
        if (postResult.status === 200) {
            return { message: postResult.data.message }
        }
        throw new Error('Could not perform action')        
    }

    async getToken(sharedCode: string): Promise<string> {
        const getResult = await this.axios.get(`public/tokenFromCode?code=${sharedCode}`);
        if (getResult.status === 200) {
            return getResult.data.token
        }
        throw new Error('Could not perform action')         
    }
}
