import bind from "bind-decorator";
import { observer } from "mobx-react";
import React from "react";
import { TheCall } from "../helpers/twilioCallStatus";
import Button from "@mui/material/Button"
import CallEnd from "@mui/icons-material/CallEnd"

export interface ICallComponentProps {
    call: TheCall
}

interface ICallComponentState {
    call: TheCall
}

@observer
export class CallComponent extends React.Component<ICallComponentProps, ICallComponentState> {

    render() {
        return (<React.Fragment>
            <div>
                <p>Call from {this.props.call.from} to {this.props.call.to}</p>
                <Button disabled={this.props.call.isEnded} 
                    onClick={this.props.call.hangup} 
                    color="primary" variant="contained" 
                    endIcon={<CallEnd />}>Hang up</Button>
                <Button disabled={this.props.call.isEnded} 
                    onClick={this.props.call.pickup} 
                    color="primary" variant="contained" 
                    endIcon={<CallEnd />}>Pick up</Button>                    
                <div>{this.props.call.state}</div>
                <div>{this.props.call.direction}</div>
            </div>
        </React.Fragment>)
    }
}