import { Typography } from '@mui/material';
import React from 'react';

export function CommonAppTitle(props: any) {

    return (<React.Fragment>
    <Typography variant="h2" component="h3" align="center" justifyContent={"center"} >
        <b>
            Cophone<sup className='betaSup' >Beta</sup> 
        </b>
    </Typography>
    </React.Fragment>)
  }