import React from 'react';
import './App.css';
// import { EmulatorConferenceComponent } from './components/emulatorConferenceComponent';
import { EmulatorConferenceComponent } from './components/emulatorConferenceComponent';
import { UserService } from './helpers/userService';

export interface AppProps {
    userService: UserService
}

export interface AppState {
}

export default class LoggedInEmuApp extends React.Component<AppProps> {

    // componentDidMount() {
    //     document.body.style.backgroundColor = "black"
    // }

    render() {
        return <EmulatorConferenceComponent userService={this.props.userService} />;
    }
}
