import bind from "bind-decorator";
import React from "react";
import { RemoteEndpoint } from "../lib/msClient/endpoints/remoteEndpoint";
import MuiPhoneNumber from 'material-ui-phone-number';
import { Logger } from "../helpers/logger";
import { Button, Dialog, List, ListItem, TextField } from "@mui/material";

import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js';
import Box from '@mui/material/Box';
import { SipRemoteEndpoint, Testing } from "../lib/msClient/endpoints/sipRemoteEndpoint";
import { CallComponent } from "./callComponent";
import { CallError } from "../helpers/errors/callError";
import { MediaTrack } from "../lib/msClient/endpoints/mediaTrack";
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from "mobx-react";
import { observable } from "mobx";
import { TheCall } from "../helpers/twilioCallStatus";
import {Listener} from "eventemitter2";
import { PstnComponent } from "./pstnComponent";


interface ISendSmsProps {
    sipRemoteEndpoint: SipRemoteEndpoint
}

@observer
export class SmsComponent extends React.Component<ISendSmsProps> {

    @observable
    private phoneNumber: PhoneNumber | undefined

    @observable
    private text: string | undefined

    renderSmses(phoneNumber2Sms: Map<string, Testing.Sms>): React.ReactNode {
        return (<React.Fragment key={`smses_${this.props.sipRemoteEndpoint.phoneNumber}`}>
            <List>
            {
                Array.from(phoneNumber2Sms.values()).map((sms) => {
                    return this.renderSms(sms);
                })
            }
            </List>
        </React.Fragment>)
    }
    
    renderSms(sms: Testing.Sms): any {
        return (<React.Fragment key={sms.id}>
            <ListItem alignItems="flex-start">
            {
                <div>
                    <p>From: {sms.from.formatInternational()}</p>
                    <p>To: {sms.to.formatInternational()}</p>
                    <p>{sms.text}</p>
                    <p>Status: {sms.Status}</p>
                </div>
            }
            </ListItem>
        </React.Fragment>)
    }

    render() {
        return (<React.Fragment key={`sendSmsFrom_${this.props.sipRemoteEndpoint.phoneNumber}`} >
            <MuiPhoneNumber defaultCountry={'us'} onChange={(v) => this.phoneNumber = parsePhoneNumber(v as string)}/>
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="Sms text"
                    multiline
                    rows={4}
                    variant="standard"
                    onChange={(v) => this.text = v.target.value}
                    />
            <div>
                <Button onClick={(v) => this.props.sipRemoteEndpoint.sendSms(this.phoneNumber!, this.text!) } >Send sms</Button>
            </div>
            <div>
                {this.renderSmses(this.props.sipRemoteEndpoint.smses)}
            </div>
            </div>
        </React.Fragment>)
    }    
}

export interface IRemoteEndpointProps {
    sipRemoteEndpoint: SipRemoteEndpoint
    unmuteAudio: boolean
}

interface IRemoteEndpointState {
    callButtonEnabled: boolean
    phoneNumber: PhoneNumber | undefined
    requestingMedia: boolean
}

@observer
export class SipEndpointComponent extends React.Component<IRemoteEndpointProps, IRemoteEndpointState> {
    
    _canvas = React.createRef<HTMLCanvasElement>();
    _audio: HTMLAudioElement;
    _mediaStream: MediaStream | undefined;
    
    _idx: any
    _stopped: boolean = false
    private _logger: Logger;
    private listeners = new Array<Listener | SipRemoteEndpoint>();

    constructor(public readonly props: IRemoteEndpointProps){
        super(props)
        this.state = { callButtonEnabled: false, phoneNumber: undefined, requestingMedia: false };
        
        this._logger = new Logger(`SipEndpointComponent_${props.sipRemoteEndpoint.id}`)
        this._audio = document.createElement("audio")
        this._audio.autoplay = false
        this._audio.muted = true
    }
    
    async componentDidMount() {
        this.props.sipRemoteEndpoint.on(RemoteEndpoint.TRACKS, this.onTracks)
        this.props.sipRemoteEndpoint.on([SipRemoteEndpoint.EVENT_CALL, '**'], this.onCallEvent)
        this.props.sipRemoteEndpoint.on([SipRemoteEndpoint.EVENT_SMS, '**'], this.onSms)
        window.addEventListener('beforeunload', this.cleanup);
    }

    @bind
    private onSms(data: any) {
        this._logger.debug('onSms', data)

    }
    
    @bind
    private onCallEvent(call: any) {
        try {
            this.unmute()
        } catch (e) {
            console.error(e)
        }
        this.forceUpdate()
    }
    
    componentWillUnmount() {

        this.cleanup()
    }
    
    @bind
    private cleanup() {
        this._stopped = true
        window.removeEventListener('beforeunload', this.cleanup)
    }
  
    @bind
    private onTracks(tracks: MediaStreamTrack[]) {
        if (tracks.length > 0 && tracks.every(t => t.kind === 'audio')) {
            this._mediaStream = new MediaStream(tracks)
            this._audio.srcObject = this._mediaStream
        } else {
            this._audio.srcObject = null
        }
    }
    
    @bind 
    private onData(...msg: any[]){
        this._logger.debug('onData()', msg)
    }
    
    @bind unmute() {
        if (this._audio) {
            this._audio.play().catch(console.error)
            if (this._audio.muted) {
                this._logger.debug('unmutting')
                this._audio.muted = false
            }
        }
    }
    
    
    @bind async onCallButtonClick(v: any) {

        if (this.state.phoneNumber) {
            // before placing the call, we need to make sure that we have mic access
            // get an audio track and immediately release it
            this.setState(prevState => {
                return {
                    ...prevState,
                    requestingMedia: true
                }
            })

            let sendTrack;
            
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                sendTrack = new MediaTrack(stream.getAudioTracks()[0], 'pstn audio');
            } catch {
                this.setState(prevState => {
                    return {
                        ...prevState,
                    }
                })
                return new CallError('Could not getUserMedia')
            } finally {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        requestingMedia: false,
                    }
                })
            }

            if (sendTrack) {
                sendTrack.track.stop()
                sendTrack = undefined
                this.props.sipRemoteEndpoint.dial(this.state.phoneNumber)
            } else {
                this._logger.error('Could not get audio track to send.')
            }
        } else {
            this._logger.error('Cannot call invalid number {}', this.state.phoneNumber)
        }
    }
    
    @bind
    protected onCallDisconnected(data: any) {
        this.setState(prevState => {
            return {
                ...prevState,
            }
        })
    }
        
    renderAllowMedia = () => {
        return(<React.Fragment key="requestMediaOverlay">
            <Dialog open={true}>
                <DialogTitle>Requesting microphone access</DialogTitle>
                <div>
                    You need to allow microphone access in order to place telephone calls
                </div>
            </Dialog>
            </React.Fragment>)
    }
    
    render() {
        return (<React.Fragment>
            <p>Sipbridge endpoint</p>
            <PstnComponent sipRemoteEndpoint={this.props.sipRemoteEndpoint}/>
            <SmsComponent sipRemoteEndpoint={this.props.sipRemoteEndpoint} />
            </React.Fragment>)
        }
    }