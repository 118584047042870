import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, Tooltip, Typography } from "@mui/material"
import Paper, { PaperProps } from "@mui/material/Paper"
import { Phone, Favorite, Feedback, PersonPin, Payment, Delete, ReceiptTwoTone, ShareRounded, Settings, VideoSettings } from "@mui/icons-material"
import { bind } from "bind-decorator"
import React from "react"
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { CreditCardComponent } from "./creditCardComponent"
import { CreditCard } from "./creditCard"
import { UserDetailsComponent } from "./userDetails"
import { DeleteAccount } from "./deleteAccount"
import { PaymentManagement } from "./PaymentManagement"
import { UserService } from "../helpers/userService"
import CloseIcon from '@mui/icons-material/Close';
import { BootstrapDialog, BootstrapDialogTitle } from "./paymentPopupComponent"
import { CustomerFeedback } from "./customerFeedbackComponent"
import { GetPhoneNumber } from "./getPhoneNumberComponent"
import { ShareView } from "./shareViewComponent"
import { MediaSettings } from "./mediaSettings"
import { localEndpointState } from "../lib/state/localEndpointState"
import { runInAction } from "mobx"
import { observer } from "mobx-react"
import Draggable from "react-draggable"

function DraggablePaperComponent(props: PaperProps) {
    return (
      <Draggable
        handle="#settings-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

// export const UserSettingTab = {
//     Person: 0,
//     PricingTable: 1,
//     Share: 2,
//     Feedback: 3,
//     MediaSettings: 4,
//     DeleteAccount: 5,
// }

// order of items is RELEVANT: tabs will be displayed in this order
export enum UserSettingTab {
    Person,
    PricingTable,
    Share,
    Feedback,
    MediaSettings,
    DeleteAccount
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

export interface IUserSettingsProps {
    show: boolean
    selectedTab?: number
    // userData: IUserData
    onClose: () => void
    onDeleteAccount: () => boolean
    userService: UserService
}

export interface IUserSettingsState {
    open: boolean
    clientSecret: string
    // userData: IUserData
}

@observer
export class UserSettings extends React.Component<IUserSettingsProps, IUserSettingsState> {
    
    constructor(props: IUserSettingsProps) {
        super(props)
        this.state = {
            open: props.show,
            clientSecret: '',
            // userData: props.userData
        }
    }

    public show(selectedTab?: number) {
        this.setState({
            open: true
        })
    }

    public hide() {
        this.setState({
            open: false
        })
    }

    public setPaymentClientSecret(secret: string) {
        this.setState({
            clientSecret: secret
        })
    }

    @bind
    private handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        runInAction(() => localEndpointState.userSettings.selectedTab = newValue)
    }

    @bind
    private handleClose() {
        this.setState({open: false})
        this.props.onClose()
    }

    render() {
        return (
            <Dialog open={this.state.open} onClose={this.handleClose} 
            aria-labelledby="settings-dialog-title"
            PaperComponent={DraggablePaperComponent}
            hideBackdrop // Disable the backdrop color/image
            disableEnforceFocus // Let the user focus on elements outside the dialog
            // style={{ position: 'initial'}}
            >
                <DialogTitle style={{ cursor: 'move' }}>
                    <IconButton
                        aria-label="close"
                        onClick={this.handleClose}
                        sx={{

                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    Settings
                </DialogTitle>
                <DialogContent dividers>
                    <Tabs
                        value={localEndpointState.userSettings.selectedTab}
                        onChange={this.handleChange}
                        variant="scrollable"
                        allowScrollButtonsMobile
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="icon tabs example"
                    >
                        <Tab icon={<PersonPin />} aria-label="person" />
                        <Tab disabled={this.props.userService.isMainUser === false} icon={<Payment />} aria-label="stripe-pricing-table" />
                        {/* <Tab icon={<ReceiptTwoTone/>} aria-label="invoicing" /> */}
                        <Tab icon={<ShareRounded />} aria-label="share" />
                        <Tab icon={<Feedback />} aria-label="feedback" />
                        <Tab icon={<VideoSettings/>} aria-label="mediasettings"/>
                        <Tab disabled={this.props.userService.isMainUser === false} icon={<Delete />} aria-label="delete" />
                        {/* <Tab icon={<Phone />} aria-label="phone" /> */}
                    </Tabs>
                    <TabPanel value={localEndpointState.userSettings.selectedTab} index={UserSettingTab.Person}>
                        <UserDetailsComponent userService={this.props.userService} />
                    </TabPanel>
                    <TabPanel value={localEndpointState.userSettings.selectedTab} index={UserSettingTab.PricingTable}>
                        <PaymentManagement userService={this.props.userService} ></PaymentManagement>
                    </TabPanel>
                    <TabPanel value={localEndpointState.userSettings.selectedTab} index={UserSettingTab.Share}>
                        <ShareView userService={this.props.userService} />
                    </TabPanel>                                   
                    <TabPanel value={localEndpointState.userSettings.selectedTab} index={UserSettingTab.Feedback}>
                        <CustomerFeedback userService={this.props.userService} ></CustomerFeedback>
                    </TabPanel>
                    <TabPanel value={localEndpointState.userSettings.selectedTab} index={UserSettingTab.MediaSettings}>
                        <MediaSettings localEndpointState={localEndpointState} ></MediaSettings>
                    </TabPanel>
                    <TabPanel value={localEndpointState.userSettings.selectedTab} index={UserSettingTab.DeleteAccount}>
                        <DeleteAccount agreeToDeletion={false} userService={this.props.userService}></DeleteAccount>
                    </TabPanel>
                    {/* <TabPanel value={localEndpointState.userSettings.selectedTab} index={UserSettingTab.GetPhoneNumber}>
                        <Subscribe.ChooseNumber userService={this.props.userService}></Subscribe.ChooseNumber>
                    </TabPanel> */}
                </DialogContent>                    
                                
            </Dialog>
           
        );
    }
}