import bind from "bind-decorator";
import { EventEmitter2 } from "eventemitter2";
import { MqClient } from "./mqClient";
import Stomp from 'stompjs';
import { EnhancedEventEmitter2 } from "../msClient/base/iMsSignaling";
import { Logger } from "../../helpers/logger";

const logger = new Logger('Signaler')

function formatId(user_id: string, conn_id: string): string {
    return `${user_id}.${conn_id}`
}

export class Signaler extends EnhancedEventEmitter2 {
    
    public readonly deliverOwnMessages: boolean;
    subid: Stomp.Subscription | undefined;

    // overrides the inherited ones
    public static readonly EVENT_CONNECTED: string[] = ['Signaler', 'connected']
    public static readonly EVENT_DISCONNECTED: string[] = ['Signaler', 'disconnected']

    protected _sfuConnected: boolean = false;
    private _apiver = new Map<string, string>();

    public get connected(): boolean {
        return this._sfuConnected
    }

    constructor(public readonly mqClient: MqClient, 
        public readonly queueName: string,
        public readonly recvDestination: string,
        public readonly sendRoutingKey: string) {

        super({wildcard: true})
        this.mqClient.onDisconnected(this.onMqClientDisconnected)
        this.mqClient.onConnectedAsync(this.onMqClientConnected)
        this.deliverOwnMessages = localStorage.getItem('MQCLIENT.DELIVEROWNGROUPMSG') === 'true'
    }
    
    @bind
    protected onMqClientDisconnected() {
        this._sfuConnected = false
        this.emit(Signaler.EVENT_DISCONNECTED)
    }
    
    @bind
    protected onMqClientConnected() {
        this._sfuConnected = true
        this.subid = this.mqClient.subscribe(this.recvDestination, this.onMessage, { "x-queue-name": this.queueName });
        this.emit(Signaler.EVENT_CONNECTED)
    }

    @bind
    protected onMessage(msg: Stomp.Message) {
        logger.debug(`Signaler.onMessage()`, msg)
        const s = msg.body.toString()
        const j = JSON.parse(s)
        const sender = (msg.headers as any).destination.split('/')[3].split('.')
        const s_user_id = sender[1]
        const s_conn_id = sender[2]
        const senderId = formatId(s_user_id, s_conn_id)
        if (senderId === this.mqClient.id) {
            if (this.deliverOwnMessages) {
                logger.warn('DELIVEROWNGROUPMSG is set to true, we are delivering own message', msg)
            } else {
                return
            }
        }
        if (j.apiver) {
            const sender_apiverid = this._apiver.get(senderId)
            if (sender_apiverid) {
                if (sender_apiverid != j.apiver) {
                    logger.warn(`New apiverid detected prev: ${sender_apiverid} curr: ${j.apiver}`)
                    // logger.warn('New version detected, force reloading the page now!')
                    // window.location.reload()
                }
            } else {
                this._apiver.set(senderId, j.apiver)
                logger.debug(`New apiver for ${senderId}`, this._apiver.get(senderId))
            }
        }
        this.emit(j.event, j.content)
    }

    public send(event: string, content?: any): boolean {
        content = content ?? {}
        const payload = JSON.stringify({event, content})
        return this.mqClient.send(this.sendRoutingKey, payload)
    }
}