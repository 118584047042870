import React from "react"

export class PrivacyComponent extends React.Component {
    render() {
        return (<React.Fragment>
            <h1>Privacy Policy</h1>
<p>Thank you for using our communication platform! This Privacy Policy describes how we collect, use, and share information about you when you use our service.</p>
<h2>Information We Collect</h2>
<p>When you use our platform, we may collect the following information about you:</p>
<ul>
	<li>Your name, email address, phone number, and other contact information you provide to us;</li>
	<li>Your communication activity such as phone calls, text messages, and call logs;</li>
	<li>Your device information such as your IP address, operating system, and browser type;</li>
	<li>Your payment information when you make a purchase on our platform;</li>
	<li>Other information you choose to provide to us.</li>
</ul>
<h2>How We Use Your Information</h2>
<p>We use your information to provide, maintain, and improve our communication platform. This includes:</p>
<ul>
	<li>Allowing you to make and receive phone calls and text messages;</li>
	<li>Providing customer support and responding to your inquiries;</li>
	<li>Processing payments;</li>
	<li>Preventing and detecting fraudulent and unauthorized use of our platform;</li>
	<li>Analyzing usage trends and improving our platform;</li>
	<li>Complying with legal obligations.</li>
</ul>
<h2>How We Share Your Information</h2>
<p>We may share your information with the following third parties:</p>
<ul>
	<li>Service providers who help us operate our platform, such as payment processors and cloud storage providers;</li>
	<li>Law enforcement or government agencies when we are required to do so by law;</li>
	<li>Other companies in the event of a merger, acquisition, or sale of assets.</li>
</ul>
<h2>Security</h2>
<p>We take appropriate measures to protect your information from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
<h2>Children's Privacy</h2>
<p>Our platform is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately.</p>
<h2>Changes to This Policy</h2>
<p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new policy on our website. Your continued use of our platform after we make changes is deemed to be acceptance of those changes.</p>
<h2>Contact Us</h2>
<p>If you have any questions about this Privacy Policy, please contact us at hello [at] cophone.io</p>
        </React.Fragment>)
    }
}

export class TermsComponent extends React.Component {
    render() {
        return (<React.Fragment>
            <body>
                <h1>Terms and Conditions</h1>
                <p>Welcome to our online communication platform (the "Platform"). By using our Platform, you agree to be bound by these terms and conditions, which govern your use of the Platform.</p>
            
                <h2>Use of the Platform</h2>
                <p>Our Platform allows you to use an online smartphone with which you can place and receive phone calls, as well as send and receive short messages. By using the Platform, you agree to use it only for lawful purposes and in accordance with these terms and conditions. You agree not to use the Platform to place automated calls or send automated messages and/or emails, or to engage in any other activity that could be considered spam.</p>
            
                <h2>Privacy Policy</h2>
                <p>Our Privacy Policy explains how we collect, use, and disclose your personal information when you use our Platform. By using our Platform, you consent to our use of your personal information in accordance with our Privacy Policy.</p>
            
                <h2>User Content</h2>
                <p>You are solely responsible for any content you submit or upload to the Platform, including but not limited to messages and calls. You agree that we may monitor and remove any content that violates these terms and conditions or is otherwise objectionable.</p>
            
                <h2>Intellectual Property</h2>
                <p>All content on the Platform, including but not limited to text, graphics, logos, and software, is owned by us or our licensors and is protected by copyright and other intellectual property laws.</p>
            
                <h2>License</h2>
                <p>We grant you a limited, non-exclusive, non-transferable license to use the Platform in accordance with these terms and conditions.</p>
            
                <h2>Disclaimer of Warranties</h2>
                <p>We make no warranties or representations about the accuracy, completeness, or suitability of the Platform. We disclaim all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            
                <h2>Limitation of Liability</h2>
                <p>To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Platform.</p>
            
                <h2>Indemnification</h2>
                <p>You agree to indemnify, defend, and hold us harmless from and against any and all claims, damages, costs, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Platform.</p>
            
                <h2>Governing Law and Jurisdiction</h2>
                <p>These terms and conditions shall be governed by and construed in accordance with the laws of [insert jurisdiction]. Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of [insert jurisdiction].</p>
            
                <h2>Information Sharing</h2>
                <p>We may share your personal information with other companies in order to provide the services offered on the Platform. By using the Platform, you consent to our sharing of your personal information for this purpose.</p>
            
                <h2>Modifications</h2>
                <p>We reserve the right to modify these terms and conditions at any time, in our sole discretion. Any such modifications will be effective immediately upon posting on the Platform.</p>
            
                <h2>Prohibited Activities</h2>
                <p>Prohibited Activities: You are expressly prohibited from engaging in any of the following activities in connection with your use of the Platform: (a) placing automated calls or sending automated emails; (b) engaging in any activity that could be considered spam; (c) using the Platform in any manner that violates any applicable laws or regulations; (d) engaging in any activity that could interfere with or disrupt the operation of the Platform; or (e) attempting to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform. </p>
            
                <h2>Short codes disclaimer</h2>
                <p>The platform does not guarantee delivery of short text messages sent from short codes.</p>
                <p>You will NOT be able to send outbound messages to short codes. Therefore, you will not be able to reply, opt out via "STOP," or send "HELP" messages. </p>
                
                <p>By using our Platform, you acknowledge that you have read and understood these terms and conditions and agree to be bound by them.</p>
            
            </body>
            </React.Fragment>)
    }
}