import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@mui/material";
import bind from "bind-decorator";
import React from "react";
import { UserService } from "../helpers/userService";

export interface DeleteAccountState {
    agreeToDeletion: boolean
}

export interface DeleteAccountProps {
    agreeToDeletion: boolean
    userService: UserService
}

export class DeleteAccount extends React.Component<DeleteAccountProps, DeleteAccountState> {
    
    constructor(props: DeleteAccountProps) {
        super(props)
        this.state = {
            agreeToDeletion: props.agreeToDeletion
        }
    }
    
    @bind
    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            agreeToDeletion: event.target.checked
        })
    }

    @bind
    private async handleDelete(event: any) {
        await this.props.userService.deleteAccount()
    }

    render() {
        return (
        <React.Fragment>
            <Box sx={{ m: 2 }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Delete account</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.agreeToDeletion} onChange={this.handleChange} name="bulan" />}
                                label="I want to completely delete my account"
                            />
                            <Button variant="contained" color="secondary" disabled={!this.state.agreeToDeletion} onClick={this.handleDelete}>Delete my account</Button>
                        </FormGroup>
                        <FormHelperText>This operation cannot be undone. You might be invoiced one more time.</FormHelperText>
                    </FormControl>
                {/* <Box m={1} //margin 
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                // sx={boxDefault}
                >
                    <Button variant="contained" color="secondary" disabled={!this.state.agreeToDeletion} onClick={this.handleDelete}>Delete my account</Button>
                </Box> */}
            </Box>
        </React.Fragment>
        )
    }
}