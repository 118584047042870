import bind from "bind-decorator";
import { EnhancedEventEmitter2 } from "../msClient/base/iMsSignaling";
import { MqClient } from "./mqClient";
import Stomp from 'stompjs';
import { Logger } from "../../helpers/logger";


export class PresenceService extends EnhancedEventEmitter2 {
    private _connected: boolean = false;
    logger: Logger;

    public get connected(): boolean {
        return this._connected;
    }

    public static readonly EVENT_ANY = '*';
    public static readonly EVENT_ALL = '**';
    public static readonly EVENT_ENDPOINT_CONNECTED = 'bind';
    public static readonly ANY_ENDPOINT_CONNECTED = [PresenceService.EVENT_ENDPOINT_CONNECTED, PresenceService.EVENT_ALL];
    public static readonly EVENT_ENDPOINT_DISCONNECTED = 'unbind';
    public static readonly ANY_ENDPOINT_DISCONNECTED = [PresenceService.EVENT_ENDPOINT_DISCONNECTED, PresenceService.EVENT_ALL];

    // overrides the inherited ones
    public readonly EVENT_CONNECTED: string[] = ['PresenceService', 'connected']
    public readonly EVENT_DISCONNECTED: string[] = ['PresenceService', 'disconnected']

    public readonly EVENT_WE_CONNECTED = 'weHaveConnected';
    public static readonly presenceExchangePrefix = 'presence';

    public static readonly EVENT_ELEMENT_NEW = "new";
    public static readonly EVENT_ELEMENT_CLOSE = "close";
    public static readonly EVENT_ELEMENT_RESUME = "resume";
    public static readonly EVENT_ELEMENT_PAUSE = "pause";

    public readonly deliverOwnMessages: boolean;
    private recvSubscriptionId: Stomp.Subscription | undefined;
    private announceSubscriptionId: Stomp.Subscription | undefined;

    public readonly presenceExchange = `${PresenceService.presenceExchangePrefix}${this.resourceId}`;

    // we'll receive all messages through this queue
    public readonly queueName = `q.${this.id}.presence`;
    // subscribe to this topic to receive presence notifications
    public readonly recvDestination = `/exchange/${this.presenceExchange}`;
    // subscribe to this topic to send presence notifications
    public readonly announceDestination = `/exchange/${this.presenceExchange}/${this.id}`;

    public get id(): string {
        return this.mqClient.id;
    }

    constructor(public readonly mqClient: MqClient, public readonly resourceId: string) {
        super({ wildcard: true });
        this.logger = new Logger('PresenceService');
        this.deliverOwnMessages = localStorage.getItem('MQCLIENT.DELIVEROWNGROUPMSG') === 'true';
        this.mqClient.onDisconnected(this.onMqClientDisconnected);
        this.mqClient.onConnectedAsync(this.onMqClientConnected);
    }

    @bind
    private onMqClientDisconnected() {
        this._connected = false;
        this.emit(this.EVENT_DISCONNECTED);
    }

    @bind
    protected onMqClientConnected() {
        // first make sure we receive presence updates
        this.recvSubscriptionId = this.mqClient.subscribe(this.recvDestination, this.onPresenceEvent, { "x-queue-name": this.queueName });
        // then announce our presence
        this.announceSubscriptionId = this.mqClient.subscribe(this.announceDestination, this.onPresenceEvent, { "x-queue-name": this.queueName });
    }

    @bind
    onPresenceEvent(msg: Stomp.Message) {

        this.logger.debug('Event', msg.headers, msg.body);

        // action    str   Either "bind" or "unbind"
        // exchange  str   The name of the exchange being bound/unbound
        // queue     str   The name of the queue being bound/unbound
        // key	     str   The binding key supplied at the time of binding
        if (msg) {
            const newId: string = (msg.headers as any).key;
            var action: string = (msg.headers as any).action;

            if (action == PresenceService.EVENT_ENDPOINT_CONNECTED && this.id === newId) {
                // we want to emit 2 events: one related to the PresenceService
                // and one to the endpoint itself
                action = this.EVENT_WE_CONNECTED;
                this._connected = true;
                this.emit(this.EVENT_CONNECTED);
            }
            const data = {
                exchange: (msg.headers as any).exchange,
                queue: (msg.headers as any).queue,
                sid: newId,
                aid: newId.split('.'),
                payload: msg.body.toString()
            };
            this.emit([action, ...data.aid], data);
        }
    }
}
