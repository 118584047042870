import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js';
import Box from '@mui/material/Box';
import { SipRemoteEndpoint, Testing } from "../lib/msClient/endpoints/sipRemoteEndpoint";
import { CallComponent } from "./callComponent";
import { CallError } from "../helpers/errors/callError";
import { MediaTrack } from "../lib/msClient/endpoints/mediaTrack";
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from "mobx-react";
import { observable } from "mobx";
import { TheCall } from "../helpers/twilioCallStatus";
import {Listener} from "eventemitter2";
import React from 'react';
import { Logger } from '../helpers/logger';
import bind from 'bind-decorator';
import { RemoteEndpoint } from '../lib/msClient/endpoints/remoteEndpoint';
import Dialog from '@mui/material/Dialog/Dialog';

export interface IRemoteEndpointProps {
    sipRemoteEndpoint: SipRemoteEndpoint
}

interface IRemoteEndpointState {
    callButtonEnabled: boolean
    phoneNumber: PhoneNumber | undefined
    requestingMedia: boolean
}

@observer
export class SipForEmulatorRemoteEndpointComponent extends React.Component<IRemoteEndpointProps, IRemoteEndpointState> {
    
    _canvas = React.createRef<HTMLCanvasElement>();
    _audio: HTMLAudioElement;
    _mediaStream: MediaStream | undefined;
    
    _idx: any
    _stopped: boolean = false
    private _logger: Logger;
    private listeners = new Array<Listener | SipRemoteEndpoint>();

    constructor(public readonly props: IRemoteEndpointProps){
        super(props)
        this.state = { callButtonEnabled: false, phoneNumber: undefined, requestingMedia: false };
        
        this._logger = new Logger(`SipForEmulatorRemoteEndpointComponent_${props.sipRemoteEndpoint.id}`)
        this._audio = document.createElement("audio")
        this._audio.autoplay = false
        this._audio.muted = true
    }
    
    async componentDidMount() {
        this.props.sipRemoteEndpoint.on(RemoteEndpoint.TRACKS, this.onTracks)
        this.props.sipRemoteEndpoint.on([SipRemoteEndpoint.EVENT_CALL, '**'], this.onCallEvent)
        this.props.sipRemoteEndpoint.on([SipRemoteEndpoint.EVENT_SMS, '**'], this.onSms)
        window.addEventListener('beforeunload', this.cleanup);
    }

    @bind
    private onSms(data: any) {
        this._logger.debug('onSms', data)

    }
    
    @bind
    private onCallEvent(call: any) {
        try {
            this.unmute()
        } catch (e) {
            console.error(e)
        }
    }
    
    componentWillUnmount() {
        this.cleanup()
    }
    
    @bind
    private cleanup() {
        this._stopped = true
        window.removeEventListener('beforeunload', this.cleanup)
        // this.props.sipRemoteEndpoint.offAny(this.onTracks)
        // this.props.sipRemoteEndpoint.offAny(this.onCallEvent)
        // this.props.sipRemoteEndpoint.offAny(this.onSms)
    }
  
    @bind
    private onTracks(tracks: MediaStreamTrack[]) {
        if (tracks.length > 0 && tracks.every(t => t.kind === 'audio')) {
            this._mediaStream = new MediaStream(tracks)
            this._audio.srcObject = this._mediaStream
        } else {
            this._audio.srcObject = null
        }
    }
    
    @bind 
    private onData(...msg: any[]){
        this._logger.debug('onData()', msg)
    }
    
    @bind unmute() {
        if (this._audio) {
            this._audio.play().catch(console.error)
            if (this._audio.muted) {
                this._logger.debug('unmutting')
                this._audio.muted = false
            }
        }
    }
    
    @bind
    protected onCallDisconnected(data: any) {
        this.setState(prevState => {
            return {
                ...prevState,
            }
        })
    }
        
    renderAllowMedia = () => {
        return(<React.Fragment key="requestMediaOverlay">
            <Dialog open={true}>
                <DialogTitle>Requesting microphone access</DialogTitle>
                <div>
                    You need to allow microphone access in order to place telephone calls
                </div>
            </Dialog>
            </React.Fragment>)
    }
    
    render() {
            return null
        }
        // return (false && <React.Fragment>
        //     <p>Sipbridge endpoint</p>
        //     </React.Fragment>)
        // }
}