import { Alert, AlertColor, Backdrop, CircularProgress, LinearProgress, Stack, Typography } from "@mui/material";
import bind from "bind-decorator";
import React from "react";
import { ConferenceState } from "../lib/msClient/endpoints/conference";
import CheckIcon from '@mui/icons-material/Check';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { computed, makeObservable, observable } from "mobx";
import { ProcessState } from "../lib/state/emuHardwareState";
import { observer } from "mobx-react";

export interface IConferenceStateProps {
    conferenceState: ConferenceState
    openSettingsLink: React.ReactNode
}

@observer
export class ConferenceStateComponent extends React.Component<IConferenceStateProps> {
    constructor(public readonly props: IConferenceStateProps) {
        super(props)
        makeObservable(this)
    }

    @computed
    private get getEmubridgeSeverity(): AlertColor {
        const processState = this.props.conferenceState.emuBridgeRemoteEndpoint?.remoteEndpointState.hardwareState.process.state
        switch (processState) {
            case ProcessState.RUNNING: return "success"
            case ProcessState.STARTING: return "info"
            case ProcessState.FAULTED: return "error"
            default: return "warning"
        }
    }

    @computed
    private get getEmubridgeIcon(): any {

        const processState = this.props.conferenceState.emuBridgeRemoteEndpoint?.remoteEndpointState.hardwareState.process.state
        switch (processState) {
            case undefined: return <CircularProgress size="1rem" color="primary" />
            case ProcessState.STOPPED: return <HourglassEmptyIcon fontSize="inherit" />
            case ProcessState.RUNNING: return <CheckIcon fontSize="inherit" />
            case ProcessState.STARTING: return <HourglassTopIcon fontSize="inherit"/>
            case ProcessState.FAULTED: return <ErrorIcon fontSize="inherit"/>
            default: return <WarningIcon fontSize="inherit"/>
        }
    }    

    private getBoolSeverity(x: boolean): AlertColor {
        if (x) {
            return "success"
        } else {
            return "warning"
        }          
    }

    private getIcon(x: boolean): any {
        if (x) {
            return <CheckIcon fontSize="inherit" />
        } else {
            return <CircularProgress size="1rem" color="primary" />
        }        
    }

    public get live(): boolean {
        if (this.props.conferenceState.mqClientConnected 
            && this.props.conferenceState.sfuConnected
            && this.props.conferenceState.localEndpoint.connected
            && this.props.conferenceState.emuBridgeRemoteEndpoint?.remoteEndpointState.hardwareState.process.state === ProcessState.RUNNING) {
                return true
            }
        return false;
    }

    // @bind
    render(): React.ReactNode {

        if (this.live) {
            return null
        }

        return (<>
            <Backdrop sx={{ cursor: "grab", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={ true }>
                <Stack sx={{ minWidth: '25%' }} spacing={0.5} >
                    <Alert icon={false} severity="info" sx={{ display: 'block', textAlign: "center"}} >
                    Your cophone is starting
                    </Alert>
                    <Alert icon={this.getIcon(this.props.conferenceState.mqClientConnected)} severity={this.getBoolSeverity(this.props.conferenceState.mqClientConnected)}>
                    Network
                    </Alert>
                    <Alert icon={this.getIcon(this.props.conferenceState.sfuConnected)} severity={this.getBoolSeverity(this.props.conferenceState.sfuConnected)}>
                    System 
                    </Alert>
                    <Alert icon={this.getIcon(this.props.conferenceState.localEndpoint.connected)} severity={this.getBoolSeverity(this.props.conferenceState.localEndpoint.connected)}>
                    Local endpoint 
                    </Alert>
                    <Alert icon={this.getEmubridgeIcon} severity={this.getEmubridgeSeverity}>
                    Remote endpoint
                    </Alert>
                    <Alert icon={false} severity="info" sx={{ display: 'block', textAlign: "center"}} >
                    { this.props.openSettingsLink }
                    </Alert>
                </Stack>
            </Backdrop>
        </>)
    }
}