import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { bind } from "bind-decorator";
import React from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "./paymentPopupComponent";


export interface ILoggedOutSuccessProps {
    show: boolean;
}

export interface ILoggedOutSuccessState {
    show: boolean;
    accountDeleted: boolean;
}

export class LoggedOutSuccess extends React.Component<ILoggedOutSuccessProps, ILoggedOutSuccessState> {

    constructor(props: ILoggedOutSuccessProps) {
        super(props);
        const accountDeleted = localStorage.getItem('accountDeleted')
        localStorage.removeItem('accountDeleted')
        this.state = {
            show: props.show,
            accountDeleted: accountDeleted !== null && accountDeleted === '1'
        };
    }

    private get dialogTitle() {
        if (this.state.accountDeleted) {
            return 'Account deleted.'
        } else {
            return 'See you next time!'
        }
    }

    private get dialogContent() {
        if (this.state.accountDeleted) {
            return 'We hope to see you back someday!'
        } else {
            return 'You have been logged out.'
        }
    }

    @bind
    private handleClose() {
        this.setState({ show: false });
    }

    @bind
    private loginAgain() {
        document.location.href = "login"
    }

    @bind
    private randomXkcd() {
        document.location.href = "https://c.xkcd.com/random/comic/";
    }

    private renderLoginButton() {
        return <Button onClick={this.loginAgain}>Login again</Button>
    }

    private renderXkcdButton() {
        return <Button onClick={this.randomXkcd}>Random xkcd</Button>
    }

    render() {
        return (
            <Dialog open={this.state.show} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    {this.dialogTitle}
                </DialogTitle>
                <DialogContent dividers>
                    <p>{this.dialogContent}</p>
                    {this.state.accountDeleted && this.renderXkcdButton() }
                    {!this.state.accountDeleted && this.renderLoginButton() }
                </DialogContent>

            </Dialog>

        );
    }
}
