import { EventEmitter2, Listener, ListenerFn, OnOptions } from "eventemitter2";
import { PstnEvent } from "../../rabbitmq/pstnEvent";
import { Sms } from "../../rabbitmq/sms";


export abstract class EnhancedEventEmitter2 extends EventEmitter2 {

    public readonly EVENT_CONNECTED: string[] = ['EnhancedEventEmitter2', 'connected']
    public readonly EVENT_DISCONNECTED: string[] = ['EnhancedEventEmitter2', 'disconnected']

    public abstract get connected(): boolean

    /**
     * If connected, listener will be called immediately, in addition to being added to the 'on' event
     * @param listener 
     * @param options 
     * @returns
     */
    public onConnectedAsync(listener: ListenerFn, options?: OnOptions): this|Listener {

        if (this.connected) {
            setTimeout(listener, 45)
        }
        if (options) {
            options.async = true
        }
        return this.on(this.EVENT_CONNECTED, listener, options)
    }

    public onDisconnected(listener: ListenerFn, options?: OnOptions): this|Listener {
        return this.on(this.EVENT_DISCONNECTED, listener, options)
    }
}

export interface IMsSignaling extends EnhancedEventEmitter2 {
    send(event: string, content?: any): void;
}

export interface IGroupSignaling extends EventEmitter2 {
    send(event: string, content?: any): void;
}

export interface IUserServerSignaling extends EventEmitter2 {
    send(event: string, content?: any): void;
}
export interface ISmsSignaling {
    onSms(cb: (sms: Sms) => void): void;
}

export interface IPstnSignaling {
    onPstn(cb: (pstn: PstnEvent) => void): void
    sendPstn(event: PstnEvent): void
}