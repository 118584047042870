import bind from "bind-decorator";
import { EventEmitter2 } from "eventemitter2";
import { PhoneNumber } from "libphonenumber-js";
import { IGroupSignaling } from "../msClient/base/iMsSignaling";
import { v4 as uuidv4 } from 'uuid';

export interface ISmsSignaling {
    sendSms(fromPhoneNumber: PhoneNumber, toPhoneNumber: PhoneNumber, text: string): void
}

export const EVENT_SMS = 'sms'
export const EVENT_SMSSTATUS = 'smsStatus'
export const SMS_DIRECTION_INBOUND = 'sms_inbound'
export const SMS_DIRECTION_OUTBOUND = 'sms_outbound'

export class SmsSignaling2 extends EventEmitter2 implements ISmsSignaling {
    
    constructor (protected readonly smsSignaling: IGroupSignaling,
        protected readonly phoneNumber: PhoneNumber) {
        super({wildcard: true})

        this.smsSignaling.on(['*', '*', EVENT_SMS], this.onSms)
        this.smsSignaling.on(['*', '*', EVENT_SMSSTATUS], this.onSmsStatus)
        // we want to be notified of others sending smses from the same number
    }
    
    @bind
    protected onSms(data: any) {
        console.log('onSms', data)
        if ((data.from === this.phoneNumber.formatInternational() && data.direction === SMS_DIRECTION_OUTBOUND)
            || (data.to === this.phoneNumber.formatInternational() && data.direction === SMS_DIRECTION_INBOUND)) {
            this.emit(EVENT_SMS, data)
        }
    }
    
    @bind
    protected onSmsStatus(senderId: string, data: any) {
        console.log('onSmsStatus', senderId, data)
        // if ((data.from === this.phoneNumber.formatInternational() && data.direction === SMS_DIRECTION_OUTBOUND)
        //     || (data.to === this.phoneNumber.formatInternational() && data.direction === SMS_DIRECTION_INBOUND)) {
        //     this.emit(EVENT_SMSSTATUS, data)
        // }
        this.emit(EVENT_SMSSTATUS, senderId, data)
    }    

    public sendSms(fromPhoneNumber: PhoneNumber, toPhoneNumber: PhoneNumber, text: string, opts?: { id? : string}): void {
        const _id = opts && opts.id ? opts.id : `sms_${uuidv4()}`
        const smsInfo = {
            id: _id,
            from: fromPhoneNumber.formatInternational(),
            to: toPhoneNumber.formatInternational(),
            text: text
        }

        this.smsSignaling.send(EVENT_SMS, smsInfo);
    }
}