import { computed, makeObservable, observable, runInAction } from 'mobx';

class UserSettingsState {
    @observable private _selectedTab: number = 0

    set selectedTab(value: number) {
        runInAction(() => this._selectedTab = value)
    }

    @computed get selectedTab(): number {
        return this._selectedTab
    }

    // Other
    @observable private _show: boolean = false
    set show(value: boolean) {
        runInAction(() => this._show = value)
    }

    @computed get show(): boolean {
        return this._show
    }

    showTab(selectedTab: number) {
        this.selectedTab = selectedTab
        this.show = true
    }

    constructor() {
        makeObservable(this)
    }
}

export class LocalEndpointState {
    
    // All devices
    @observable private _mediaDeviceInfos: MediaDeviceInfo[] = []

    @computed get mediaDeviceInfos(): MediaDeviceInfo[] {
        return this._mediaDeviceInfos
    }

    private set mediaDeviceInfos(value: MediaDeviceInfo[]) {
        runInAction(() => {
            this._mediaDeviceInfos = value
            if (!this._videoInputDevice || !this.videoInputs.find(vi => vi.deviceId === this._videoInputDevice?.deviceId)) {
                this.videoInputDevice = this.getDefault(this.videoInputs) || undefined
            }
            if (!this._audioInputDevice || !this.audioInputs.find(ai => ai.deviceId === this._audioInputDevice?.deviceId)) {
                this.audioInputDevice = this.getDefault(this.audioInputs) || undefined
            }
            if (!this._audioOutputDevice || !this.audioOutputs.find(ao => ao.deviceId === this._audioOutputDevice?.deviceId)) {
                this.audioOutputDevice = this.getDefault(this.audioOutputs) || undefined
            }            
        })
    }

    protected enumerateDevices(): Promise<MediaDeviceInfo[]> {
        // if (this.handlingUserMedia) {
        //     setTimeout(() => {
        //         this.enumerateDevices()
        //     }, 2000);
        //     return
        // }
        if (!navigator.mediaDevices?.enumerateDevices) {
            console.error("enumerateDevices() not supported.");
            return new Promise<MediaDeviceInfo[]>((resolve, reject) => {
                resolve([] as MediaDeviceInfo[])
            });
        } else {
            // List cameras and microphones.
            try {
                return navigator.mediaDevices.enumerateDevices()
            } catch {
                return new Promise<MediaDeviceInfo[]>((resolve, reject) => {
                    resolve([] as MediaDeviceInfo[])
                });                
            }
        }
    }

    // Audio output
    @observable private _audioOutputDevice: MediaDeviceInfo | undefined;

    @computed get audioOutputDevice(): MediaDeviceInfo | undefined {
        return this._audioOutputDevice
    }

    set audioOutputDevice(value: MediaDeviceInfo | undefined) {
        runInAction(() => this._audioOutputDevice = value)
        // this.storeData("audioOutputDeviceId", this._audioOutputDeviceId)
    }

    set audioOutputDeviceId(value: string | undefined) {
        this.audioOutputDevice = this.audioOutputs.find(ao => ao.deviceId === value) || this.audioOutputDevice
    }

    @computed public get hasMultipleAudioInputs(): boolean {
        return this._mediaDeviceInfos.filter((mdi) => mdi.kind === 'audioinput' && mdi.deviceId != 'default').length > 1
    }

    @computed public get hasMultipleAudioOutputs(): boolean {
        return this._mediaDeviceInfos.filter((mdi) => mdi.kind === 'audiooutput' && mdi.deviceId != 'default').length > 1
    }

    @computed public get hasMultipleVideoInputs(): boolean {
        return this._mediaDeviceInfos.filter((mdi) => mdi.kind === 'videoinput' && mdi.deviceId != 'default').length > 1
    }

    // Audio input
    @observable private _audioInputDevice: MediaDeviceInfo | undefined;

    @computed get audioInputDevice(): MediaDeviceInfo | undefined {
        return this._audioInputDevice
    }

    set audioInputDevice(value: MediaDeviceInfo | undefined) {
        runInAction(() => this._audioInputDevice = value)
    }

    @computed get audioInputDeviceId(): string | undefined {
        return this.audioInputDevice?.deviceId
    }

    set audioInputDeviceId(value: string | undefined) {
        this.audioInputDevice = this.audioInputs.find(ai => ai.deviceId === value) || this.audioInputDevice
    }

    // Video input
    @observable private _videoInputDevice: MediaDeviceInfo | undefined

    @computed get videoInputDevice(): MediaDeviceInfo | undefined {
        return this._videoInputDevice
    }

    set videoInputDevice(value: MediaDeviceInfo | undefined) {
        runInAction(() => this._videoInputDevice = value)
    }

    @computed get videoInputDeviceId(): string | undefined {
        return this.videoInputDevice?.deviceId
    }

    set videoInputDeviceId(value: string | undefined) {
        this.videoInputDevice = this.videoInputs.find(ai => ai.deviceId === value) || this.videoInputDevice
    }    

    // Other
    public readonly userSettings = new UserSettingsState()

    get audioInputs(): MediaDeviceInfo[] {
        return this._mediaDeviceInfos.filter(v => v.kind == "audioinput")
    }

    get audioOutputs(): MediaDeviceInfo[] {
        return this._mediaDeviceInfos.filter(v => v.kind == "audiooutput")
    }

    get videoInputs(): MediaDeviceInfo[] {
        return this._mediaDeviceInfos.filter(v => v.kind == "videoinput")
    }

    private static noDevices(mediaDeviceInfos: MediaDeviceInfo[]) {
        return !mediaDeviceInfos || mediaDeviceInfos.length === 0
    }

    get noAudioOutputs(): boolean {
        return LocalEndpointState.noDevices(this.audioOutputs)
    }

    get noAudioInputs(): boolean {
        return LocalEndpointState.noDevices(this.audioInputs)
    }

    get noVideoInputs(): boolean {
        return LocalEndpointState.noDevices(this.videoInputs)
    }

    private _hasMediaDeviceError: boolean = false

    @computed get hasError(): boolean {
        return this._hasMediaDeviceError
    }

    getDefault(mediaDeviceInfos: MediaDeviceInfo[]): MediaDeviceInfo | null {
        if (mediaDeviceInfos.length > 0) {
            return mediaDeviceInfos.filter(mdi => mdi.label.toLowerCase() === 'default')[0] || mediaDeviceInfos[0] || ''
        } else {
            return null
        }
    }

    async reloadDevices(): Promise<boolean> {
        try {
            this.mediaDeviceInfos = await this.enumerateDevices()
            return this.mediaDeviceInfos.length > 0
        } catch (e: any) {
            this._hasMediaDeviceError = true
            return false
            // runInAction(() => this.hasError = true);
            // this.logger.error("Could not enumerate devices", e.toString());
        }
    }

    constructor() {
        makeObservable(this);
        this.reloadDevices().then(r => {
            if (r && navigator.mediaDevices) {
                navigator.mediaDevices.ondevicechange = (event) => {
                    this.reloadDevices();
                }
            }
        })
    }
}

export const localEndpointState = new LocalEndpointState();