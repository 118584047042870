import { AccountCircle, EmailRounded, PasswordRounded, PasswordTwoTone, Visibility, VisibilityOff } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, TextField, FormGroup, Box, InputAdornment, Input, Button, FormControl, IconButton, InputLabel, OutlinedInput, Link, Alert, CircularProgress, Stack, Typography, Paper } from "@mui/material";
import axios from "axios";
import bind from "bind-decorator";
import { autorun, computed, IReactionDisposer, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { isEmail, Item, requestStateType } from "../helpers/utils";
import { PublicService } from "../helpers/publicService";
import { CommonAppTitle } from "../helpers/utilComponents";

interface ILoginProps {
    // loginAddress: URL
    // recaptchaSiteKey: string
    publicService: PublicService
}

interface ILoginState {
}

const passwordLabel = 'Password'

@observer
export class LoginComponent extends React.Component<ILoginProps, ILoginState> {
    
    @observable email: string = ''
    @observable password: string = ''
    @observable showPassword: boolean = false
    @observable recaptchaToken: string | undefined
    refreshReCaptcha: string | number | boolean | null | undefined;
    @observable requestState: requestStateType = 'notstarted'

    private _disposers = new Array<IReactionDisposer>()
    timer: NodeJS.Timer | undefined

    constructor(props: ILoginProps) {
        super(props)
        makeObservable(this)       
    }

    @computed
    get readyToSubmit(): boolean {
        return (!!this.password && this.password != '' && !!this.email && isEmail(this.email) && !!this.recaptchaToken)
    }

    componentDidMount(): void {

        const token = localStorage.getItem('token')
        if (token) {
            window.location.href = '/'
            return
        }

        this.timer = setInterval(() => this.refreshReCaptcha = Date.now(), 1000 * 90)
    }

    @bind
    handleMouseDownPassword(event: any) {
        event.preventDefault();
    };

    @bind
    onVerify(token: string): void | Promise<void> {
        this.recaptchaToken = token
    }
    
    @bind
    private async doLogin() {
        try {
            runInAction(() => this.requestState = 'verifying')
            const res = await this.props.publicService.login({
                email: this.email,
                password: this.password,
                recaptchaToken: this.recaptchaToken!
            })

            if (res.data && res.data.token) {
                runInAction(() => this.requestState = 'ok')
                localStorage.setItem('token', res.data.token)
                document.location.href = '/'
            } else {
                runInAction(() => this.requestState = 'error')
                throw new Error('Successfully logged in, but got no token')
            }
        } catch (e) {
            console.log(e)
            runInAction(() => this.requestState = 'error')
        } finally {
            this.refreshReCaptcha = Date.now()
        }
    }

    @bind
    handleSubmit(ev: any) {
        ev.preventDefault()
    }
    
    render() {
        return <form onSubmit={this.handleSubmit} >
        <GoogleReCaptchaProvider reCaptchaKey={this.props.publicService.recaptchaSiteKey_v3}>
        <GoogleReCaptcha
            onVerify={this.onVerify}
            refreshReCaptcha={this.refreshReCaptcha}
        />            
        <Box display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh">
            <Stack sx={{ minWidth: '20%', maxWidth: '80%' }} spacing={2}>
                <CommonAppTitle/>
                <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                        <OutlinedInput
                        id="outlined-adornment-email"
                        type='text'
                        autoComplete='off'
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton edge="end" disabled>
                            <EmailRounded />
                            </IconButton>
                            </InputAdornment>
                        }
                label="Email"
                autoFocus
                onChange={(evt) => runInAction(() => this.email = evt.target.value.trim().toLowerCase())}
                // fullWidth
                />
                </FormControl>                
                <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{passwordLabel}</InputLabel>
                    <OutlinedInput
                    id="outlined-adornment-password"
                    type={this.showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => runInAction(() => this.showPassword = !this.showPassword)}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                        tabIndex={-1}
                        >
                        {this.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    }
                    label={passwordLabel}
                    onChange={(evt) => runInAction(() =>this.password = evt.target.value) }
                    fullWidth
                    />
                </FormControl>
            { (this.requestState === 'ok') && <Alert severity="success" variant="outlined">OK</Alert> }
            { (this.requestState === 'error') && <Alert severity="error" variant="outlined">Something went wrong. If you forgot your password, you can <Link href='resetpassword'>reset it</Link>.</Alert> }
            { (this.requestState === 'notstarted') && <Button type="submit" disabled={!this.readyToSubmit} variant="contained" onClick={this.doLogin} >Login</Button> }
            { (this.requestState === 'verifying') && <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div>}
            <Paper key="info" elevation={0}>
                    <Typography variant="body2" component="h3" align="center" justifyContent={"center"}>
                        No account? <Link href='signup'>Sign-up</Link> instead.
                    </Typography>
                    <Typography variant="body2" component="h3" align="center" justifyContent={"center"}>
                        You can reset your password <Link href='resetpassword'>here</Link>.
                    </Typography>
            </Paper>
            </Stack>
        </Box>
        </GoogleReCaptchaProvider>
        </form>
    }
}