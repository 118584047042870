import bind from "bind-decorator";
import { EventEmitter2, Listener } from "eventemitter2";
import { IGroupSignaling } from "../base/iMsSignaling";
import { MsClient } from "../base/msClient";
import { NotConnectedError } from "../../rabbitmq/customErrors";
import { PresenceService } from "../../rabbitmq/presenceService";
import { MediasoupElementInfo } from "../base/mediasoupElement";
import { Logger } from "../../../helpers/logger";


export type RemoteEndpointType = 'default' | 'sfu' | 'sipbridge' | 'emubridge'

export class RemoteEndpoint extends EventEmitter2 {

    public static readonly NEW_MEDIASOUP_ELEM = 'new_mediasoup_elem'
    public static readonly CLOSE_MEDIASOUP_ELEM = 'close_mediasoup_elem'
    public static readonly EVENT_DATA = 'data'
    public static readonly DISCONNECTED = 'disconnected'
    public static readonly TRACKS = 'tracks'
    public static readonly ERROR = 'error'
    public static readonly MANUAL_COMMAND = 'manual_command'
    public static readonly STOP_PRODUCER = 'stop_producer'
    public static readonly START_PRODUCER = 'start_producer'
    public static readonly CANNOT_DECODE_MEDIASOUP_ELEM = "cannot_decode"
    public static readonly CANNOT_CONSUME_MEDIASOUP_ELEM = "cannot_consume"
    public static readonly SUSPEND = 'suspend'
    public static readonly RESUME = 'resume'
    public static readonly TERMINATE = 'terminate'
    public static readonly START = 'start'
    public static readonly CLIPBOARD_COPY = 'clipboard_copy'

    protected producerId2consumerId = new Map<string, string>();
    private mediasoupElementInfos = new Map<string, MediasoupElementInfo>();
    private onNewListener: Listener;
    logger: Logger;

    public get aid(): string[] {
        return this.id.split('.')
    }

    public get type(): RemoteEndpointType {
        return 'default'
    }

    constructor(public readonly id: string,
        // public readonly sharedData: any,
        // public readonly producers: ProducerInfo[],
        protected readonly msClient: MsClient,
        protected readonly groupSignaling: IGroupSignaling,
        protected readonly presenceService: PresenceService,
        public autoConsumeProducers: boolean) {
            super({wildcard: true})
            this.logger = new Logger(`RemoteEndpoint_${id}`)
            // this.msClient.on(MsClient.EVENT_DISCONNECTED, () => this.onDisconnected('msClient', 'dummy'))
            // this.groupSignaling.on(`${id}.startedproducers`, this.onStartedProducers)
            // this.groupSignaling.on(`${id}.stoppingproducers`, this.onStoppingProducers)
            // this.groupSignaling.on(`${id}.disconnected`, this.onDisconnected)
            this.onNewListener = this.presenceService.on([PresenceService.EVENT_ELEMENT_NEW, ...this.aid, PresenceService.EVENT_ALL], this.onNew, {objectify: true}) as Listener
            // if (producers && producers.length > 0 && autoConsumeProducers)
            //     setTimeout(() => this.ConsumeProducers(producers), 200)
            this.logger.debug('Listening on', [PresenceService.EVENT_ELEMENT_NEW, ...this.aid, PresenceService.EVENT_ALL])
            // after the current event cycle, we're ready to receive events, so get the state of the remote endpoint
            setTimeout(() => this.groupSignaling.send('getState', {}), 0)
    }

    @bind
    protected async onNew(data: any) {
        this.logger.debug('onNew', data)
        const mei = JSON.parse(data.payload) as MediasoupElementInfo

        if (this.mediasoupElementInfos.has(mei.id)) {
            this.logger.warn('already seen this mediasoup element info')
            return false
        }
        this.mediasoupElementInfos.set(mei.id, mei)
        // set the close event only once on [endpoint.aid, mei.aid]
        this.presenceService.once([PresenceService.EVENT_ELEMENT_CLOSE, ...mei.subjectId.split('.')], this.onClose)

        this.emit([RemoteEndpoint.NEW_MEDIASOUP_ELEM, mei.name, mei.type, mei.kind ?? 'undefined',], mei)

        if (this.autoConsumeProducers && (mei.type === 'dataproducer' || mei.type === 'producer')) {
            try {
                if (mei.type === 'dataproducer' || (mei.codecs && await this.msClient.canDecode(mei.codecs))) {
                    this.logger.debug('CAN decode this mei', mei, this.msClient.codecs)
                    const newTrack = await this.consume(mei);
                    // consume returns null if there isn't any new track (ex when consuming a dataproducer)
                    if (newTrack) {
                        this.emitAllTracks();
                    } 
                } else {
                    this.logger.debug('CANNOT decode this mei', mei, this.msClient.codecs)
                    this.emit([RemoteEndpoint.CANNOT_DECODE_MEDIASOUP_ELEM, mei.name, mei.type, mei.kind ?? 'undefined',], mei)
                }
            } catch (e: any) {
                this.logger.error('Unable to consume', mei, e.toString())
                this.emitError('unable to consume', mei)
                this.emit([RemoteEndpoint.CANNOT_CONSUME_MEDIASOUP_ELEM, mei.name, mei.type, mei.kind ?? 'undefined',], mei)
            }
        } else {
            this.logger.warn(this.autoConsumeProducers, mei)
        }
    }

    @bind
    private onClose(data: any) {
        const mei = JSON.parse(data.payload) as MediasoupElementInfo

        const mediasoupElementInfo = this.mediasoupElementInfos.get(mei.id)
        if (!mediasoupElementInfo) {
            this.logger.warn('element closed but we have never seen it.')
            return false
        } else {
            this.logger.debug('closing element', data)
        }

        this.stopConsume(mediasoupElementInfo)
        this.emit([RemoteEndpoint.CLOSE_MEDIASOUP_ELEM, mei.name, mei.type, mei.kind ?? 'undefined', mei.id], mediasoupElementInfo)

        this.emitAllTracks();

        return true
    }

    private emitAllTracks() {
        const tracks = this.msClient.getTracks(Array.from(this.producerId2consumerId.values()));
        this.emit(RemoteEndpoint.TRACKS, tracks);
    }

    private emitError(message: string, mei: any) {
        this.emit(RemoteEndpoint.ERROR, {message, mei})
    }

    // @bind
    // private onDisconnected(senderId: string, data: any) {
    //     console.log('onDisconnected()')
    //     try {
    //         this.stopConsumeAll()
    //     } catch (e) {
    //         if (e instanceof NotConnectedError) {
    //             // actually this is no surprise, since we were disconnected
    //             // so it's ok to swallow
    //         } else {
    //             throw e
    //         }
    //     }
    //     this.mediasoupElementInfos.clear()
    //     this.emit(RemoteEndpoint.DISCONNECTED, this.id)
    // }
    
    public getAllTracks(): MediaStreamTrack[] {
        return this.msClient.getTracks(Array.from(this.producerId2consumerId.values()))
    }

    public async consume(m: MediasoupElementInfo): Promise<MediaStreamTrack | null> {
        let consumerId: string | undefined = this.producerId2consumerId.get(m.id)

        if (consumerId) {
            this.logger.warn('already consuming this producer')
            return this.msClient.getTrack(consumerId)
        }
        
        try {
            if (m.type == 'producer') {
                consumerId = await this.msClient.consume(m.id)
            } else if (m.type == 'dataproducer') {
                consumerId = await this.msClient.consumeData(m.id, this.onWebRtcData)
            } else {
                throw new Error(`You are trying to consume an element of type ${m.type}`)
            }
            this.producerId2consumerId.set(m.id, consumerId)
            return this.msClient.getTrack(consumerId)
        } catch (e: any) {
            throw new Error(`An error occured trying to consume ${m}`, e)
        }

    }
    
    @bind
    protected onWebRtcData(...data: any[]) {
        this.emit(RemoteEndpoint.EVENT_DATA, data)
    }

    public stopConsume(m: MediasoupElementInfo): boolean {
        const consumerId = this.producerId2consumerId.get(m.id)
        if (consumerId) {
            this.msClient.stop(consumerId)
            this.producerId2consumerId.delete(m.id)
            return true
        } else {
            this.logger.warn('not consuming this mediasoup element', m.name, m.kind, m.type)
            return false
        }
    }

    public stopAllElements() {
        this.stopConsumeAll()
    }

    public stopConsumeAll() {
        Array.from(this.producerId2consumerId).forEach((tuple) => {
            this.msClient.stop(tuple[1])
            this.producerId2consumerId.delete(tuple[0])
        })
    }

    public close(): void {
        this.logger.debug('closing')
        this.onNewListener.off()
        this.stopAllElements()
    }
}