import { Backdrop, Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import axios from "axios";
import bind from "bind-decorator";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { UserDetails, UserService } from "../helpers/userService";
import { GetPhoneNumber } from "./getPhoneNumberComponent";
import StripePricingTable from "./stripePricingTable";
import { CostOverview } from "./costOverview";

export interface PaymentManagementProps {
    userService: UserService
}

export interface PaymentManagementState {
    userDetails: UserDetails | null
    errored: boolean
}

export class PaymentManagement extends React.Component<PaymentManagementProps, PaymentManagementState> {
    
    constructor(props: PaymentManagementProps) {
        super(props)
        this.state = {
            userDetails: null,
            errored: false
        }
    }

    componentDidMount(): void {
        this.props.userService.getUserDetails().then((ud) => {
            this.setState({
                userDetails: ud,
                errored: false
            })
        }).catch((reason: any) => {
            this.setState({
                userDetails: null,
                errored: true
            })
        })
    }
    
    render() {
        console.log(this.state.userDetails)
        return (
            <React.Fragment>
            {this.state.errored && <HasError></HasError>}
            {!this.state.errored && !this.state.userDetails && <WaitLoadSubscriptions></WaitLoadSubscriptions>}
            {!this.state.errored &&  this.state.userDetails && this.state.userDetails.hasSubscriptions && <HasSubscription userService={ this.props.userService } ></HasSubscription>}
            {!this.state.errored &&  this.state.userDetails && !this.state.userDetails.hasSubscriptions && <NoSubscription userDetails ={ this.state.userDetails } userService= {this.props.userService}></NoSubscription>}
            {/* {!this.state.errored &&  this.state.userDetails && !this.state.userDetails.hasSubscriptions && <NoPhoneNumber userService={ this.props.userService } ></NoPhoneNumber>} */}
            
            </React.Fragment>
        )
    }
}


interface NoSubscriptionProps {
    userDetails: any
    userService: UserService
}

class SelectProductStore {
    @observable product: string | undefined
    @observable phoneNumber: string | undefined
}

@observer
class NoSubscription extends React.Component<NoSubscriptionProps> {

    @observable
    chosenPhoneNumber: string | undefined

    store = new SelectProductStore()

    constructor(props: NoSubscriptionProps) {
        super(props)
        makeObservable(this)
    }

    @bind
    getClientReference(): string | undefined {
        // we want to pack the userId with the chosen phone number
        if (this.chosenPhoneNumber) {
            const pn = this.chosenPhoneNumber.replace('+', '')
            return `${this.props.userDetails.id}_${pn}`
        }
        return undefined
    }

    @bind
    onPhoneNumberChoose(pn: string) {
        runInAction(() => {
            this.chosenPhoneNumber = pn
        })
    }

    render() {
        return (
            <React.Fragment key="nosubscription">
                <Stack spacing={2}>
                    <Typography align="center" justifyContent={"center"} variant="subtitle1">You need a subscription in order to have a phone number</Typography>
                    <GetPhoneNumber publicService={this.props.userService.publicService} store={this.store} ></GetPhoneNumber>
                    {this.chosenPhoneNumber && <StripePricingTable clientId={this.getClientReference()} clientEmail={this.props.userDetails.email} ></StripePricingTable> }
                    {/* <Typography align="center" justifyContent={"center"} variant="caption">*after you subscribe, you will choose your new number</Typography> */}
                </Stack>
            </React.Fragment>
        )
    }
}

interface NoPhoneNumberProps {
    userService: UserService
}

@observer
class NoPhoneNumber extends React.Component<NoPhoneNumberProps> {

    @observable phoneNumbers: string[] | undefined
    @observable error: string | undefined
    @observable selectedPhoneNumber: string | undefined;

    constructor(props: NoPhoneNumberProps) {
        super(props)
        makeObservable(this)
    }

    componentDidMount(): void {
        this.getPhoneNumbers()
    }

    private async getPhoneNumbers() {
        const pns =  ['123', '456', '789']
        this.phoneNumbers = pns
        this.selectedPhoneNumber = this.phoneNumbers[0]
    }

    // private async getPhoneNumbers() {
    //     try {
    //         const res = await this.props.userService.getAvailablePhoneNumbers()
    //         runInAction(() => {
    //             this.phoneNumbers = res
    //             // this.selectedPhoneNumber = this.phoneNumbers[0]
    //         })
    //     } catch {
    //         runInAction(() => this.error = 'Could not get phone numbers')
    //     }
    // }

    @bind
    private handleChange(ev: SelectChangeEvent) {
        console.log('Selected phone number', ev.target.value as string)
        this.selectedPhoneNumber = ev.target.value
    }

    render() {
        if (this.phoneNumbers && this.phoneNumbers.length > 0) {
            const menuItems = this.phoneNumbers?.map((item, i) => {
                return (
                    <MenuItem key={i} value={item}>{item}</MenuItem>
                );
            })
            return (<React.Fragment>
                <Stack spacing={2}>
                <Typography>Add a new number to your phone</Typography>
                <FormControl fullWidth>
                <InputLabel id="select-phonenumber">Your new phone number</InputLabel>
                <Select
                labelId="select-phonenumber"
                id="select-phonenumber"
                value={this.selectedPhoneNumber ?? this.phoneNumbers[0]}
                label="Your new phone number"
                onChange={this.handleChange}
                > 
                    {menuItems}
                </Select>
                </FormControl>
                </Stack>
            </React.Fragment>)
        } else {
            if (this.error) {
                return (<Typography><p>An error occured</p></Typography>)
            } else {
                return (<CircularProgress></CircularProgress>)
            }
        }
    }
}

interface HasSubscriptionProps {
    userService: UserService
}

class HasSubscription extends React.Component<HasSubscriptionProps> {
    
    @bind
    async onManageClick() {
        const url = await this.props.userService.getBillingPortalSession()
        window.location = url as any
    }

    render() {
        return (
            <React.Fragment key="hasubscription">
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <CostOverview userService={this.props.userService}/>
                    <Divider variant="middle" />
                    <Box sx={{ m: 2 }}>
                        <Box>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Manage your subscription and payment methods</FormLabel>
                            <FormGroup>
                                <Button onClick={this.onManageClick} >Manage</Button>
                            </FormGroup>
                        </FormControl>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        )
    }
}

class WaitLoadSubscriptions extends React.Component {
    render() {
        return (<React.Fragment>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <CircularProgress color='info' />
            </div>
        </React.Fragment>)
    }
}

class HasError extends React.Component {
    render() {
        return (<React.Fragment>
            <div>
                An error has occured
            </div>
        </React.Fragment>)
    }
}