import { AccountCircle, Visibility, VisibilityOff, EmailRounded } from "@mui/icons-material";
import { Box, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, Stack, Alert, CircularProgress, Link, Typography, FormHelperText } from "@mui/material";
import axios from "axios";
import bind from "bind-decorator";
import { autorun, computed, IReactionDisposer, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import {
    GoogleReCaptcha,
    GoogleReCaptchaProvider,
  } from 'react-google-recaptcha-v3';
import { PublicService } from "../helpers/publicService";
import { CommonAppTitle } from "../helpers/utilComponents";
import { isEmail } from "../helpers/utils";

interface IDoResetPasswordProps {
    publicService: PublicService
}

interface IDoResetPasswordState {}

type requestStateType = 'notstarted' | 'pending' | 'verifying' | 'ok' | 'error'
const passwordLabel = 'Password'
const passwordLabelExt = 'Password (10 to 30 characters)'
const repeatPasswordLabel = 'Repeat password'

@observer
export class DoResetPasswordComponent extends React.Component<IDoResetPasswordProps, IDoResetPasswordState> {
    
    @observable email: string = ''
    @observable password: string = ''
    @observable repassword: string = ''
    @observable token: string | undefined
    @observable requestState: requestStateType = 'notstarted'
    @observable showPassword: boolean = false
    @observable showRepassword: boolean = false
    @observable recaptchaToken: string | undefined

    private refreshReCaptcha: string | number | boolean | null | undefined;
    private timer: ReturnType<typeof setInterval> | undefined
    @observable error_password: boolean = false;
    error_helperText: string = '';
    
    constructor(props: IDoResetPasswordProps) {
        super(props)
        makeObservable(this)
        autorun(() => {
            if (this.password != '' && (this.password.length < 10 || this.password.length > 30)) {
                this.error_password = true;
                this.error_helperText = 'Password length must be between 10 and 30 characters';
            } else {
                this.error_password = false;
                this.error_helperText = '';
            }
        })
    }

    @computed
    get readyToSubmit(): boolean {
        return this.password != '' && this.password === this.repassword && !!this.email && !!this.token && this.password.length >= 10 && this.password.length <= 30
    }

    componentDidMount() {
        const { t } = Object.fromEntries(new URLSearchParams(document.location.search));

        if (!t || t.length < 10) {
            document.location.replace('/login')
        } else {
            this.token = t
        }

        this.timer = setInterval(() => this.refreshReCaptcha = Date.now(), 1000 * 90)
    }

    componentWillUnmount(): void {
        if (this.timer) clearInterval(this.timer)
    }

    @bind
    onVerify(token: string): void | Promise<void> {
        runInAction(() => {
            this.recaptchaToken = token
        })
    }

    @bind
    private async doResetPassword(evt: any) {

        try {
            runInAction(() =>this.requestState = 'verifying')

            const res = await this.props.publicService.doResetPassword({
                email: this.email,
                password: this.password,
                token: this.token!,
                recaptchaToken: this.recaptchaToken!
            })

            if (res.status === 200) {
                runInAction(() =>this.requestState = 'ok')
            } else {
                runInAction(() =>this.requestState = 'error')
            }
        } catch {
            runInAction(() =>this.requestState = 'error')
        } finally {
            this.refreshReCaptcha = Date.now()
        }
    }

    @bind
    handleMouseDownPassword(event: any) {
        event.preventDefault();
    };    

    @bind
    handleSubmit(ev: any) {
        ev.preventDefault()
    }

    render() {
        return <form onSubmit={this.handleSubmit} ><GoogleReCaptchaProvider reCaptchaKey={this.props.publicService.recaptchaSiteKey_v3}>
        <GoogleReCaptcha
            onVerify={this.onVerify}
            refreshReCaptcha={this.refreshReCaptcha}
        />            
        <Box display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh">
            <Stack sx={{ minWidth: '20%', maxWidth: '80%' }} spacing={2}>
            <CommonAppTitle/>
                <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                        <OutlinedInput
                        id="outlined-adornment-email"
                        type='text'
                        autoComplete='off'
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton edge="end" disabled>
                            <EmailRounded />
                            </IconButton>
                            </InputAdornment>
                        }
                label="Email"
                autoFocus
                onChange={(evt) => runInAction(() => this.email = evt.target.value.trim().toLowerCase())}
                // fullWidth
                />
                </FormControl>                
                <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{passwordLabelExt}</InputLabel>
                    <OutlinedInput
                    id="outlined-adornment-password"
                    type={this.showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => runInAction(() => this.showPassword = !this.showPassword)}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                        tabIndex={-1}
                        >
                        {this.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    }
                    label={passwordLabelExt}
                    onChange={(evt) => runInAction(() => this.password = evt.target.value) }
                    fullWidth
                    error = {this.error_password}
                    />
                    {
                        this.error_helperText && 
                        <FormHelperText error id="password-error">
                            {this.error_helperText}
                        </FormHelperText>
                    }
                </FormControl>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-repeat-password">{repeatPasswordLabel}</InputLabel>
                    <OutlinedInput
                    id="outlined-adornment-repeat-password"
                    type={this.showRepassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle re-password visibility"
                        onClick={() => runInAction(() =>this.showRepassword = !this.showRepassword)}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                        tabIndex={-1}
                        >
                        {this.showRepassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    }
                    label={repeatPasswordLabel}
                    onChange={(evt) => runInAction(() =>this.repassword = evt.target.value)}
                    fullWidth
                    />
                </FormControl>
            { (this.requestState === 'ok') && <Alert severity="success" variant="outlined">Password reset successful. You can now <Link href="login" underline="always">login</Link>.</Alert> }
            { (this.requestState === 'error') && <Alert severity="error" variant="outlined">Something went wrong. Please refresh this page and try again.</Alert> }
            { (this.requestState === 'notstarted') && <Button type="submit" disabled={!this.readyToSubmit} variant="contained" onClick={this.doResetPassword} >Reset password</Button> }
            { (this.requestState === 'verifying') && <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div>}
            </Stack>
        </Box>
        </GoogleReCaptchaProvider>
        </form>
    }

}