import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import StripePricingTable from './stripePricingTable';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
export function BootstrapDialogTitle(props: any) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{

              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

export default function PaymentPopup(props: any) {
    const [open, setOpen] = React.useState(true);
  
    // const handleClickOpen = () => {
    //   setOpen(true);
    // };
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle onClose={handleClose}>
            Subscription needed
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <StripePricingTable clientId={props.clientId} clientEmail={props.clientEmail}></StripePricingTable>
          </DialogContent>
        </BootstrapDialog>
      </div>
    );
  }