import bind from "bind-decorator";
import React from "react";
import { UserMediaError } from "../helpers/errors/userMediaError";
import { Logger } from "../helpers/logger";
import { LocalEndpoint } from "../lib/msClient/endpoints/localEndpoint";
import Box from '@mui/material/Box';

const logger = new Logger('LocalEndpointComponent')

export interface ILocalEndpointProps {
    endpoint: LocalEndpoint
}

interface ILocalEndpointState {
    hasMicrophone: boolean | undefined | null
    hasWebcam: boolean | undefined | null
}

export class LocalEndpointComponent extends React.Component<ILocalEndpointProps, ILocalEndpointState> {
    
    _canvas = React.createRef<HTMLCanvasElement>();
    _video: HTMLVideoElement;
    _mediaStream: MediaStream | undefined;
    _lastDisplayedTimestamp: DOMHighResTimeStamp = 0;
    _canvas2dContext: CanvasRenderingContext2D | null = null
    _imgBox = React.createRef<HTMLDivElement>()
    _idx: any
    _stopped: boolean = false

    constructor(public readonly props: ILocalEndpointProps){
        super(props)

        this.state = { hasMicrophone: undefined, hasWebcam: undefined }

        this._video = document.createElement("video")
        this._video.autoplay = false
        this._video.muted = true
    }
    
    async componentDidMount() {
        try {
            this.setupCanvas()
            this.startMedia()
        } catch (e) {
            logger.error(e)
        }
        window.addEventListener('beforeunload', this.cleanup);
    }
    
    componentWillUnmount() {
        this.cleanup()
    }
    
    @bind
    private cleanup() {
        this._stopped = true
        this.props.endpoint.stop()
        window.removeEventListener('beforeunload', this.cleanup); // remove the event handler for normal unmounting
    }
    
    private setupCanvas() {
        if (this._canvas.current) {
            this._canvas2dContext = this._canvas.current.getContext("2d")
        }
    }
    
    private async startMedia() {
        this.props.endpoint.on(LocalEndpoint.EVENT_TRACKS, this.onTracks)
        try {
            await this.props.endpoint.produceDefaultMedia()

        } catch (e: any) {
            if (e instanceof UserMediaError) {
                this.setState({hasMicrophone: null, hasWebcam: null})
            }
        }
        // const currentTracks = this.props.endpoint.getAllTracks()
        // this.onTracks(currentTracks)
    }
    
    @bind
    private onTracks(tracks: MediaStreamTrack[]) {
        logger.debug('got tracks', tracks.length)
        if (tracks.length > 0) {
            this._mediaStream = new MediaStream(tracks)
            this._video.srcObject = this._mediaStream
            this._video.play().catch(logger.error)
            window.requestAnimationFrame(this.requestAnimationFrame);
        } else {
            this._video.srcObject = null
        }
    }
    
    @bind
    private requestAnimationFrame(timestamp: DOMHighResTimeStamp) {
        
        if (!this._stopped && this._lastDisplayedTimestamp + 1000/30 <= timestamp) {
            this._canvas2dContext!.drawImage(this._video, 0, 0, this._canvas.current!.width, this._canvas.current!.height)
            this._lastDisplayedTimestamp = timestamp
        }
        !this._stopped && window.requestAnimationFrame(this.requestAnimationFrame)
    }
    
    @bind 
    private onData(...msg: any[]){
        logger.debug('onData()', msg)
    }
    
    @bind switchMute() {
        if (this._video) {
          if (this._video.muted) {
            logger.debug('unmutting')
            this._video.muted = !this._video.muted
          }
        }
      }

    renderErrors() {
        let microphoneErr = null
        if (this.state.hasMicrophone === null) {
            microphoneErr = <React.Fragment>No microphone</React.Fragment>
        }

        let webcamErr = null
        if (this.state.hasWebcam === null) {
            webcamErr = <React.Fragment>No webcam</React.Fragment>
        }
        return <React.Fragment>{microphoneErr} {webcamErr}</React.Fragment>
    }

    render() {
        return (<React.Fragment key={this.props.endpoint.id}>
            <Box component="div" sx={{ p: 2, border: '1px dashed grey' }}>

                {this.renderErrors()}
                <div>
                    <div ref = { this._imgBox} >
                        <canvas id = {`videoCanvas_${this.props.endpoint.id}`} ref= {this._canvas }></canvas>
                    </div>
                    <div>
                        <p>Local endpoint</p>
                    </div>
                </div>
            </Box>
            </React.Fragment>)
        }
    }