import React from 'react';
import './App.css';
import { ChatConferenceComponent } from './components/chatConferenceComponent';
import { observer } from 'mobx-react';
import { UserService } from './helpers/userService';

export interface AppProps {
    userService: UserService
}

export interface AppState {
    token: any | null
}

@observer
export default class LoggedInChatApp extends React.Component<AppProps, AppState> {

    render() {
        return <ChatConferenceComponent userService={this.props.userService} />;
    }
}
