import Stomp from 'stompjs';
import bind from 'bind-decorator';
import { EventEmitter2 } from 'eventemitter2';
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from 'jwt-decode';
import { NotConnectedError } from './customErrors';
import { EnhancedEventEmitter2 } from '../msClient/base/iMsSignaling';
import { Logger } from '../../helpers/logger';
import { computed, makeObservable, observable, runInAction } from 'mobx';

const logger = new Logger('MqClient')

export function formatId(user_id: string, conn_id: string): string {
    return `${user_id}.${conn_id}`
}

export enum ConnectionStatus {
    Disconnected,
    Connected,
    Error
}

export class MqClient extends EnhancedEventEmitter2 {
    
    public readonly EVENT_CONNECTED: string[] = ['MqClient', 'connected']
    public readonly EVENT_DISCONNECTED: string[] = ['MqClient', 'disconnected']

    @observable
    private client: Stomp.Client | undefined;
    public readonly exchange: string;
    public readonly conn_id: string;
    public readonly user_id: string;
    private reconnectAttempts: number = 0;

    @observable
    private _connectionStatus: ConnectionStatus = ConnectionStatus.Disconnected;
    
    private set connectionStatus(value: ConnectionStatus) {
        runInAction(() => this._connectionStatus = value)
    }

    @computed
    public get connectionStatus(): ConnectionStatus {
        return this._connectionStatus
    }

    @computed
    public get connected(): boolean {
        return this.client !== undefined && this.connectionStatus === ConnectionStatus.Connected
    }
   
    public get id(): string {
        return formatId(this.user_id, this.conn_id)
    }
    
    constructor(public readonly url: Promise<string>, public readonly vhost: string, public readonly resourceId: string, public readonly token: string,
            public readonly maxReconnectAttempts: number) {
        super({wildcard: true})
        makeObservable(this)

        const dtoken = jwt_decode(token) as any;
        this.user_id = dtoken.sub || dtoken.accountId
        if (!this.user_id) {
            logger.error('TOKEN', JSON.stringify(dtoken))
            throw new Error('No sub or accountId could be identified in the token')
        }
        this.conn_id = uuidv4()
        this.exchange = `/exchange/${this.resourceId}`
    }
    
    @bind
    public async connect() {
        
        try {
            var ws = new WebSocket(await this.url);

            runInAction(() => {
                this.client = Stomp.over(ws);
                this.client.debug = (...args: any[]) => {}
                this.client.connect('', this.token, this.onClientConnected, this.onClientConnectionError, this.vhost)
            })
        } catch (e: any) {
            this.onClientConnectionError(e)
        }
    }

    public resetReconnectAttempts() {
        this.reconnectAttempts = 0
    }

    @bind
    private onClientConnectionError(error: string | Stomp.Frame) {
        this.connectionStatus = ConnectionStatus.Error
        this.reconnectAttempts++
        logger.debug('onConnectionError', error)
        this.emit(this.EVENT_DISCONNECTED, error)
        if (this.reconnectAttempts > this.maxReconnectAttempts) {
            this.emit('max reconnect attempts reached', this.reconnectAttempts)
        } else {
            setTimeout(this.connect, 1000)
        }
    }
    
    @bind
    private onClientConnected(frame: Stomp.Frame |undefined) {
        logger.debug('Connected!', frame)
        this.reconnectAttempts = 0
        this.connectionStatus = ConnectionStatus.Connected
        this.emit(this.EVENT_CONNECTED, frame)
    }
   
    public subscribe(destination: string, onMessage: (msg: Stomp.Message) => void, opts: any): Stomp.Subscription {
        if (this.client) {
            return this.client.subscribe(destination, onMessage, opts)
        }
        throw new Error("No client!")
    }
    public send(destination: string, payload: string) {
        if (!this.client || !this.client.connected) {
            throw new NotConnectedError('mqClient is not connected!')
        }
        return this.client!.send(destination, { sender: this.id }, payload)
    }
}