import assert from "assert";
import axios, { AxiosInstance } from "axios";
import jwt_decode from 'jwt-decode';
import { PublicService } from "./publicService";
import bind from "bind-decorator";

export interface ShareLink {
    url: string
}

export interface Invoice {

    id: string
    amount_due: number
    due_date: number
    currency: string

}

export class UserDetails {
    constructor(
        public readonly id: string,
        public readonly fullName: string, 
        public readonly email: string, 
        public readonly phoneNumber: string,
        public readonly subscriptionIds: string[]) {}

    get hasSubscriptions() {
        return this.subscriptionIds && this.subscriptionIds.length > 0
    }
}

export class UserService {
    
    private axios: AxiosInstance
    private _serverAddress: string | undefined
    private _vhost: string
    private _token: string
    private _sfuId: string
    private _userId: string
    private _userEmail: string
    private _grant_type: string
    private _serverAddressPromise: Promise<string>

    @bind
    private serverAddressExecutor(resolve: (value: string) => void, reject: any) 
    {
        this._serverAddressResolve = resolve
        this._serverAddressReject = reject
    }

    private _serverAddressResolve!: (value: string) => void;
    private _serverAddressReject!: (reason?: any) => void;

    public get isMainUser(): boolean {
        return this._grant_type === 'password'
    }

    constructor(conn: {
        token: string,
        // serverAddress: string,
        sfuId: string
    } , public readonly publicService: PublicService) {
        this._token = conn.token
        const dtoken = jwt_decode(conn.token) as any;
        const oaccountId = dtoken.sub || dtoken.accountId;
        this._grant_type = dtoken.grant_type 
        if (!oaccountId) {
            throw new Error('No sub or accountId found in token!');
        }
        
        if (!process.env.REACT_APP_SERVER_ADDRESS) {
            throw new Error('Invalid configuration: no server address defined.');
        }

        this._userEmail = dtoken.email;
        this._userId = this._vhost = oaccountId;
        // this._serverAddress = conn.serverAddress;
        this._sfuId = conn.sfuId;
    
        this.axios = axios.create({baseURL: this.publicService.mainServerUrl, headers: {
            Authorization: `Bearer ${conn.token}`
        } })

        this._serverAddressPromise = new Promise(this.serverAddressExecutor)
    }

    getAvailablePhoneNumbers(): Promise<string[]> {
        return this.publicService.getAvailablePhoneNumbers()
    }

    async getUserDetails(): Promise<UserDetails> {
        const detailsResponse = await this.axios.get('/user/account/details')
        if (detailsResponse.status === 200) {
            return new UserDetails(this._userId,
                '',
                this._userEmail,
                detailsResponse.data.phoneNumber,
                detailsResponse.data.subscriptionIds)
        } else {
            throw new Error('Could not contact server')
        }
    }

    async getNextInvoice(): Promise<Invoice> {
        const invoiceResponse = await this.axios.get('/user/account/invoices/upcoming')
        if (invoiceResponse.status == 200) {
            return invoiceResponse.data as Invoice
        } else {
            throw new Error('An error occured getting data from server')
        }
    }

    async getBillingPortalSession(): Promise<string> {
        const billingPortalSession = await this.axios.get('user/account/billingPortalSession')
        if (billingPortalSession.status === 200) {
            return billingPortalSession.data.url
        }
        throw new Error('Could not contact server')
    }

    async createSubscription(phoneNumber: string): Promise<{ subscriptionId: string, clientSecret: string} > {
        const res = await this.axios.post('/user/account/create-subscription', {
            phoneNumber
        })
        if (res.status === 200) {
            return res.data
        }
        throw new Error("Could not create subscription")
    }

    async buyNumber(phoneNumber: string) {
        const buyNumberRes = await this.axios.post('/user/account/phoneNumbers/buy')
        if (buyNumberRes.status === 200) {
            return buyNumberRes.data
        }
    }

    async sendFeedback(description: string, phoneNumber: string): Promise<string> {
        const res = await this.axios.post('user/account/feedback', {
            description,
            phoneNumber
        })
        if (res.status === 200) {
            return 'OK'
        }
        throw new Error('An error occured')
    }

    async deleteAccount() {
        const res = await this.axios.delete('user/account/delete')
        if (res.status === 200) {
            localStorage.setItem('accountDeleted', '1');
            this.clearToken()
            this.logout()
        } else {
            console.error('An error occured while deleting the account')
        }
    }

    async getShareLink(): Promise<ShareLink> {
        const shareLinkResponse = await this.axios.get('/user/account/share')
        if (shareLinkResponse.status == 200) {
            return shareLinkResponse.data as ShareLink
        } else {
            throw new Error('An error occured getting data from server')
        }
    }    

    clearToken() {
        this._token = ''
    }

    async requestUserConf(): Promise<any | undefined> {
        try {
            const serverAddressResponse = await this.axios.get('/user/account/conf')
            if (serverAddressResponse.status === 200) {
                var response = serverAddressResponse.data as Object
                return response
            } else {
                this.clearToken()
                this.logout()
                throw new Error('An error occured getting mq server address from main server')
            }
        } catch {
            this.clearToken()
            this.logout()                
        }
    }

    async requestRestartEmubridge(): Promise<any | undefined> {
        const serverAddressResponse = await this.axios.post('/user/account/restart')
        if (serverAddressResponse.status === 200) {
            var response = serverAddressResponse.data as Object
            return response
        } else {
            throw new Error('An error occured getting mq server address from main server')
        }
    }

    @bind
    private async getServerAddress(): Promise<string> {
        try {
            const data = await this.requestUserConf()
            {
                if (!process.env.REACT_APP_SERVER_ADDRESS) {
                    throw new Error('Invalid configuration: no server address defined.');
                }
                this._serverAddress = data?.mqServerAddress ? data.mqServerAddress : process.env.REACT_APP_SERVER_ADDRESS
                assert(this._serverAddress)
                this._serverAddressResolve(this._serverAddress!)
            }
        } catch (e: any) {
            console.error("getServerAddress", e)
            setTimeout(this.getServerAddress, 1000)
        }
        return this._serverAddressPromise
    }

    get serverAddress(): Promise<string> {
        if (!this._serverAddress) {
            return this.getServerAddress()
        }
        return this._serverAddressPromise
    }
    get vhost(): string {
        return this._vhost
    }
    get exchangeId(): string {
        return 'main'
    }
    get token(): string {
        return this._token
    }
    get sfuId(): string {
        return this._sfuId
    }
    get userId(): string {
        return this._userId
    }
    get userEmail(): string {
        return this._userEmail
    }

    logout(): void {
        localStorage.removeItem('token')
        if (process.env.REACT_APP_LOGOUT_REDIRECT_URL)
            window.location.href = process.env.REACT_APP_LOGOUT_REDIRECT_URL
        else 
            window.location.reload()
    }
}

export const publicService = new PublicService(process.env.REACT_APP_MAINSERVER_URL!, process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3!, process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2!)