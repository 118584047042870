import { Avatar, Box, Button, CircularProgress, Grid, InputAdornment, Link, List, ListItem, ListItemAvatar, ListItemText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Invoice, UserService } from "../helpers/userService";
import ReceiptIcon from '@mui/icons-material/Receipt';
import { observer } from "mobx-react";
import { makeObservable, observable, override, runInAction } from "mobx";
import { requestStateType } from "../helpers/utils";
import * as QRCode from "qrcode";
import bind from "bind-decorator";

export interface IShareViewProps {
    userService: UserService
}

export interface IShareViewState {
}

@observer
export class ShareView extends React.Component<IShareViewProps, IShareViewState> {
    
    @observable _state: requestStateType = 'notstarted'
    @observable shareUrl: URL | undefined = undefined

    constructor(props: IShareViewProps) {
        super(props)
        makeObservable(this)
    }

    // componentDidMount(): void {
    //     this.props.userService.getNextInvoice().then((i) => {
    //         this.setState({
    //             errored: false,
    //             nextInvoice: i
    //         })
    //     }).catch((reason: any) => {
    //         this.setState({
    //             errored: true,
    //             nextInvoice: undefined
    //         })
    //     })
    // }

    @bind
    getLink() {
        runInAction(async () => {
            try {
                this._state = 'pending'
                this.shareUrl = new URL((await this.props.userService.getShareLink()).url) //new URL('https://cophone.io/shared?t=1234567890'); //
                this._state = 'ok'
            } catch (e: any) {
                console.error(e.toString())
                this._state = 'error'
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <React.Fragment>
                    <Stack alignItems="center" spacing={1}>
                        <Typography>You can share a direct link to your device.</Typography>
                        <Typography>Anyone with the link has full control of your device!</Typography>
                        {this._state === 'notstarted' && <Button onClick={this.getLink}>Generate link</Button> }
                    </Stack>
                </React.Fragment>
            {this._state === 'error' && <HasError></HasError>}
            {this._state === 'verifying' && <WaitLoad></WaitLoad>}
            {this._state === 'ok' && this.shareUrl && <ShareViewInternal url={this.shareUrl} ></ShareViewInternal>}
            </React.Fragment>
        )
        }
    }

    class HasError extends React.Component {
        render() {
            return (<React.Fragment>
                <div>
                    An error has occured
                </div>
            </React.Fragment>)
        }
    }

    class WaitLoad extends React.Component {
        render() {
            return (<React.Fragment>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress color='info' />
                </div>
            </React.Fragment>)
        }
    }

    interface ShareViewInternalProps {
        url: URL
    }

    @observer
    class ShareViewInternal extends React.Component<ShareViewInternalProps> {
        @observable tooltipText: string = "Click to copy"

        constructor(props: ShareViewInternalProps) {
            super(props)
            makeObservable(this)
        }

        componentDidMount(): void {
            var canvas = document.getElementById('qrcodecanvas')

            QRCode.toCanvas(canvas as HTMLCanvasElement, this.props.url.toString(), { errorCorrectionLevel: 'L' })
        }

        @bind
        async toClipboard(text: string) {
            await navigator.clipboard.writeText(text)        
            runInAction(() => {
                this.tooltipText = "Copied!"
            })
            setTimeout(() => {
                runInAction(() => {
                    this.tooltipText = "Click to copy"
                })},
                2000)
        }

        render() {
            return (
                <React.Fragment>
                    <Stack alignItems="center" spacing={2}>
                        <canvas id="qrcodecanvas"></canvas>
                    <Tooltip
                        PopperProps={{
                        disablePortal: true,
                        }}
                        disableFocusListener
                        title={this.tooltipText}
                    >
                        <Typography onClick={() => this.toClipboard(this.props.url.toString())} fontSize="14px" style={{wordBreak:'break-all'}} >
                            {this.props.url.toString()}
                        </Typography>
                    </Tooltip>
                        <p>Links are valid for <b>24 hours.</b></p>
                    </Stack>
                </React.Fragment>
            )
        }
    }