import { PhoneNumber } from "libphonenumber-js"
import { Call, EVENT_CALL_OPERATION } from "../../helpers/call"
import { IGroupSignaling } from "../msClient/base/iMsSignaling"
import { v4 as uuidv4 } from "uuid"
import { CallCommand, TheCall } from "../../helpers/twilioCallStatus"
export interface IDialer {
    dial(fromPhoneNumber: PhoneNumber, toPhoneNumber: PhoneNumber): CallCommand
    pickup(call: TheCall): CallCommand
    hangup(call: TheCall): CallCommand
}

export enum Internal_PhoneCall_Operation {
    Invalid = -1,
    Init = 0,
    Accept = 1,
    Hangup = 2,
    PlaceOnHold = 3,
    TakeOffHold = 4
}

export class CallInfo 
{
    public id: string
    public from: string
    public to: string
    public operation: Internal_PhoneCall_Operation
    public senderId: string

    constructor(call: Call, operation: Internal_PhoneCall_Operation) {
        this.id = call.id
        this.from = call.from.number
        this.to = call.to.number
        this.operation = operation
        this.senderId = '-'
    }
}

export class TwilioCallInfo 
{
    public id: string
    public from: string
    public to: string
    public operation: Internal_PhoneCall_Operation
    public senderId: string

    constructor(call: any, operation: Internal_PhoneCall_Operation) {
        this.id = call.CallSid
        this.from = call.From
        this.to = call.To
        this.operation = operation
        this.senderId = '-'
    }
}

export class PstnSignaling2 implements IDialer {
    constructor(protected readonly groupSignaling: IGroupSignaling) {}
    
    dial(fromPhoneNumber: PhoneNumber, toPhoneNumber: PhoneNumber, opts?: { id? : string}): CallCommand {
        const _id = opts && opts.id ? opts.id : `call_${uuidv4()}`;
        const from = fromPhoneNumber.format("E.164");
        const to = toPhoneNumber.format("E.164");
        const callCommand = new CallCommand(_id, _id, Internal_PhoneCall_Operation.Init, from, to);

        this.sendCommand(callCommand);
        return callCommand
    }

    pickup(call: TheCall): CallCommand {
        const callCommand = new CallCommand(call.external_id, call.external_id, Internal_PhoneCall_Operation.Accept,
            call.from, call.to);
        this.sendCommand(callCommand);
        return callCommand;
    }

    hangup(call: TheCall): CallCommand {
        // const callInfo = new TwilioCallInfo(call, PhoneCall_Operation.Hangup)
        const callCommand = new CallCommand(call.external_id, call.external_id, Internal_PhoneCall_Operation.Hangup,
            call.from, call.to);

        this.sendCommand(callCommand);
        return callCommand;
    }

    private sendCommand(callCommand: CallCommand){
        this.groupSignaling.send(EVENT_CALL_OPERATION, callCommand)
    }
}