import bind from "bind-decorator"
import { observer } from "mobx-react"
import { observable, runInAction } from 'mobx'
import React from "react"
import { RemoteEndpoint } from "../lib/msClient/endpoints/remoteEndpoint"
import { SipEndpointComponent } from "./sipEndpointComponent"
// import { IUserData } from "./userDetails"
import { Backdrop, Button, CircularProgress, IconButton } from "@mui/material"
import { Conference } from "../lib/msClient/endpoints/conference"
import { RemoteEndpointComponent } from "./remoteEndpointComponent"
import { LocalEndpoint } from "../lib/msClient/endpoints/localEndpoint"
import { LocalEndpointComponent } from "./localEndpointComponent"
import { SipRemoteEndpoint } from "../lib/msClient/endpoints/sipRemoteEndpoint"
import { UserService } from "../helpers/userService"

export interface IConferenceProps {
    userService: UserService
    // serverAddress: string
    // vhost: string
    // resourceId: string
    // token: string
    // sfuId: string
}

export interface IPaymentData {
    paymentClientSecret?: string
}

export interface IConferenceState {
    showSettings: boolean
    paymentData?: IPaymentData
    // userData: IUserData
    maxReconnectsReached: boolean
    localEndpoint?: LocalEndpoint | undefined
}

@observer
export class ChatConferenceComponent extends React.Component<IConferenceProps, IConferenceState> {

    protected readonly conference: Conference;
    private sipEndpointComponent = React.createRef<SipEndpointComponent>()
    private _refreshPageButton = React.createRef<HTMLButtonElement>()

    @observable
    private userHasInteracted: boolean = false;
        
    constructor(props: IConferenceProps) {
        super(props)
        this.state = { showSettings: false, maxReconnectsReached: false }

        this.conference = new Conference(this.props.userService)
        this.conference.on(Conference.EVENT_MAX_RECONNECT_ATTEMPTS, this.onMaxReconnectAttempts)
        // this.conference.on(Conference.EVENT_LOCAL_ENDPOINT_JOINED, this.onLocalEndpointJoined)
        // this.conference.on(Conference.EVENT_REMOTE_ENDPOINT_JOINED, this.onRemoteEndpointJoined)
        // this.conference.on(Conference.EVENT_REMOTE_ENDPOINT_LEAVING, this.onRemoteEndpointLeaving)
    }
    
    @bind
    private onMaxReconnectAttempts() {
        this.setState({ maxReconnectsReached: true })
    }
    
    @bind
    private onLocalEndpointJoined(endpoint: LocalEndpoint) {
        endpoint.once(endpoint.EVENT_DISCONNECTED, this.onLocalEndpointLeft)
        this.setState({
            localEndpoint: endpoint
        })
    }

    @bind
    private onLocalEndpointLeft(endpoint: LocalEndpoint) {
        this.setState({
            localEndpoint: undefined
        })
    }
    
    componentDidMount() {
        document.title = "Chat"

        this.connect()
        window.addEventListener('beforeunload', this.cleanup);
    }

    componentWillUnmount() {
        this.cleanup()
    }

    @bind
    private connect() {
        this.conference.connect()
    }

    @bind
    private cleanup() {
        window.removeEventListener('beforeunload', this.cleanup); // remove the event handler for normal unmounting
    }

    @bind
    refreshPage() {
        window.location.reload()
    }

    renderRefreshOverlay = () => {
        return <div className="center">
            <Button color="secondary" variant="contained" size="large" onClick={this.refreshPage} ref={this._refreshPageButton} >Refresh</Button>
        </div>
    }

    private renderSfuNotConnectedOverlay(size: number = 40) {
        return (
            <React.Fragment>
                <Backdrop open={ !this.conference.state.sfuConnected } >
                    <CircularProgress size={size} color="primary" />
                    <p>Not ready</p>
                </Backdrop>
            </React.Fragment>
        )
    }

    protected renderLocalEndpoint() {
        if (this.state.localEndpoint) {
            return <LocalEndpointComponent endpoint={this.state.localEndpoint}></LocalEndpointComponent>
        } else {
            <div><p>Local endpoint loading...</p></div>
        }
    }

    protected renderRemoteEndpoint = (e: RemoteEndpoint) => {
        if (e.type === 'default') {
            return <RemoteEndpointComponent key={e.id} endpoint={e}></RemoteEndpointComponent>
        } 

        if (e.type === 'sipbridge') {
            const eSip = e as SipRemoteEndpoint
            return <SipEndpointComponent key={eSip.id} sipRemoteEndpoint = {eSip} unmuteAudio={true} ref = {this.sipEndpointComponent} />
        }
        return null
    }

    protected renderRemoteEndpoints = (res: RemoteEndpoint[]) => {
        return (<React.Fragment key="chatConference"> 
            {
                res.map((e) => {
                    return this.renderRemoteEndpoint(e)
            })}
        </React.Fragment>)
    }

    protected renderChatConference(): React.ReactFragment {
        return <React.Fragment key="conference">
            {this.state.maxReconnectsReached && this.renderRefreshOverlay()}
            {this.state.localEndpoint !== undefined && this.renderLocalEndpoint()}
            {this.renderRemoteEndpoints(Array.from(this.conference.state.remoteEndpoints.values()))}
            {this.renderSfuNotConnectedOverlay()}
            {/* {this.renderSipEndpoint()} */}
        </React.Fragment>
    }

    render() {
        return this.renderChatConference()
    }
    
}